import React from 'react';
import PropTypes from 'prop-types';
import { history } from '../_helpers';
import { SendButton, TextFieldCell, Snack} from "../_components";
import '../css/style.css';
import { storeData, setStore } from '../store.js';
import { connect } from "react-redux";
import {store} from '../_helpers'; 
import ConfItem from './ConfItem';
import { userService, confService } from '../_services';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import { userActions, appActions } from "../_actions";
import i18next from 'i18next';


function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
} 

class ConfListPage extends React.Component {


	constructor(props)
	{
		super(props);
        
		 this.state = { isLoading:false}
        store.subscribe(this.storeChange)
	}

    componentDidMount() {
        
        //this.props.dispatch(userActions.conf_list());
        confService.confListParticipants(false);
        
    }
    
     storeChange = (e) => {
        
     }  

    onItemClick = (e) => {
        
    }
    
    onItemEdit = (e) => {
        
        this.props.dispatch(userActions.set_conf(e));
        this.props.dispatch(appActions.setpage('conf_editor',e.id));
    }
    
    onItemSchedule = (e) => {
        
        this.props.dispatch(userActions.set_conf(e));
        this.props.dispatch(appActions.setpage('conf_prog',e.id));
    }
    
    onItemDelete = (id) => {


        let self = this;
        let res_dialog = window.confirm("Удалить конференцию?");
		if (res_dialog == true)
        {
            
             //self.props.dispatch(userActions.del_conf(id)); 
             //this.props.dispatch(userActions.conf_list());
             
             userService.patchWrapper('api/conference/'+id+'?contracts_for_speakers=1',function(data) 
		   {
                //self.props.dispatch(userActions.conf_list());
                confService.confListParticipants(false);
           },function(error) {});
            
             
        }
    }
    
    addNewConf = () => {
        this.props.dispatch(userActions.set_conf(null));
        this.props.dispatch(appActions.setpage('new_conf'));
    }
    

    render() {
	
		let self = this;
        const { isLoading } = this.state;
     const { classes, user } = this.props;
     
     console.log(user.conflist);
        
        return (
        <div class="layout">
				<div class="layout__contains">
					<h1 class="layout__title">Конференции</h1>

					<div class="layout__btn">
						<button class="btn" onClick={self.addNewConf}>Создать конференцию</button>
					</div>
				</div>

				<ul class="conf-list">
                {user.conflist.length>0 && user.conflist.filter(e=>e.contracts_for_speakers ==0).map(function(item,index){
                    return <ConfItem data={item} 
                            onClick={()=>self.onItemClick(item.id)} 
                            onEdit={()=>self.onItemEdit(item)} 
                            onSchedule={()=>self.onItemSchedule(item)} 
                            onDelete={()=>self.onItemDelete(item.id)}         
                                    />
                    })}
				
					
				</ul>
			</div>
        );
    }
}


function mapStateToProps(state) {
    const {app, user, authentication} = state;
    
    return {
        user,
        app,
        authentication
    };
}

const connectedConfListPage = connect(mapStateToProps)(ConfListPage);
export {connectedConfListPage as ConfListPage};

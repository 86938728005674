import {scheduleConstants} from "../_constants";


export const scheduleActions = {
    
    set_schedule,
	set_slots,
    set_lessons,
    set_themes,
    set_themes_id,
    set_speakers,
    set_rooms,
    set_date,
    set_loading,
    set_type_update,
    set_file_status,
    set_file_data,
    
}

function set_date(data) {
    
    return {type: scheduleConstants.SET_DATE,data};
    
} 

function set_file_status(data) {
    
    return {type: scheduleConstants.SET_FILE_STATUS,data};
} 

function set_file_data(data) {
    
    return {type: scheduleConstants.SET_FILE_DATA,data};
}

function set_loading(data) {
    
    return {type: scheduleConstants.SET_LOADING,data};
    
} 

function set_schedule(data) {
    
    return {type: scheduleConstants.SET_SCHEDULE,data};
    
}

function set_slots(data) {
    
    return {type: scheduleConstants.SET_SLOTS,data};
    
} 

function set_lessons(data) {
    
    return {type: scheduleConstants.SET_LESSONS,data};
    
} 

function set_themes(data) {
    
    return {type: scheduleConstants.SET_THEMES,data};
    
} 

function set_themes_id(data) {
    
    return {type: scheduleConstants.SET_THEMES_ID,data};
    
} 

function set_speakers(data) {
    return {type: scheduleConstants.SET_SPEAKERS,data};
} 

function set_rooms(data) {
    return {type: scheduleConstants.SET_ROOMS,data};
} 
    
function set_type_update(data)    
{
    return {type: scheduleConstants.IS_NEED_UPDATE,data};
    
}

import React from "react";
import get from 'lodash/get'
import {MenuItem, Select, makeStyles} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles({
    root: {
        background: '#ffffff',
        border: 0,
        color: '#0b1218',
        minHeight: 34,
        maxHeight: 34,
        lineHeight: 1.5,
        paddingTop: 0,
    },
    icon: {
        width: 32,
        height: 32,
        backgroundColor: 'rgba(11, 18, 24, 0.06)',
        borderRadius: '50%',
        top: props => get(props, 'styles.icon.top', -5),
        color: '#000000'
    },
    select: {
        paddingRight: '1.2em',
        whiteSpace: 'pre-line',
        fontWeight: 'bold',
        color: '#000',
        fontSize: 24,
        display: 'flex',
        '&:focus': {
            backgroundColor: 'white'
        },
    },
    disabled: {
        color: '#989b9e',
    },
});

function StylizedSelect(props) {
    const {id, disabled, name, inputProps, emptyLabel, options, data, dataItem} = props;
    const styles = useStyles(props);

    const handleChange = (e) => {
        props.onChange(e);
    };

    const getValue = () => {
        let names = name.split('.');
        switch (names.length) {
            case 1:
                return  dataItem ? (dataItem[name] || '') : (data.item[name] || '');
            case 2:
                return  dataItem
                    ? (!dataItem[names[0]] ? '' : (dataItem[names[0]][names[1]] || ''))
                    : (!data.item[names[0]] ? '' : (data.item[names[0]][names[1]] || ''));
            case 3:
                return dataItem
                    ? (!dataItem[names[0]] ? '' : (!dataItem[names[0]][names[1]] ? '' : (dataItem[names[0]][names[1]][names[2]] || '')))
                    : (!data.item[names[0]] ? '' : (!data.item[names[0]][names[1]] ? '' : (data.item[names[0]][names[1]][names[2]] || '')));
            default:
                throw new Error('Unsupported name depth: ' + names.length);
        }
    };

    const getLabel = () => {
        if (!props.options) {
            return '';
        }

        let label = '';
        let value = getValue();

        const jsonValue = JSON.stringify(value);
        for (let item of props.options) {
            if (JSON.stringify(item.value) === jsonValue) {
                label = item.label;
                break;
            }
        }
        return label || '';
    };

    return (
        <Select
            style={{width: '100%'}}
            inputProps={{id: id, title: getLabel()}}
            disabled={disabled}
            name={name}
            value={getValue()}
            classes={{...styles}}
            {...inputProps}
            onChange={handleChange}
            IconComponent={ExpandMoreIcon}
            displayEmpty>
            {emptyLabel && <MenuItem disabled value={0}>{emptyLabel}</MenuItem>}
            {options && options.map((item, i) => {
                return (
                    typeof item === 'object'
                        ? <MenuItem id={id + "-item-" + item.value} key={i} value={item.value}
                                    disabled={item.disabled}>{item.label}</MenuItem>
                        : <MenuItem id={id + "-item-" + item} key={i} value={item}
                                    disabled={item.disabled}>{item}</MenuItem>
                )
            })}
        </Select>
    )
}

export default StylizedSelect;

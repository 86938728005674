import React from "react";
import {Dialog, DialogActions} from "@material-ui/core";
import StylizedDialogTitle from "./StylizedDialogTitle";
import CustomizedButton from "./buttons/CustomizedButton";

export class DeleteDialog extends React.Component {
    render() {
        return (
            <Dialog open={this.props.open} maxWidth={this.props.maxWidth}>
                <StylizedDialogTitle
                    dialogTitle={this.props.header}
                />
                {this.props.children}
                <DialogActions style={{ justifyContent: 'flex-end', marginTop: '36px' }}>
                    <CustomizedButton
                        onClick={() => this.props.onCancel()}
                        title="Нет"
                    />
                    <CustomizedButton
                        onClick={() => this.props.onConfirm()}
                        disabled={this.props.disabled}
                        title="Да"
                        prim
                    />
                </DialogActions>
            </Dialog>
        )
    }
}

import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function BldDialog(props) {
    
    
  const [address, setAddress] = React.useState('');
   const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  
  const [count,setCount ] = React.useState(0);

	let { handleClose, dAddress,dName, dEmail, id, dialogOpen } = props;
    
    React.useEffect(() => {
         
         
         if (count == 0)
         {   
                setAddress(dAddress);
                setName(dName);
                setEmail(dEmail);
             
         }
         
    
    });
	
	function saveData(e){
        
        e.preventDefault();
        
        if (id==0)
        {
            if (address != '')
            {
                handleClose({address,name,email});
                setCount(0);
            }
        }
        else if (address != '' && id>0)
        {
            handleClose({address,name,email});
                setCount(0);
        }
            
	}
	
	function cancelData() {
		handleClose('');
		setCount(0);
	}
 
  return (

      <Dialog open={dialogOpen}>
	<DialogTitle>Площадка</DialogTitle>
    <form onSubmit={saveData}>
        <DialogContent style={{width:500}}>
         <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Название"
            fullWidth
            variant="standard"
			value={name}
            required
			onChange={(e)=>{setName(e.target.value);setCount(count+1)}}
          />
          <TextField
            autoFocus
            margin="dense"
            id="address"
            label="Адрес"
            fullWidth
            variant="standard"
			value={address}
            required
			onChange={(e)=>{setAddress(e.target.value);setCount(count+1)}}
          />
           
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="E-mail"
            fullWidth
            variant="standard"
			value={email}
			onChange={(e)=>{setEmail(e.target.value);setCount(count+1)}}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Сохранить</Button>
          <Button onClick={cancelData}>Отмена</Button>
        </DialogActions>
        </form>
      </Dialog>

  );
}
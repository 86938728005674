import React from "react";
import {IconButton, Tooltip} from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";

export class SvgButton extends React.Component {
    handleClick = (e) => {
        if (this.props.onClick) {
            e.stopPropagation();
            this.props.onClick(e);
        }
    };

    render() {
        const {
            box,
            path,
            name,
            size
        } = this.props;

        const styles = {
            icon: {
                borderRadius: '50%',
                backgroundColor: 'rgba(11, 18, 24, 0.06)',
                width: 46,
                height: 46,
                paddingRight: 13,
                color: '#000000',
                marginRight: 11,
            },
            icon_sm: {
                borderRadius: '50%',
                backgroundColor: 'rgba(11, 18, 24, 0.06)',
                width: 23,
                height: 23,
                paddingRight: 13,
                paddingTop: 4,
                color: '#000000',
            },
            svg: {
                width: 19,
                height: 19
            },
            svg_sm: {
                width: 15,
                height: 15
            }
        };

        return (
            <Tooltip title={name} aria-label={name}>
                <IconButton id={this.props.id} onClick={this.handleClick} style={size==="sm" ? styles.icon_sm : styles.icon}>
                    <SvgIcon style={size==="sm" ? styles.svg_sm : styles.svg}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox={box || "0 0 512 512"}><path d={path}/></svg>
                    </SvgIcon>
                </IconButton>
            </Tooltip>
        );
    }
}

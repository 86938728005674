import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function SectionDialog(props) {
  const [name, setName] = React.useState('');
  const [count,setCount ] = React.useState(0);
  const [showDeclined, setShowDecl ] = React.useState(false);

	let { handleClose,dialogOpen } = props;
    
    React.useEffect(() => {
         
         if (count == 0)
		  {
			setShowDecl(props.showDecl);
		  }
    
    });
	
	function saveData(){
        
        handleClose({showDeclined});
        setCount(0);
            
	}
	
	function cancelData() {
		handleClose(null);
		setCount(0);
	}
    
    function clickShowDecl(){
        
        setCount(1);
        setShowDecl(!showDeclined);
    }
 
  return (

      <Dialog open={dialogOpen}>
	<DialogTitle>Настройки</DialogTitle>
        <DialogContent>
        <FormGroup>
          <FormControlLabel control=
                        <Checkbox color="default" 
                        checked={showDeclined} onClick={clickShowDecl} /> label="Показывать отклоненных спикеров" />
                        
                        </FormGroup>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={saveData}>Сохранить</Button>
          <Button onClick={cancelData}>Отмена</Button>
        </DialogActions>
      </Dialog>

  );
}
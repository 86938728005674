import React from 'react';
import {FormControl, Grid, InputLabel, NativeSelect, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const Stylized = withStyles({
    root: {
        background: '#ffffff',
        border: 0,
        color: '#0b1218',
        height: 57,
    },
    icon: {
        width: 35,
        height: 35,
        backgroundColor: 'rgba(11, 18, 24, 0.06)',
        borderRadius: '50%',
        top: 'auto'
    },
    select: {
        paddingRight: '2em',
        fontWeight: 'bold',
        color: '#000',
        fontSize: 'inherit',
        display: 'flex',
        alignItems: 'center',
        '&:focus': {
            backgroundColor: 'white'
        },
    },
})(NativeSelect);

export class NativeSelectCell extends React.Component {
    handleChange = (e) => {
        this.props.onChange(e);
    };

    render() {
        return (
            <Grid item xs={this.props.xs ? this.props.xs : 12}>
                <FormControl fullWidth>
                    <InputLabel>{this.props.label}</InputLabel>
                    <Stylized inputProps={{id: this.props.id}}
                                  name={this.props.name}
                                  error={!!(this.props.dataError !== undefined ? this.props.dataError[this.props.name] : this.props.data.error[this.props.name])}
                                  value={this.props.dataItem !== undefined ? (this.props.dataItem[this.props.name] || 0) : (this.props.data.item[this.props.name] || 0)}
                                  {...this.props.inputProps}
                                  onChange={this.handleChange}>
                        {this.props.options && this.props.options.map(item => (
                            typeof item === 'object'
                                ? <option key={item.value} value={item.value}
                                          disabled={item.disabled}>{item.label}</option>
                                : <option key={item} value={item} disabled={item.disabled}>{item}</option>
                        ))}
                    </Stylized>
                    {(this.props.data !== undefined && this.props.data.error[this.props.name] !== '') &&
                    <Typography variant="caption" color="error">{this.props.data.error[this.props.name]}</Typography>}
                    {(this.props.dataError !== undefined && this.props.dataError[this.props.name] !== '') &&
                    <Typography variant="caption" color="error">{this.props.dataError[this.props.name]}</Typography>}
                </FormControl>
            </Grid>
        );
    }
}

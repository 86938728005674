import {userConstants} from "../_constants";
import {userService} from '../_services';
import {history} from '../_helpers';
import axios from 'axios';
import {serverUrl} from '../_constants'; 

export const userActions = {
    login,
    logout,
    registr,
    conf_list,
    conf_student_list,
    set_conf_list,
    add_conf,
    edit_conf,
    del_conf,
    disable_error,
    set_error,
    teamorg,
    set_conf,
    set_conf_selected,
    getuser,
    setuser,
    get_schedule,
    
}

         function setCookie(name, value, options) {
                options = options || {};
                let expires = options.expires;
                if (typeof expires == "number" && expires) {
                    var d = new Date();
                    d.setTime(d.getTime() + expires * 1000);
                    expires = options.expires = d;
                }

                if (expires && expires.toUTCString)
                    options.expires = expires.toUTCString();

                value = encodeURIComponent(value);
                let updatedCookie = name + "=" + value;
                for (let propName in options) {
                    updatedCookie += "; " + propName;
                    let propValue = options[propName];
                    if (propValue !== true) {
                        updatedCookie += "=" + propValue;
                    }
                }

                document.cookie = updatedCookie;
            } 

function login(data) {
    
    let md5hash = data.password;
    let username = data.username;
    return dispatch => {
        
        dispatch(request({username}));
        
        
         let data1 = JSON.stringify({"email":data.username,"password":md5hash});
		 
		 //data1 = '{"email":"org1@example.org","password":"password"}';
       
       axios.defaults.headers.post['Content-Type'] = "application/json"; 
       axios.defaults.withCredentials = true;
	   
       
       axios.post(serverUrl+'api/token', data1).then(
                user => {
					
					
					console.log(user);
                    
                    if (user.data)
                        setCookie("token",user.data);
                    
                    //axios.defaults.headers.post['Authorization'] = 'Bearer '+user.data;  
                    //axios.defaults.headers.get['Authorization'] = 'Bearer '+user.data;  
                    //axios.defaults.headers.patch['Authorization'] = 'Bearer '+user.data;  
                    setCookie("user",username);
                    setCookie("pwd",md5hash);
                    
                    dispatch(success(user));
                    localStorage.setItem('conf','{"id":0}'); 
                    localStorage.setItem('user','{}'); 
                    history.push('/');
                    
                },
                error => {
                    dispatch(failure(error));
                }
            );
     
    };
    

    function request(user) {

        return {type: userConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
		
        return {type: userConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.LOGIN_FAILURE, error}
    }
} 

function registr(data) {
    
    let md5hash = data.password;
    let username = data.username;
    return dispatch => {
        
        dispatch(request({username}));
        
        let data0 = {"email":data.username,"password":md5hash,"password_confirmation":md5hash};
        
         if (data.isStudent)
             data0['type'] = "student";
        
         let data1 = JSON.stringify(data0);
         
		
        axios.defaults.headers.post['Content-Type'] = "application/json"; 
        axios.defaults.withCredentials = true;
	   
        axios.post(serverUrl+'api/user', data1).then(
                user => {
					console.log(user);
                    
                    if (user.data && user.data.token)
                    {
                        setCookie("token",user.data.token);
                    
                        setCookie("user",username);
                        setCookie("pwd",md5hash);
                        dispatch(success(user));
                        localStorage.setItem('conf','{"id":0}'); 
                        localStorage.setItem('user','{}'); 
                        history.push('/');
                    }
                    
                },
                error => {
                    dispatch(failure(error));
                }
            );
     
    };
    

    function request(user) {

        return {type: userConstants.REG_REQUEST, user}
    }

    function success(user) {
		
        return {type: userConstants.REG_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.REG_FAILURE, error}
    }
}

function logout() {
    userService.logout();
    return {type: userConstants.LOGOUT};
}

function disable_error() {
    
    return {type: userConstants.DISABLE_ERROR};
}

function set_error(error,err_type="error") {

    return {type: userConstants.SET_ERROR,error:error,err_type:err_type};
}


function set_conf(selectedConf) {
    
    try {
        console.log(selectedConf);
        let dataStr = JSON.stringify(selectedConf);
        localStorage.setItem('conf',dataStr);
    }
    catch(e) {
        console.log("SAVE ERR",e);
    }
    
    return {type: userConstants.SET_CONF,selectedConf};
}

function set_conf_selected() {
    
    return {type: userConstants.SET_CONF_SELECTED};
}

function add_conf(data_conf) {
    
    return dispatch => {
        
        dispatch(request(data_conf));
        
         let data1 = JSON.stringify(data_conf);
         
           userService.postWrapper('api/conference',data1,function(data) {
                    //dispatch(success(data.data));
                    dispatch(success(data_conf));
       },function(error) {
        dispatch(failure(error));
        })
    }
    
     function request(data) {
        console.log(data);
        return {type: userConstants.ADDCONF_REQUEST, data}
    }

    function success(data) {
		
        return {type: userConstants.ADDCONF_SUCCESS, data}
    }

    function failure(error) {
        return {type: userConstants.ADDCONF_FAILURE, error}
    }
}    

function edit_conf(conf_data,id) {
    
    return dispatch => {
        
        dispatch(request(conf_data));
        
        
         let data1 = JSON.stringify(conf_data);
         
           userService.patchWrapperBody('api/conference/'+id,data1,function(data) {
               
                    dispatch(success(conf_data));
       },function(error) {
        dispatch(failure(error));
        })
    }
    
     function request(data) {
        console.log(data);
        return {type: userConstants.EDITCONF_REQUEST, data}
    }

    function success(selectedConf) {
        
        
        return {type: userConstants.EDITCONF_SUCCESS, selectedConf}
    }

    function failure(error) {
        
        console.log(error);
        
        return {type: userConstants.EDITCONF_FAILURE, error}
    }
}


function del_conf(id) {
    
    return dispatch => {
        
        dispatch(request(id));
        
        
         
           userService.delWrapper('api/conference/'+id,function(data) {
               
                    dispatch(success(id));
       },function(error) {
        dispatch(failure(error));
        })
    }
    
     function request(id) {
        console.log(id);
        return {type: userConstants.DELCONF_REQUEST, id}
    }

    function success(id) {
        
        
        return {type: userConstants.DELCONF_SUCCESS, id}
    }

    function failure(error) {
        
        console.log(error);
        
        return {type: userConstants.DELCONF_FAILURE, error}
    }
}        

function setuser(userData) {
    
     
    return dispatch => {
         dispatch(success(userData));
    }
    
     function success(data) {
		
         console.log("USR SET",data);
       
        let info = {...data};
                   
        return {type: userConstants.USERINFO_SUCCESS, info}
    }
}

function getuser() {
    
    return dispatch => {
        
       dispatch(request({}));
       
         userService.getWrapper('api/user',function(data) {
			 
			 console.log(data.data);
           
                    dispatch(success(data.data));
       },function(error) {
        dispatch(failure(error));
        })
       
    
    }
    
     function request(data) {
        console.log(data);
        return {type: userConstants.USERINFO_REQUEST, data}
    }

    function success(data) {
		
         console.log("USR",data);
       
        let info = {...data};
        localStorage.setItem('user',JSON.stringify(info));
                   
        return {type: userConstants.USERINFO_SUCCESS, info}
    }

    function failure(error) {
        localStorage.setItem('user',JSON.stringify({}));
        return {type: userConstants.USERINFO_FAILURE, error}
    }
}    


function set_conf_list(data) {
    
    return {type: userConstants.CONFLIST_SUCCESS, conflist:[...data]}
}

function conf_list(data) {
    
    return dispatch => {
        
       dispatch(request({}));
       
         userService.getWrapper('api/conference',function(data) {
			 
			 console.log(data.data);
           
                    dispatch(success(data.data));
       },function(error) {
        dispatch(failure(error));
        })
       
      
    
    }
    
     function request(data) {
        console.log(data);
        return {type: userConstants.CONFLIST_REQUEST, data}
    }

    function success(data) {
		
         console.log("CONF");
       
       let conflist = [...data];
                   
        return {type: userConstants.CONFLIST_SUCCESS, conflist}
    }

    function failure(error) {
        return {type: userConstants.CONFLIST_FAILURE, error}
    }
}    


function conf_student_list(data) {
    
    return dispatch => {
        
       dispatch(request({}));
       
         userService.getWrapper('api/conference/all',function(data) {
			 
           
                    dispatch(success(data.data));
       },function(error) {
        dispatch(failure(error));
        })
       
      
    
    }
    
     function request(data) {
        console.log(data);
        return {type: userConstants.CONFLIST_STUDENT_REQUEST, data}
    }

    function success(data) {
		
         console.log("CONF");
       
       let conflist = [...data];
                   
        return {type: userConstants.CONFLIST_STUDENT_SUCCESS, conflist}
    }

    function failure(error) {
        return {type: userConstants.CONFLIST_STUDENT_FAILURE, error}
    }
}    


function teamorg(data) {
    
    return dispatch => {
        
       dispatch(request({}));
       
       userService.getWrapper('records/conference?join=building',function(data) {
           
                    console.log("TEAM");
                    console.log(data);
                    dispatch(success(data.data));
       },function(error) {
        dispatch(failure(error));
        })
       
    }
    
     function request(data) {
        
        return {type: userConstants.TEAMORG_REQUEST, data}
    }

    function success(data) {
		console.log(data);
        return {type: userConstants.TEAMORG_SUCCESS, data}
    }

    function failure(error) {
        console.log(error);
        return {type: userConstants.TEAMORG_FAILURE, error}
    }
}    


function get_schedule(id) {
    
    return dispatch => {
        
        let sections = [];
        
       dispatch(request({}));
       
       userService.getWrapper('api/schedule/'+id,function(data) {
           
                    console.log("SCHEDULE");
                    console.log(data);
                    dispatch(success(data.data));
                    
                    dispatch(request_sections({}));
                    
                    userService.getWrapper('api/section?conference_id='+id,function(data) {
           
                    dispatch(success_sections(data.data));
                    
                    
                    },function(error) {
                        dispatch(failure_sections(error));
                        })
                    
                    
       },function(error) {
        dispatch(failure(error));
        })
       
    }
    
      function request_sections(data) {
        
        return {type: userConstants.SECTIONS_REQUEST, data}
    }

    function success_sections(data) {
		console.log(data);
        return {type: userConstants.SECTIONS_SUCCESS, data}
    }

    function failure_sections(error) {
        console.log(error);
        return {type: userConstants.SECTIONS_FAILURE, error}
    }
    
     function request(data) {
        
        return {type: userConstants.SCHEDULE_REQUEST, data}
    }

    function success(data) {
		console.log(data);
        return {type: userConstants.SCHEDULE_SUCCESS, data}
    }

    function failure(error) {
        console.log(error);
        return {type: userConstants.SCHEDULE_FAILURE, error}
    }
}

    
    
import React from 'react';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import '../css/style.css';
import '../css/constructor.css';
import classNames from 'classnames'
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SelectTheme from './SelectTheme';
import SelectRoom from './SelectRoom';
import SelectSpeaker from './SelectSpeaker';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import SaveIcon from '@material-ui/icons/Save';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import crossLogo from '../img/cross.png';
import timeLogo from '../img/time.png';


const STATUS_INVITED = 'invited';
    const STATUS_APPROVED = 'approved';
    const STATUS_DECLINED = 'declined';
    const STATUS_CONTRACT_SENT = 'contract_sent';
    const STATUS_CONTRACT_SIGNED = 'contract_signed';
    const STATUS_ACT_SIGNED = 'act_signed';
    const STATUS_WAITING_FOR_PAYMENT = 'waiting_for_payment';
    const STATUS_PAID = 'paid';

function getFormat(tp) {

    if (!tp || tp == "offline") return "оффлайн";
    if (tp == "online") return "онлайн";

    return "гибрид"
}

function SpeakersList(props) {

    let {
        speakers,
        mainIndex,
        index,
        delSpeaker,
		addSpeaker,
        allSpeakers,
        onSpeakerTextChange,
        onSpeakerContractChange,
        onSpeakerPartipChange,
        saveTime,
        showDeclined,
		
    } = props;
    

    let filteredSpeakers = []


    for (let i = 0; i < allSpeakers.length; i++) {
        let isAdded = false;
        for (let j = 0; j < speakers.length; j++)
            if (speakers[j].name == allSpeakers[i]) {
                isAdded = true;
                break;
            }
        if (isAdded == false)
            filteredSpeakers.push(allSpeakers[i]);
    }

    return (
        <ul className='speakers-list'>
            {speakers.filter(e=> showDeclined || e.status != STATUS_DECLINED).map(function (item, indexSpeaker) {

                    let iName = '';
                    if (item && item.name) iName = item.name;


                    return (
                        <li>
                            <Droppable droppableId={"speaker_" + mainIndex + "_" + index + "_" + indexSpeaker}>
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef} class="speaker-choose">
                                        <SelectSpeaker data={filteredSpeakers} item={iName}
                                                       onChange={(e) => onSpeakerTextChange(e, indexSpeaker)}/>

                                    </div>

                                )}
                            </Droppable>
                            {iName != '' && <div class="calendar-block__col status-speaker" title="Выбор статуса участия">
                                <span>Участие</span>

                                <select class="calendar-block__select"
                                        onChange={(e) => onSpeakerPartipChange(e, indexSpeaker)}>

                                    {item.status == "1" ? <option value="1" selected>не выбрано</option> :
                                        <option value="1">не выбрано</option>}
                                    {item.status == STATUS_INVITED ?
                                        <option value={STATUS_INVITED} selected>участие запрошено</option> :
                                    <option value={STATUS_INVITED}>участие запрошено</option>}
                                    {item.status == STATUS_DECLINED ?
                                    <option value={STATUS_DECLINED} selected>участие отклонено</option> :
                                        <option value={STATUS_DECLINED}>участие отклонено</option>}
                                    {item.status == "approved" ?
                                        <option value="approved" selected>участие подтверждено</option> :
                                        <option value="approved">участие подтверждено</option>}
                                    {item.status == "without_contract" ?
                                        <option value="approved" selected>договор не нужен</option> :
                                        <option value="approved">договор не нужен</option>}    
                                        
                                    {item.status == "contract_sent" ?
                                        <option value="contract_sent" selected>договор направлен</option> :
                                        <option value="contract_sent">договор направлен</option>}
                                    {item.status == "act_signed" ?
                                        <option value="act_signed" selected>акт подписан</option> :
                                        <option value="act_signed">акт подписан</option>}
                                    {item.status == "waiting_for_payment" ?
                                        <option value="waiting_for_payment" selected>в оплате</option> :
                                        <option value="waiting_for_payment">в оплате</option>}
                                    {item.status == "paid" ? <option value="paid" selected>оплачен</option> :
                                        <option value="paid">оплачен</option>}
                                </select>
                            </div>}

                            <div className="addSpeaker" onClick={() => addSpeaker(index, mainIndex)}>
                                <i className='icon-plus-border'></i>
                            </div>

                        </li>)
                }
            )}
        </ul>
    )
}


export default function SlotItem(props) {

    let {
        item,
        index,
        mainIndex,
        delTheme,
        delRoom,
        delSpeaker,
        themes,
        rooms,
        changeThemes,
        changeRoom,
        delSlot,
		unbindSlot,
        addSpeaker,
        changeSpeakers,
        speakers,
        changeSpeakerPartip,
        saveTime,
        changeMode,
        saveUrl,
		moveSlot,
		plusDuration,
		minusDuration,
        showDeclined,
        
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const [focus, setFocus] = React.useState(false);
    const [focusUrl, setFocusUrl] = React.useState(false);
    const [startTime, setTime] = React.useState(null);
    const [oldTime, setOldTime] = React.useState(null);
    const [oldDur, setOldDur] = React.useState(null);
    const [duration, setDuration] = React.useState(null);
    const [url, setUrl] = React.useState(null);
    const [count, setCount] = React.useState(0);
    const [id, setId] = React.useState(null);

    React.useEffect(() => {

        if ((!id && item.id) || (item.id != id && item.id))
            setId(item.id);

        if (count == 0) {

            if (item.url_online)
                setUrl(item.url_online);

            setTime(item.start_time ? item.start_time : '');
            setOldTime(item.start_time ? item.start_time : '');
            setDuration(item.duration ? item.duration : '');
            setCount(1);
        }

        if (item.start_time != oldTime) {
            setTime(item.start_time);
            setOldTime(item.start_time);
        }

        if (item.duration != oldDur) {
            setDuration(item.duration);
            setOldDur(item.duration);
        }

    });

    const changeStart = (e) => {

        setTime(e.target.value);
        setCount(count + 1);
    }

    const changeDuration = (e) => {

        setDuration(e.target.value);
        setCount(count + 1);
    }

    const changeUrl = (e) => {

        setUrl(e.target.value);
        setCount(count + 1);
    }

    const onFocusOut = () => {

        if (focus == true) {
            setFocus(false);
            clickSave();
        }
    }

    const onFocusUrlOut = () => {

        if (focusUrl == true) {
            setFocusUrl(false);
            clickSaveUrl();
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };


    function clickSave() {
        console.log(item);
        saveTime(id, startTime, duration);
    }

    function clickSaveUrl() {
        console.log(item);
        saveUrl(id, url);
    }

    function onTextChange(e, oldVal) {
        changeThemes(e, oldVal);
    }


    function onSpeakerTextChange(e, i) {
        changeSpeakers(e, i);
    }

    function onSpeakerPartipChange(e, i) {
        //console.log(e.target.value,i);
        changeSpeakerPartip(e.target.value, i);
    }


    function openMenu(event) {

        setAnchorEl(event.currentTarget);
    };

    
    return (
        <div class="line-constructor">
            <div class="time">
                <div class="time-value">
                    <input type="time" id="time"
                           onBlur={onFocusOut}
                           onFocus={() => setFocus(true)}
                           onChange={changeStart}
                           value={startTime ? startTime : ''} min="00:00" max="23:59" style={{width: 110}}/>
                </div>
                <div class={classNames("select-format", item.type)} onClick={changeMode}
                     title={"Нажмите чтобы изменить формат"}>
                    <span>{getFormat(item.type)}</span>
                </div>
            </div>
            <div class="move">

            </div>
            <div class="duration">
                <div class="duration-item">
                    <div class="minus" onClick={()=>minusDuration(mainIndex,index)}>
                        <i className="icon-minus"></i>
                    </div>
                    <label for="">
                        <input type="number" id="duration"
                               min={1}
                               onBlur={onFocusOut}
                               onFocus={() => setFocus(true)}
                               onChange={changeDuration}
                               value={duration ? duration : ''} min="0" style={{height: 50}}/>
                        <span>минут</span>
                    </label>
                    <div class="pluse" onClick={()=>plusDuration(mainIndex,index)}>
                        <i class="icon-plus"></i>
                    </div>
                </div>
            </div>
            <div class="theme">
                <div class="theme-item">
                    <Droppable droppableId={"theme_" + mainIndex + "_" + index}>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} class="input-wrap">
                                <div class="slot_wrapper">
                                    <SelectTheme data={themes} item={item.theme ? item.theme : ''}
                                                 onChange={onTextChange}/>
                                </div>

                            </div>
                        )}
                    </Droppable>
                </div>
            </div>
            <div class="speaker">
               

                <SpeakersList speakers={item.speakers} mainIndex={mainIndex} index={index}
                              allSpeakers={speakers}
							  addSpeaker={addSpeaker}
                              onSpeakerTextChange={onSpeakerTextChange}
                              onSpeakerPartipChange={onSpeakerPartipChange}
                              delSpeaker={delSpeaker}
                              showDeclined={showDeclined}/>


            </div>
            <div class="room">
                <div class="room-item">
                    {(item.type == "hybrid" || item.type == "offline" || !item.type) &&
                        <Droppable droppableId={"room_" + mainIndex + "_" + index}>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} class="input-wrap">
                                    <div class="slot_wrapper">
                                        <SelectRoom data={rooms} id={item.room_id ? item.room_id : 0}
                                                    item={item.room ? item.room : ''}
                                                    onChange={(e) => changeRoom(e, item)}/>
                                    </div>

                                </div>
                            )}
                        </Droppable>}
                    {(item.type == "hybrid" || item.type == "online") && <input
                        style={{marginTop: 5}}
                        placeholder="url лекции"
                        onBlur={onFocusUrlOut}
                        onFocus={() => setFocusUrl(true)}
                        onChange={changeUrl}
                        value={url}/>

                    }
                </div>
            </div>
            <div class="options" style={{marginTop:10,marginLeft:10,width:40}}>
                 <Menu
								id="fade-menu"
								MenuListProps={{
								  'aria-labelledby': 'fade-button',
								}}
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								TransitionComponent={Fade}
								  anchorOrigin={{
								  vertical: 'top',
								  horizontal: 'right',
								}}
							
							  >
							   <MenuItem onClick={()=>moveSlot(mainIndex,id)}>Переместить в секцию</MenuItem>
							{mainIndex>=0 && <MenuItem onClick={unbindSlot}>Открепить от секции</MenuItem>}
								<MenuItem onClick={delSlot}>Удалить слот</MenuItem>
							  </Menu>
								<MoreIcon onClick={openMenu}/>
            </div>
        </div>
    );
}
import React from 'react';
import {Button, Grid} from "@material-ui/core";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import TextField from '@material-ui/core/TextField';

import { SendButton, TextFieldCell, Snack} from "../_components";
import { history } from '../_helpers';
import { CircularProgress } from '@material-ui/core';
import {cookieText} from '../_constants'; 
import {connect} from 'react-redux';
import {userActions} from '../_actions';
import {store} from '../_helpers'; 
import CookieConsent, { Cookies } from "react-cookie-consent";

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
 

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        // reset login status
      
        this.state = {
            username: '',
            pwd: '',
            submitted: false,
            alertShow: false,
            err_type:'',
            snackText: '',
        };
        
        store.subscribe(this.storeChange)
    }
    
    storeChange = (e) => {
        
        console.log(e);
        console.log(store.getState());
        if (store.getState().authentication.error && store.getState().authentication.isLoading == false)
        {
            //alert(store.getState().authentication.error);
            //alert("Неправильнй email пользователя или пароль");
            this.setState({snackText:"Неправильные email пользователя или пароль",alertShow:true,err_type:"error"});
            
        }
        
        
    }
    
    componentDidMount() {
     
     const {dispatch} = this.props;  
     
        let username = getCookie('user'); 
        let pwd = getCookie('pwd');  
        
        if (username && pwd)
        {
            this.setState({username,pwd});
            dispatch(userActions.login({username:username,password:pwd}));
        }
    }
    
    onCloseSnack = () => {
        this.setState({alertShow: false});
    };

    handleChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };
    
    openReg = () => {
        
        //alert(window.location.href);
        history.push('/registration');
    }

    handleSubmit = (e) => {
        
        
        var self = this;
        
        e.preventDefault();

        this.setState({submitted: true});
        const {username, pwd} = this.state;
        const {dispatch} = this.props;  
        
        
        if (username.length>0 && pwd.length>0) {
            
            localStorage.setItem('user','{}');
          
            dispatch(userActions.login({username:username,password:pwd}));
        }
        else
        {
            
            this.setState({snackText:'Введите email и пароль',alertShow:true,err_type:"warning"});
        }
    };
    
   
    render() {
        
        console.log(this.props);
        
        const { error, isLoading } = this.props;
        
        const {username, submitted, pwd, alertShow, err_type, snackText} = this.state;
        
        let errorMsg = 'Неверный токен';
        if (error) {
            if (error === "Forbidden") errorMsg = "Неправильный токен";
        }
        
        
        

        return (
            <div>
            <Grid container justify="center" alignItems="center" style={{height: '100vh'}}>
                <Grid item style={{width: 400}}>
                    <form name="form" onSubmit={this.handleSubmit} style={{justifyContent: 'center'}}>
                        <div style={{verticalAlign: 'center'}}>
                      
                        </div>
                        <div className={(submitted && !username ? ' has-error' : '')}>
                            
                             <TextFieldCell id="login"
                                       label="E-mail пользователя"
                                       name="username"
                                       type="text"
                                       value={username}
                                       onChange={this.handleChange}/>             

                        </div>
                        <div className={(submitted && !username ? ' has-error' : '')}>
                            
                             <TextFieldCell id="pwd"
                                       label="Пароль"
                                       name="pwd"
                                       type="password"
                                       value={pwd}
                                       onChange={this.handleChange}/>             

                        </div>
                        
                        <div className="form-group"
                             style={{marginTop: 5, justifyContent: 'space-between', flex: 1, display: 'flex'}}>
                            
                                    <CustomizedButton
                                    style={{marginBottom: 5}}
                                    
                        id="category-button-add"
                        type="submit"
                        title="Войти"
                        prim
                    />
                    <CustomizedButton
                                    style={{marginBottom: 5}}
                                    onClick={this.openReg}
                        id="category-button-add"
                        title="Регистрация"
                        prim
                    />
                        </div>
                    </form>
                    <a href="https://api.naviconf.com/auth/google?type=org" 
                    style={{marginTop: 30,fontSize:20}}>
                    <span style={{color:'blue', textDecoration: 'underline'}}>Войти через Google как организатор</span>
                    </a>
                    
                    <a href="https://api.naviconf.com/auth/google?type=student" 
                    style={{marginTop: 30,fontSize:20}}>
                    <span style={{color:'blue', textDecoration: 'underline'}}>Войти через Google как посетитель</span>
                    </a>
        
                    
                </Grid>
            </Grid>
            {isLoading && <div className="shadow"><div className="shadow_circle"><CircularProgress/></div></div>}    
               <CookieConsent
              location="bottom"
              buttonText="Согласен"
              cookieName="myAwesomeCookieName2"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
        >
              {cookieText}
            </CookieConsent>
            <Snack open={alertShow} variant={err_type} text={snackText} onClose={this.onCloseSnack}/>
            </div>
        );  
    }
}

function mapStateToProps(state) {
    const {loggingIn, error, isLoading} = state.authentication;
    return {
        loggingIn,
        isLoading,
        error
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export {connectedLoginPage as LoginPage};
import React from "react";

const styles = {
        title: {
            fontSize: 32,
            fontWeight: 'bold',
            lineHeight: 1.22,
            letterSpacing: -0.55,
            color: '#0b1218',
            paddingTop: '10px',
            paddingBottom: 24,
            paddingLeft: 24,
        }
};

export default function StylizedDialogTitle(props) {
        return(
                <div
                    style={styles.title}>
                    {props.dialogTitle}
                    {props.children}
                </div>
        )
}

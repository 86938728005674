import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import '../css/style.css';
import {serverUrl} from '../_constants'; 
 
const getDate = (time) => {

   if (!time) return "не определено";
   
   let time1 = time.substr(0,10);
   
   return time1.split('-').reverse().join('-');
   
   return time1;
}    

export default function ConfItem(props) {
    
    let { data, onEdit,  onClick } = props;
    

  return (
   <li class="conf-list__item">
						<div class="card" onClick={props.onClick}>
							<div class="card__top" onClick = {props.onEdit}>
								<img src={data.image?serverUrl+data.image.replace('public','/storage'):''} alt="" class="object-fit is--cover"/>
                                
							</div>

							<div class="card__body">
								<p style={{marginBottom:10,fontSize:18,fontWeight:500}}>{data.name}</p>
                                <div class="layout__inf">
								
									<p class="layout__inf-text">{getDate(data.start_time)} - {getDate(data.end_time)}</p>
	
                                    {!data.building_id && <p class="layout__inf-text">Нет адреса</p>}
                                    
                                    <p class="layout__inf-text">{data.hasTicket?'Билет куплен':'Нет билета'}</p>    
	
								</div>
                                <div class="layout__inf">
                                <ul class="layout__inf-list">
										<a onClick = {props.onEdit} class="card__link"><span>Посмотреть</span></a>
									</ul></div>

							</div>
						</div>
					</li>
  );
}
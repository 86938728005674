import React from 'react';
import {FormControl, Grid} from "@material-ui/core";
import StylizedInputLabel from "../_components/StylizedInputLabel";
import StylizedSelect from "../_components/StylizedSelect";
import StylizedHelper from "./StylizedHelper";

export class SelectCell extends React.Component {
    render() {
        const {
            dataError,
            name,
            data,
            disabled,
            label,
            xs,
            tall,
        } = this.props;

        const isError = !!(dataError ? dataError[name] : data.error[name]);

        return (
            <Grid item xs={xs || 12}>
                <StylizedInputLabel disabled={disabled}
                                    style={(label !== '' && tall) && {marginBottom: '23px'}}>
                    {label}
                </StylizedInputLabel>
                <FormControl
                    fullWidth
                    error={isError}>
                    <StylizedSelect {...this.props}/>
                    <StylizedHelper>
                        {data && data.error[name] !== '' && data.error[name]}
                        {dataError && dataError[name] !== '' && dataError[name]}
                    </StylizedHelper>
                </FormControl>
            </Grid>
        );
    }
}

import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Router, Route } from 'react-router-dom';
import {PrivateRoute} from './_components';
import { history } from './_helpers';
import { MainPage } from './MainPage';
import { LoginPage, GooglePage, RegistrPage } from './LoginPage';


const routes = ["/conf_editor","/conf_prog","/team_org","/new_conf","/blds",
                "/speakers","/sponsors","/nav","/client_view","/partips","/conf_view","/tickets",
                ];
                
const routes_id = ["/conf_editor/:id","/conf_prog/:id",
                "/nav/:id","/client_view/:id","/partips/:id","/conf_view/:id",
                ];

export class App extends React.Component {
    constructor(props) {
        super(props);
       
    }
  
    componentDidMount() {
        document.title = 'Naviconf';
        
    }
    
  render() {  
    return (
    
        <Router history={history}>
                 <Route path="/login" component={LoginPage}/> 
                 <Route path="/registration" component={RegistrPage}/> 
                 <Route path="/google-callback" component={GooglePage}/> 
                 <Route path="/google-callback-s" component={GooglePage}/> 
                 <PrivateRoute exact path={"/"} component={MainPage} />
                 <PrivateRoute path={"/purchased-:pid"} component={MainPage} />
                 
                 
            {routes.map(item => 
                <PrivateRoute exact path={item} component={MainPage} />
            )}          
            {routes_id.map(item => 
                <PrivateRoute path={item} component={MainPage} />
            )}          
        </Router>
    
    )}
  
}

export default App;

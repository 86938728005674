import {scheduleConstants} from '../_constants';

const initialState = {
    sections: [],
	slots:[],
    themes: [],
    themes_id:[],
    rooms: [],
    speakers: [],
	allLessons:[],
    curDate: null,
    isLoading: false,
    fileStatus:0,
    fileData:null,
    
}
 

export function schedule(state = initialState, action) {
    
    
    if (action.type === scheduleConstants.SET_SCHEDULE) {
        return {
            ...state, sections: action.data
        };
    } 
	  if (action.type === scheduleConstants.SET_SLOTS) {
        return {
            ...state, slots: action.data
        };
    } 
    else if (action.type === scheduleConstants.SET_DATE) {
        
        return {
            ...state, curDate: action.data
        };
    } 
    else if (action.type === scheduleConstants.SET_FILE_STATUS) {
        
        return {
            ...state, fileStatus: action.data
        };
    } 
    else if (action.type === scheduleConstants.SET_FILE_DATA) {
        
        return {
            ...state, fileData: action.data
        };
    }
    else if (action.type === scheduleConstants.SET_ROOMS) {
        
        return {
            ...state, rooms: action.data
        };
    } 
    else if (action.type === scheduleConstants.SET_THEMES) {
        
        return {
            ...state, themes: action.data
        };
    } 
    else if (action.type === scheduleConstants.SET_SPEAKERS) {
        
        return {
            ...state, speakers: action.data
        };
    }
    else if (action.type === scheduleConstants.SET_LESSONS) {
        
        return {
            ...state, allLessons: action.data
        };
    }
    else if (action.type === scheduleConstants.SET_THEMES_ID) {
        
        return {
            ...state, themes_id: action.data
        };
    }
    else {
        return state;
    }
    
}
import React from "react";
import {withStyles, Button} from "@material-ui/core";

export default function CustomizedButton(props) {
    const StylizedButton = withStyles({
        root: {
            height: props.mini ? 32 : 46,
            borderRadius: 5,
            border: 'none',
            backgroundColor: props.prim ? '#020211' : '#e0e0e0',
            opacity: 1,
            textTransform: 'unset',
            textAlign: 'center',
            fontSize: props.mini ? 15 : 19,
            fontWeight: 500,
            lineHeight: 1.26,
            letterSpacing: 0.73,
            color: props.prim ? '#ffffff' : '#000000',
            padding: props.miniWidth ? '0 1em' : (props.prim ? '0 40px' : '0 20px'),
            '&:hover': {
                opacity: 1,
                backgroundColor: props.prim ? '#07214b' : '#cbcbcb',
                color: props.prim ? '#ffffff' : '#000000',
            },
            '&:disabled': {
                opacity: 0.24,
                color: '#ffffff',
                cursor: 'default',
            },
            '&:active': {
                opacity: props.prim ? 0.9 : 0.9,
            },
            ...props.className,
        },
    })(Button);

    return (
        <StylizedButton
            id={props.id}
            variant="outlined"
            value={props.value}
            disableRipple
            disableFocusRipple
            disabled={props.disabled}
            onClick={props.onClick}
            title={props.title}
            type={props.type}
            className={props.className}
        >
            {props.icon}
            {props.icon && <span>&nbsp;</span>}
            {props.title || 'Сохранить'}
        </StylizedButton>
    )
}

import React from 'react';
import PropTypes from 'prop-types';
import { history } from '../_helpers';
import {  confService } from '../_services';
import CompanyForm from "./CompanyForm";
import { CreateConfPage } from "./CreateConfPage";
import { ConfNavPage } from "./ConfNavPage";
import { ConfListPage } from "./ConfListPage";
import { TeamPage } from "../TeamPage/TeamPage";
import { ClientListPage, ClientConfPage } from "../ClientPages";
import { SpeakersPage } from "../SpeakersPage/SpeakersPage";
import { SponsorPage } from "../SponsorPage/SponsorPage";
import { BuildingsPage } from "../BuildingsPage/BuildingsPage";
import { PartPage } from "../PartPage/PartPage";
import { TicketsPage } from "../ClientPages/TicketsPage";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import { SendButton, TextFieldCell, Snack} from "../_components";
import {DeleteButton} from "../_components/buttons/DeleteButton";
import {EditButton} from "../_components/buttons/EditButton";
import { CircularProgress } from '@material-ui/core';
import '../fonts/fonts.css';
import '../css/style.css';
import {cookieText} from '../_constants'; 

import {DeleteDialog} from "../_components";
import { storeData, setStore } from '../store.js';
import {AppMenu} from "../_components/AppMenu";
import {HeadMenu} from "../_components/HeadMenu";
import { connect } from "react-redux";
import {store} from '../_helpers'; 
import {appActions, userActions} from "../_actions";
import i18next from 'i18next';
import TimeTable from '../TimePage/TimeTable'
import ym from 'react-yandex-metrika';

import CookieConsent, { Cookies } from "react-cookie-consent";

const USER_ORG = "org";
const USER_MEMBER = "team_member";
const USER_STUDENT = "student";

function setCookie(name, value, options) {
                options = options || {};
                let expires = options.expires;
                if (typeof expires == "number" && expires) {
                    var d = new Date();
                    d.setTime(d.getTime() + expires * 1000);
                    expires = options.expires = d;
                }

                if (expires && expires.toUTCString)
                    options.expires = expires.toUTCString();

                value = encodeURIComponent(value);
                let updatedCookie = name + "=" + value;
                for (let propName in options) {
                    updatedCookie += "; " + propName;
                    let propValue = options[propName];
                    if (propValue !== true) {
                        updatedCookie += "=" + propValue;
                    }
                }

                document.cookie = updatedCookie;
            }

class MainPage extends React.Component {


	constructor(props)
	{
		super(props);
        
		this.state = {showModal:false, companies:[],hoverIndex:-1, isEdit:false, userType:'',userName:'',email:'',err_type:'error',
        menuOpen:true,loadConfInfo:false,loadTicketInfo:false, confInfo:null,tid:0,confName:'',tickets:[]};
		store.subscribe(this.storeChange)
        
        
	}
    
     storeChange = (e) => {
        
        const { userType } = this.state;
        let self = this;
        
        if (store.getState().user.error)
        {
            let err1 = ''+store.getState().user.error;
            
            if (err1)
                this.setState({snackText:err1,alertShow:true,err_type:'error'});
            else
                this.setState({snackText:'Ошибка выполнения запроса',alertShow:true,err_type:'error'});
            
            setTimeout(() => {
                self.props.dispatch(userActions.disable_error());    
                //alert('');
            }, 100)
            
            
        }
        
        if (store.getState().user.info && store.getState().user.info.type)
        {
            this.setState({userType:store.getState().user.info.type,userName:store.getState().user.info.name,email:store.getState().user.info.email});
        }
        
        
        
     }    
    

    componentDidMount() {
        
    //alert(this.props.app.page);
    this.setLanguage(this.props.app.lang);
    
    let userStrData = localStorage.getItem('user');
    
    console.log(userStrData);
    
    if (userStrData && userStrData != '{}')
    {
        try {
            let objData = JSON.parse(userStrData);
            console.log(objData);
            ym('setUserID', objData.email);
            this.props.dispatch(userActions.setuser(objData));    
        }
        catch(e) {
            console.log(e);
        }
    }
    else
    {
        console.log(userStrData);
        this.props.dispatch(userActions.getuser());    
    }
    
    let strData = localStorage.getItem('conf');
    if (strData != "null")
    {
        try {
            let objData = JSON.parse(strData);
            this.props.dispatch(userActions.set_conf(objData));    
        }
        catch(e) {
            console.log(e);
        }
    }
    
    let url = window.location.pathname;
    let self = this;
    
    ym('hit', url);    
    
        if (url.indexOf('/purchased')>=0)
        {
            let url_arr = url.replace('/purchased-','').split('-');
            
            
            
            if (url_arr.length>1)
            {
                this.setState({tid:url_arr[1]});
                confService.confInfo(url_arr[0],function(data){
                
                    self.setState({confName:data.data.name,confInfo:data.data,loadConfInfo:true});
                    console.log(data.data);
                    
                    if (self.state.loadConfInfo)
                        self.getConfTicket(data.data.name,self.state.tickets);
                
                    },function(err) { console.log(err)});
                    
                confService.ticketsInfo(url_arr[0],function(data){
                
                    self.setState({tickets:data.data,confInfo:data.data,loadTicketInfo:true});
                    
                    
                    if (self.state.loadConfInfo)
                        self.getConfTicket(self.state.confName,data.data);
                    
                
                    },function(err) { console.log(err)});
            }
            
        }
        else
        {
            let url1 = url.replace('/','');
            
            let urls = url1.split('/');
            let url0 = urls[0];
            
            let id = 0;
            if (urls.length>1)
                id = urls[1];
            
            
            if (url0 == "")
                url0 = "confs";
            
            this.props.dispatch(appActions.setpage(url0,id));
            
        }
    
    
    //alert(this.props.app.lang);
  }
   
    setLanguage(language) {
    i18next.init({
      lng: language,
      resources: require(`../_lang/${language}.json`)
    });

    this.props.dispatch(appActions.setlang(language));
    
    
    }    
    
    getConfTicket = (name,tickets) => {
        
        console.log(name);
        console.log(tickets);
        
        const { tid } = this.state;
        
        let tname = '';
        let tcost = '';
        
        for (let i=0;i<tickets.length;i++)
            if (tickets[i].id == tid)
            {
                tname = tickets[i].name;
                tcost = tickets[i].cost;
                
            }
        
        if (tcost != '' && tcost>0)
        {
            alert('Вы купили билет "'+tname+'" на конференцию "'+name+'"');
            this.props.dispatch(appActions.setpage("confs"));
        }
        else
        {
            alert('Вы зарегистрировались на конференцию "'+name+'"');
            this.props.dispatch(appActions.setpage("confs"));
        }
        
    }
    
    hideModal = () => {
        this.setState({showModal: false});
    };

   
    
    onCloseSnack = () => {
        this.setState({alertShow: false});
    };
    
    
   

    render() {
	
		let self = this;
        const { userType,userName,menuOpen,email, alertShow, snackText, err_type } = this.state;
        const { page } = this.props.app;
        const { user } = this.props;
        console.log(user);	
        console.log(this.state);
        
        //<AppMenu menu={this.state.menu} selectedMenu={this.state.location} open={this.state.open}/>
        
        return (
        <div >
        <AppMenu open={menuOpen} onOpen={()=>self.setState({menuOpen:!menuOpen})} userType={userType} conf =  {user.selectedConf} />
		<div class={menuOpen?"wrapper__open":"wrapper"}>
		<HeadMenu name={userName} email={email} type={userType}/>
           <div class="layout_main">
           {(userType ==  USER_STUDENT)  && page =="confs" && <ClientListPage/>}
           {(userType ==  USER_STUDENT)  && page =="conf_view" && <ClientConfPage/>}
           {(userType ==  USER_STUDENT)  && page =="tickets" && <TicketsPage/>}
           
        {(userType == USER_ORG || userType == USER_MEMBER)  && (page =="confs" || page =="confs_title") && <ConfListPage/>}
           {(userType == USER_ORG || userType == USER_MEMBER) &&  page =="new_conf" && <CreateConfPage />}
           {(userType == USER_ORG || userType == USER_MEMBER) && page =="conf_editor" && <CreateConfPage />}
           {userType == USER_ORG &&  page =="team_org" && <TeamPage 
                conf_id = {user.selectedConf?user.selectedConf.id:-1}
           />}
           {(userType == USER_ORG || userType == USER_MEMBER)  && page =="client_view" && <ClientConfPage/>}
           {(userType == USER_ORG || userType == USER_MEMBER) && page =="speakers" && <SpeakersPage/>}
           {(userType == USER_ORG || userType == USER_MEMBER) && page =="sponsors" && <SponsorPage/>}
           {(userType == USER_ORG || userType == USER_MEMBER) && page =="blds" && <BuildingsPage/>}
           {(userType == USER_ORG || userType == USER_MEMBER) && page =="partips" && <PartPage
           conf_id = {(user.selectedConf && user.selectedConf.id)?user.selectedConf.id:-1}
           conf_name = {(user.selectedConf && user.selectedConf.id)?user.selectedConf.name:''}
           />}
           {(userType == USER_ORG || userType == USER_MEMBER) && page =="nav" && <ConfNavPage/>}
		   {(userType == USER_ORG || userType == USER_MEMBER) &&  page =="conf_prog" && <TimeTable
                conf_data =  {user.selectedConf}          
                building_id = {user.selectedConf?user.selectedConf.building_id:-1}
                conf_id = {user.selectedConf?user.selectedConf.id:-1}/>}
		  </div>
		</div>
    <Snack open={alertShow} variant={err_type} text={snackText} onClose={this.onCloseSnack}/>
       {user.isLoading && <div className="shadow"><div className="shadow_circle"><CircularProgress/></div></div>}
        <CookieConsent
              location="bottom"
              buttonText="Согласен"
              cookieName="myAwesomeCookieName2"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
        >
                {cookieText}
            </CookieConsent>
        </div>
        );
    }
}


function mapStateToProps(state) {
    const {app, user, authentication} = state;
    //const {user} = authentication;
    const {unresolvedAppealCount} = app;
    return {
        user,
        app,
        authentication
    };
}

const connectedMainPage = connect(mapStateToProps)(MainPage);
export {connectedMainPage as MainPage};

import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const roleAlias = {'admin':'Администрирование',
                    'speaker_editor':'Редактирование спикеров',
                    'sponsor_editor':'Редактирование спонсоров',
                    'building_editor':'Редактирование площадок',
                    'student_viewer':'Просмотр посетителей',
                    'conference_editor':'Редактирование конференций',
                    'moderator':'Модерирование'};

export default function RulesDialog(props) {
    
  const [perm, setPerm]  = React.useState([]); 
  const [count,setCount ] = React.useState(0);

	let { handleClose, rolePerm, fullPerm, roleName,dialogOpen } = props;
    
     React.useEffect(() => {
         
         if (count == 0)
		  {
              console.log(roleName);
              console.log(rolePerm);
			setPerm(rolePerm);
		  }
    
    });
    
    function selectRoles(e)
    {
        setCount(count+1);
        //setRoles(e);
    }
    
    
	function saveData(e){
        
        e.preventDefault();
        
        console.log(perm);
        handleClose(perm);
		setCount(0);
	}
    
    
	function cancelData() {
		handleClose('');
		setCount(0);
	}
    
    function checkRule(ind)
    {
        let val = perm[ind].val;
        let tmpP = [...perm];
        tmpP[ind].val = !val;
        
        setCount(count+1);
        setPerm(tmpP);
    }
    
 
  return (

      <Dialog open={dialogOpen}>
	<DialogTitle>Права доступа для роли {roleName}</DialogTitle>
    <form onSubmit={saveData}>
        <DialogContent>
          <FormGroup>
                              
          
        {perm.length>0 && perm.map(function(item,index){
            
            return (
            <FormControlLabel control={<Checkbox checked={item.val} onClick={()=>checkRule(index)} />} label={roleAlias[item.name]} />
            )
        })
        } 
        </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button type="submit">Сохранить</Button>
          <Button onClick={cancelData}>Отмена</Button>
        </DialogActions>
        </form>
      </Dialog>

  );
}
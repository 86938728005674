import React from 'react';
import PropTypes from 'prop-types';
import { history } from '../_helpers';
import { SendButton, TextFieldCell, Snack} from "../_components";
import '../css/style.css';

import { storeData, setStore } from '../store.js';
import { connect } from "react-redux";
import {store} from '../_helpers'; 
import { userService } from '../_services';

import BldDialog from '../MainPage/BldDialog';


import BuildingsItem from './BuildingsItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import { userActions, appActions } from "../_actions";
import i18next from 'i18next';
import { CircularProgress } from '@material-ui/core';

class BuildingsPage extends React.Component {


	constructor(props)
	{
		super(props);
        
		 this.state = { isLoading:false,blds:[],bldId:0, dName:'',dAddress:'',dMail:'',bldDialog:false}
        store.subscribe(this.storeChange)
	}

    componentDidMount() {
        
        this.getBuildings();
        
    }
    
    getBuildings = (id=0) => {
        
        var self = this;
        
		userService.getWrapper('api/building',function(data) 
		   {
               console.log(data);
               if (data.data)
               {
                   if (id>0)
                   {
                       let addr = '';
                       for (let i=0;i<data.data.length;i++)
                        if (data.data[i].id == id)
                       {
                           addr = data.data[i].address;
                           break
                       }
                       self.setState({blds:[...data.data],bldAddr:addr,selectedBldId:id});
                   }
                   else
                    self.setState({blds:[...data.data]});
               }
                   
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false});  }
		
		   });
    }
    
    
     storeChange = (e) => {
        
     }

    addBld = () => {
          this.setState({bldId:0,dAddress:'',dName:'', dEmail:'',bldDialog:true});
    }
    
    delBld = (id) => {
        
        let self = this;
        userService.delWrapper('api/building/'+id,function(data) 
               {
                   if (data.status == 200) self.getBuildings();
                       
              },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); }});
    }
    
    editBld = (id) => {
        
        const { blds } = this.state;
        let bldAddr = '';
        let bldName = '';
        let bldEmail = '';
        for (let i=0;i<blds.length;i++)
            if (blds[i].id == id)
            {
                bldAddr = blds[i].address;
                bldName  = blds[i].name;
                bldEmail = blds[i].email;
            }
        
        this.setState({bldId:id,dAddress:bldAddr,dName:bldName, dEmail:bldEmail,bldDialog:true});
    }
    
    closeBldDialog = (val) => {
        
        let self = this;
        const { bldId } = this.state;
        console.log(val);
        
        let post_data = {};
        
        if (val.address) post_data.address = val.address;
        if (val.name) post_data.name = val.name;
        if (val.email) post_data.email = val.email;
        
        if (post_data && post_data.name)
        {
            
            if (bldId>0)
            {
                userService.patchWrapperBody('api/building/'+bldId,post_data,function(data) 
               {
                   console.log(data);
                   if (data.status == 200) self.getBuildings();
                       
              },function(error) {   { self.setState({error:error,isLoading:false});  }
            
               })
            }
            else
            {
                userService.postWrapper('api/building',post_data,function(data) 
               {
                   console.log(data);
                   
                   if (data.status == 200) self.getBuildings();
                   
                       
              },function(error) {   { self.setState({error:error,isLoading:false});  }
            
               })
                
            }                
            
            
            this.setState({bldDialog:false});
        }
        else
        {
            this.setState({bldDialog:false});
        }
    }
    

    render() {
	
		let self = this;
        const { isLoading, blds, dName,dAddress,dEmail, bldDialog,bldId } = this.state;
     const { classes } = this.props;
    
            
        return (
        <div class="layout">
				<div class="layout__contains">
					<h1 class="layout__title">Площадки</h1>

					<div class="layout__btn">
						<button class="btn" onClick={self.addBld}>Новая площадка</button>
					</div>
				</div>

				<div class="tbl">
					<div class="tbl__top">
						<div class="tbl__items">
							<div class="tbl__item tbl__item--name">
								<input type="text" placeholder="Поиск по названию"/>
							</div>
							<div class="tbl__item tbl__item--text">
								<input type="text" placeholder="Поиск по email"/>
							</div>


                        <div class="tbl__item tbl__item--city">
								<input type="text" placeholder="Поиск по адресу"/>
							</div>

						</div>
					</div>

					<div class="tbl__body">
                    
                    
                    {blds && blds.map(function(item,index){
                        
                        return(
                            <BuildingsItem
                                data={item} 
                                isUser={true}
                                editBld={(e)=>self.editBld(e)}
                                delBld= {(e)=>self.delBld(e)}
                            />)
                    })
                    }
                    
                    </div>
                </div>  
                
                <BldDialog handleClose={self.closeBldDialog}  
                        id = {bldId}
                        dAddress = {dAddress}
                        dName = {dName}
                        dEmail = {dEmail}
                        dialogOpen={bldDialog}/>

                                    
               {isLoading && <div className="shadow"><div className="shadow_circle"><CircularProgress/></div></div>} 
			</div>
            
        );
    }
}


function mapStateToProps(state) {
    const {app, user, authentication} = state;
    
    return {
        user,
        app,
        authentication
    };
}

const connectedTeamPage = connect(mapStateToProps)(BuildingsPage);
export {connectedTeamPage as BuildingsPage};

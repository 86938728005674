import * as React from 'react';


import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import '../css/style.css';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const no_text = 'уточняется';

export default function SlotInfo(props) {
  
  const [count,setCount ] = React.useState(0);

	let { handleClose, data, open } = props;
    
    React.useEffect(() => {
         
    
    });
    
    console.log(data);
   
  return (

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
            Тема: {data.name?data.name:'Не определена'}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            Описание: {data.description?data.description:no_text}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                Спикеры: {data.sp_str?data.sp_str:no_text}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                Зал: {data.room_number?data.room_number:no_text}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                Время начала лекции: {data.time}
            </Typography>
            { data.url_online &&
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{color:'blue', textDecoration: 'underline'}}>
                <a href={data.url_online}  target="_blank">Ссылка на трансляцию</a>
            </Typography>
            }
          </Box>
        </Fade>
      </Modal>

  );
}
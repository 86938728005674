import React from 'react';
import { connect } from "react-redux";
import { List, ListItem, ListItemIcon, ListItemText, Divider, Badge } from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles } from '@material-ui/core/styles';
import {appActions, userActions} from "../_actions";
import classNames from 'classnames';
import '../css/style.css';
import '../css/fonts.css';
import circleLogo from '../img/circle.png';
import allconfLogo from '../img/allconf.png';
import scheduleLogo from '../img/schedule.png';
import clientLogo from '../img/client.png';
import navLogo from '../img/nav.png';
import speakerLogo from '../img/speakers.png';
import sponsLogo from '../img/spons.png';
import teamLogo from '../img/team.png';
import partipsLogo from '../img/partips.png';
import ncLogo from '../img/logo.png';
import baseLogo from '../img/bases.png';
import placeLogo from '../img/place.png';

import i18next from 'i18next';

const menu_org_data = [

{page:'confs_title',title:'Конференции',img:allconfLogo,
            child:[{page:'confs',title:'Все конференции',img:''},
                   {page:'new_conf',title:'Новая конференция',img:''},
                   {page:'conf_editor',title:'Редактор конференции',img:''},
                    ]},
{page:'team_org',title:'Команда',img:teamLogo,child:[]},             
{page:'conf_prog',title:'Программа',img:scheduleLogo,child:[]},             
{page:'bases',title:'Базы',img:baseLogo,child:[
    {page:'speakers',title:'Спикеры',img:speakerLogo},             
    {page:'sponsors',title:'Спонсоры',img:sponsLogo},             
    {page:'blds',title:'Площадки',img:placeLogo},             
 ]},             

{page:'nav',title:'Настройки нав.',img:navLogo,child:[]},             
{page:'client_view',title:'Вид для клиента',img:clientLogo,child:[]},                    
{page:'partips',title:'Участники конференции',img:partipsLogo,child:[]},                    
                   ];
                   
const menu_data_hide = [

{page:'confs_title',title:'Конференции',img:allconfLogo},
{page:'team_org',title:'Команда',img:teamLogo,child:[]},             
{page:'conf_prog',title:'Программа',img:scheduleLogo,child:[]},             
{page:'speakers',title:'Спикеры',img:speakerLogo},             
{page:'sponsors',title:'Спонсоры',img:sponsLogo},             
{page:'blds',title:'Площадки',img:placeLogo},             
{page:'nav',title:'Настройки нав.',img:navLogo,child:[]},             
{page:'client_view',title:'Вид для клиента',img:clientLogo,child:[]},                    
{page:'partips',title:'Участники конференции',img:partipsLogo,child:[]},                    
                   ];

/*
const menu_student_data = [

{page:'profile',title:'Личный кабинет',img:'',child:[]},
{page:'confs_title',title:'Конференции',img:allconfLogo,
            child:[{page:'confs',title:'Все конференции',img:''},
                   {page:'client_view',title:'Просмотр конференции',img:''},
                    ]},
                   ];
*/

const menu_student_data = [

{page:'confs_title',title:'Конференции',img:allconfLogo,
            child:[]},
{page:'tickets',title:'Билеты',img:scheduleLogo,
            child:[]},
            ];

class AppMenu extends React.Component {
    
     constructor(props) {
        super(props);
        this.state = {page:this.props.app.page, menu_data:[],userType:''}
        
     }

            
            
    
    goTo = (path) => {
        
		
        this.props.dispatch(appActions.moveTo(path));
    };
    
    getActive = (text) => {
        
        let { menu_data } = this.state;
        
        for (let i=0;i<menu_data.length;i++)
        {
            
            if (menu_data[i].page == text)
            {
                return text;
            }
                
            if (menu_data[i].child.length>0)    
                for (let j=0;j<menu_data[i].child.length;j++)
                    
                    if (menu_data[i].child[j].page==text)
                    {   
                        return menu_data[i].page
                    }
                
            
        }
        
        return "other"
    }
    
    setPage = (page) => {
            
        let id = 0;
        if (this.props.conf && this.props.conf.id && this.props.conf.id>0)
            id = this.props.conf.id;
            
        const { classes, dispatch } = this.props;
        if (page == "confs_title")
        {
            dispatch(appActions.setpage("confs"));
        }
        else if (page == "bases")
        {
            dispatch(appActions.setpage("speakers"));
        }
        else if (page == "new_conf")
        {
            dispatch(userActions.set_conf(null));    
            dispatch(appActions.setpage(page));
        }
        else if (page == "conf_editor")
        {
            dispatch(userActions.set_conf_selected());    
            dispatch(appActions.setpage(page,id));
        }
        else
        {
            
            if (id>0 && (page == "conf_prog" || page == "nav" || page=="client_view"))
                dispatch(appActions.setpage(page,id));
            else
                dispatch(appActions.setpage(page));
        }
        
    }
    
    showChild = (data) => {
        
        var self = this;
        const { classes, dispatch } = this.props;
        const { page, lang } = this.props.app;
        
        
        
        return (
        <ul class="header__sublist">
							
        {data.length>0 && data.map(function(item,index){ 
        
                            let bgColor =  '';
                            
                            if (page == item.page) bgColor = '#22e';
        
                            return (
                           <li class={item.img?"header__iconitem":"header__subitem"} onClick={()=>self.setPage(item.page)} key={index} style={{backgroundColor:bgColor,borderRadius:10}}>
                                    {item.img && 
                                     <a class="link-icon" style={{paddingBottom:5}}>
                                        <img src={item.img} style={{marginRight:10}}/>{i18next.t(item.page)}
                                    </a>}
									{!item.img && <a class="header__sublink">{i18next.t(item.page)}</a>}
								</li>
                            )
                        })}
        </ul>
        )
        
    }
    
     static getDerivedStateFromProps(props, current_state) {
        
        
        console.log(props,props.userType);
        console.log(current_state);
        
               
            if (props.userType != '' && current_state.userType != props.userType) {
                
                if (props.userType == "org")
                    return { menu_data:[...menu_org_data],userType:"org"}
                if (props.userType == "team_member")
                    return { menu_data:[...menu_org_data],userType:"team_member"}
                if (props.userType == "student")
                  return { menu_data:[...menu_student_data],userType:"student"}
              
                return null;
            }
        return null
    }

    render() {
        
        const { page, lang } = this.props.app;
        console.log(this.state);
        var self = this;
        
        const { menu_data } = this.state;
        
        // <div class="header__nav" >
            //<div class="header__nav-row">
        //
        //<div class="sidebar" >
          //  <div class="sidebar-top">
        
        const { classes, dispatch, open, onOpen } = this.props;
        
        
        
        return (
        
        <div>
        	<aside class="sidebar" style={{width:open?300:60}}>
        <div class="sidebar-top" style={{justifyContent: open?'start':'center'}}>
        <div class="toggle" onClick={onOpen}>
            <span></span><span></span><span></span>
        </div>
        {open && <img src={ncLogo}/>}
        
    </div>
    
    { !self.props.open && 
    <div>
    <ul class="nav">
      {this.props.userType != "student" && menu_data_hide.map(function(item,index){ 
                            
                                return(
                                 <li title={i18next.t(item.page)} onClick={()=>self.setPage(item.page)} style={{marginLeft:-10}}>
                                <img src={item.img} style={{marginTop:25}}/>
                            </li>
                            )
                            
                        })}    
                        
            {this.props.userType == "student" && menu_data.map(function(item,index){ 
                            
                                return(
                                 <li title={i18next.t(item.page)} onClick={()=>self.setPage(item.page)} style={{marginLeft:-10}}>
                                <img src={item.img} style={{marginTop:25}}/>
                            </li>
                            )
                            
                        })}    
    </ul>
    </div>}
    
    <nav id="sidebar-nav" data-simplebar="init" style={{overflow: "hidden",minHeight:850}}><div style={{margin: 0, overflow: "hidden"}}>
    <div class="simplebar-mask" style={{overflow:'hidden'}}>
    <div class="simplebar-offset" style={{right: "0px", bottom: "0px"}}><div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content" 
    style={{height: "100%"}}><div class="simplebar-content" style={{padding: "0px"}}>
        <ul class="nav">
                {self.props.open && menu_data && menu_data.length>0 && menu_data.map(function(item,index){ 
                
                             let bgColor =  '';
                            if (page == item.page) bgColor = '#22e';
                            
                            if (item.child.length>0)
                            return (
                             <li class="list-submenu" key={index} >
                            <a class={"link-icon link-toggle-submenu open"} style ={{paddingBottom:10}}
                             onClick={()=>self.setPage(item.page)}><img src={item.img} style={{marginRight:10}}/>{i18next.t(item.page)}</a>
							{self.showChild(item.child)}
                            </li>
                            )
                            else 
                                return(
                                 <li style={{backgroundColor:bgColor,borderRadius:10}}>
                                <a class="link-icon" onClick={()=>self.setPage(item.page)}>
                            <img src={item.img} style={{marginRight:10}}/>{i18next.t(item.page)}
                                </a>
                            </li>
                            )
                            
                        })}
                        
                  
            
        </ul>
    </div></div>
    </div></div></div>
    
    </nav>
</aside>
        
        
            </div>
        )
    }
}


function mapStateToProps(state) {
    const {app, users, authentication} = state;
    const {user} = authentication;
    const {unresolvedAppealCount} = app;
    return {
        user,
        users,
        app,
    };
}

const connectedAppMenu = connect(mapStateToProps)(AppMenu);
export {connectedAppMenu as AppMenu};




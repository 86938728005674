import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import '../css/style.css';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import {serverUrl} from '../_constants'; 
 

export default function TeamItem(props) {

  return (
   <div class="tbl__body-row">
							<div class="tbl__items">
                            <div class="tbl__item tbl__item--city">
									<span>{props.data?props.data.conf_name:''}</span>
								</div>
                                <div class="tbl__item tbl__item--text">
									<span>{props.data?props.data.dates:''}</span>
                                </div>
								<div class="tbl__item tbl__item--text">
									<span>{props.data?props.data.name:''}</span>
								</div>
                                <div class="tbl__item tbl__item--text">
									<span>{props.data?props.data.cost:''}</span>
								</div>
                                                                


                              
							</div>
						</div>
  );
}
import React from 'react';
import PropTypes from 'prop-types';
import { history } from '../_helpers';
import { SendButton, TextFieldCell, Snack} from "../_components";
import '../css/style.css';

import { storeData, setStore } from '../store.js';
import { connect } from "react-redux";
import {store} from '../_helpers'; 
import { userService } from '../_services';

import MemberDialog from './MemberDialog';
import RoleDialog from './RoleDialog';
import RoleListDialog from './RoleListDialog';
import RulesDialog from './RulesDialog';
import TeamItem from './TeamItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import { userActions, appActions } from "../_actions";
import i18next from 'i18next';
import { CircularProgress } from '@material-ui/core';

class TeamPage extends React.Component {


	constructor(props)
	{
		super(props);
        
		 this.state = { isLoading:false,conf_list:[], team:[],teamroles:[],openDialog:false, dName:'',dPhone:'',dMail:'',
                        roles:[], roleDialog:false,roleId:0,confId:0,id:0, rlistDialog:false, rolePerm:[], fullPerm:[]}
        store.subscribe(this.storeChange)
	}

    componentDidMount() {
        
        //this.props.dispatch(userActions.teamorg());
        console.log(this.props);
        
        let conf_list = [];
        
        if (this.props.user && this.props.user.conflist)
        {
            for (let i=0;i<this.props.user.conflist.length;i++)
                conf_list.push({id:this.props.user.conflist[i].id,name:this.props.user.conflist[i].name});
            if (conf_list.length>0)
                this.setState({conf_list:conf_list});
        }
        
        var self = this;
        this.getRole();
	   
    }
    
    closeDialog = (val) => {
        console.log(val);

        const { id } = this.state;
        
        if (val)
        {
            this.setState({openDialog:false,isLoading:true});
              var self = this;
              
                let postData = {name:val.name}
                
                if (id == 0 && val.email) postData.email = val.email;
                if (val.phone) postData.phone = val.phone;
                if (val.city) postData.city = val.city;
                
                if (id == 0)
                {                    
                    userService.postWrapper('api/team',postData,function(data) 
                   {
                       self.getTeam();
                    },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
                
                   });
                }
                else
                {
                    userService.patchWrapperBody('api/team/'+id,postData,function(data) 
                   {
                       self.getTeam();
                    },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
                
                   });
                }
        }
        else
            this.setState({openDialog:false,isLoading:false});
        
    }
    
    saveRole = (data) => {
        
        console.log(data);
        console.log(this.state);
        let self = this;
        
        const { id, confId, roleId } = this.state;
        
        if (data)
        {
            
            this.setState({isLoading:true});
            
            let role_Id = data.roleId;
            let conf_Id = data.confId;
            let reqStr = 'api/team/'+id+'?attach_conference_id='+conf_Id+'&attach_role_id='+role_Id;
            
            if (this.state.roleId>0 && this.state.confId>0)
                reqStr=reqStr+'&detach_conference_id='+confId+'&detach_role_id='+roleId;
                
            //let patchData = {attach_conference_id:confId,attach_role_id:roleId};
            
            userService.patchWrapper(reqStr,function(data) 
		   {
               self.getTeam();
                    
			},function(error) {  if (error!='')   { self.setState({error:error}); alert(error); }
		
		   });
            
        }
        
        this.setState({roleDialog:false,id:0,roleId:0,confId:0});
    }
    
    getRole = () => {
        
        var self = this;
        this.setState({isLoading:true});
		
		userService.getWrapper('api/role',function(data) 
		   {
               console.log(data);
               
               if (data.data)
                   self.setState({roles:[...data.data]});
               self.getTeam()
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false});  }
		
		   })
        
    }
    
    getRoleInfo = (id) => {
        
        var self = this;
        
		userService.getWrapper('api/role/'+id,function(data) 
		   {
               console.log(data);
               
               let perms = [...data.data.permissions.map(e=>e.key)];
               
               console.log(perms);
               
               let fP = [...data.data.full_permissions_list];
               let permList = [];
               
               for (let i=0;i<fP.length;i++)
               {
                    
                    let rName = fP[i];
                   let tmpObj = {name:rName,val:false};
                   
                   if (perms.indexOf(rName)>=0)
                       tmpObj["val"] = true;
                   
                   permList.push(tmpObj);
               }   
                   
               
           self.setState({rolePerm:permList,fullPerm:fP,roleId:data.data.id,roleName:data.data.name});
               
               
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false});  }
		
		   })
        
    }
    
    getTeam = () => {
        
        var self = this;
		
		userService.getWrapper('api/team',function(data) 
		   {
               console.log(data);
               if (data.data)
               {
                   let tmp_roles = [];
                   for (let i=0;i<data.data.length;i++)
                       for (let j=0;j<data.data[i].roles.length;j++)
                       {
                           let conf_name = '';
                           let role_name = '';
                           let role_id = data.data[i].roles[j].pivot.role_id;
                           let conf_id = data.data[i].roles[j].pivot.conference_id;
                           
                           console.log(self.state.roles);
                           
                           for (let k=0;k<self.state.roles.length;k++)
                               if (self.state.roles[k].id == role_id)
                                   role_name = self.state.roles[k].name;
                               
                            for (let k=0;k<self.state.conf_list.length;k++)
                               if (self.state.conf_list[k].id == conf_id)
                                   conf_name = self.state.conf_list[k].name;
                           
                           
                            tmp_roles.push({
                                id:data.data[i].id,
                                name:data.data[i].name,
                                email:data.data[i].email,
                                conference_id:conf_id,
                                role_id:role_id,
                                role_name:role_name,
                                conf_name:conf_name,
                                });
                       }
                       
                       console.log(tmp_roles);
                   self.setState({team:[...data.data],roles_list:tmp_roles,isLoading:false});
                   console.log(self.state);
               }
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false});  }
		
		   })
    }
    
    
    
    
    editRole = (id,val) => {
        
        let self = this;
        
        userService.patchWrapperBody('api/role/'+id,{name:val},function(data) 
		   {
               console.log(data);
               
               self.getRole()
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false});  }
		
		   })
        
    }
    
    addRole = (val) => {
        let self = this;
        
        userService.postWrapper('api/role',{name:val},function(data) 
		   {
               console.log(data);
               
               self.getRole()
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false});  }
		
		   })
    }
    
    saveRule = (data) => {
        
        
        
        if (data == '')
             this.setState({rulesDialog:false, rolePerm:[],roleName:'',fullPerm:[]});
        else
        {
            console.log(data);    
            
            let rulesData = [];
            let tmpObj = {};
            
            for (let i=0;i<data.length;i++)
            {
                if (data[i].val == true)
                        tmpObj[data[i].name] = 1;
                else
                    tmpObj[data[i].name] = 0;
            }
            
            let self = this;
                    
            this.setState({rulesDialog:false, rolePerm:[],roleName:'',fullPerm:[]});
            
            userService.postWrapper('api/permissions/'+this.state.roleId,{permissions:{...tmpObj}},function(data) 
		   {
               console.log(data);
               
            },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false});  }
		
		   })
            
            
        }
    }
    
   
    rulesOpen = (data) => {
        
        console.log(data);
        if (data.id)
            this.getRoleInfo(data.id);
        this.setState({rulesDialog:true});
    }
    
    delRole = (id) => {
        let self = this;
        
         userService.delWrapper('api/role/'+id,function(data) 
		   {
               console.log(data);
               
               self.getRole()
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false});  }
		
		   })
    }
    
    addTeamMember = () => {
        
        //()=>self.props.dispatch(appActions.setpage('new_member'))}
        this.setState({openDialog:true,id:0,dName:'',dPhone:'',dEmail:'',dCity:''});
        
    }
    
    
    
     storeChange = (e) => {
        
     }

    userEdit = (id) => {
        
        const { team } = this.state;
        
        let name = '';
        let phone = '';
        let city = '';
        
        for (let i=0;i<team.length;i++)
            if (team[i].id == id)
            {
                name = team[i].name;
                phone = team[i].phone;
                city  = team[i].city;
                break;
            }
         
         this.setState({id:id,openDialog:true,dName:name,dPhone:phone,dCity:city});
         
     }     
     
     userAction = (id) => {
         
         this.setState({id:id,roleDialog:true,confId:0,roleId:0,isUser:true});
         
     }
     
     roleAction = (val) => {
         console.log(val);
         this.setState({id:val.id,roleDialog:true,confId:val.conference_id,roleId:val.role_id,isUser:false});
     }
    
    delUserAction = (val) => {
        console.log(val);
        this.setState({isLoading:true});
        let self = this;
        
        let reqStr = 'api/team/'+val.id;
        
        userService.delWrapper(reqStr,function(data) 
		   {
               self.getTeam();
                    
			},function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
		
		   });
        
    }
    
    openRoles = () => {
        this.setState({rlistDialog:true});
    }
    
    delRoleAction = (val) => {
        console.log(val);
        this.setState({isLoading:true});
        let self = this;
        
        let reqStr = 'api/team/'+val.id+'?detach_conference_id='+val.conference_id+'&detach_role_id='+val.role_id;
        
        userService.patchWrapper(reqStr,function(data) 
		   {
               //let c_id = self.props.conf_id;
               self.getTeam();
                    
			},function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
		
		   });
    }

    render() {
	
		let self = this;
        const { isLoading, team, roles_list, rulesDialog, rolePerm,fullPerm, roleName } = this.state;    
     const { classes } = this.props;
    
            
        return (
        <div class="layout">
				<div class="layout__contains">
					<h1 class="layout__title">Моя команда</h1>

					<div class="layout__btn">
                        <button class="btn" style={{marginBottom: 10}}
                        onClick={self.openRoles}>Список ролей</button>
						<button class="btn" onClick={self.addTeamMember}>Новый член команды</button>
					</div>
				</div>

				<div class="tbl">
					<div class="tbl__top">
						<div class="tbl__items">
							<div class="tbl__item tbl__item--name">
								<input type="text" placeholder="Поиск по имени"/>
							</div>

							<div class="tbl__item tbl__item--level">
								<select>
									<option value="default" selected>Уровень</option>
									<option value="1">Сотрудник</option>
									<option value="2">Менеджер</option>
                                    <option value="3">Администратор</option>
								</select>
							</div>

							<div class="tbl__item tbl__item--text">
								<input type="text" placeholder="Поиск по конференции"/>
							</div>

							<div class="tbl__item tbl__item--text">
								<input type="text" placeholder="Поиск по email"/>
							</div>
                            <div class="tbl__item tbl__item--text">
								<input type="text" placeholder="Поиск по телефону"/>
							</div>
                            <div class="tbl__item tbl__item--city">
								<input type="text" placeholder="Поиск по городу"/>
							</div>
                            <div class="tbl__item tbl__item--text">
                            </div>

							
						</div>
					</div>

					<div class="tbl__body">
                     {roles_list && roles_list.map(function(item,index){
                        
                        return(
                            <TeamItem 
                                data={item} 
                                isUser={false}
                                userAction={(e)=>self.userAction(e)}
                                roleAction={(e)=>self.roleAction(e)}
                                delAction= {(e)=>self.delRoleAction(e)}
                                
                            />)
                    })
                    }
                    
                    {team && team.map(function(item,index){
                        
                        return(
                            <TeamItem 
                                data={item} 
                                isUser={true}
                                userEdit={(e)=>self.userEdit(e)}
                                userAction={(e)=>self.userAction(e)}
                                roleAction={(e)=>self.roleAction(e)}
                                delAction= {(e)=>self.delUserAction(e)}
                            />)
                    })
                    }
                    
                    </div>
                </div>  
            <MemberDialog handleClose={self.closeDialog}  
                 label="Член команды" 
                 title="Член команды"
                 id = {this.state.id}
                 dName = {this.state.dName}
                 dPhone = {this.state.dPhone}
                 dCity = {this.state.dCity}
				dialogOpen={this.state.openDialog}/>
            <RoleDialog 
                    handleClose={self.saveRole}  
                    roles_list ={self.state.roles}
                    conf_list = {self.state.conf_list}
                    old_confId= {self.state.confId}
                    old_roleId= {self.state.roleId}
                    dialogOpen={this.state.roleDialog}/>   

             <RoleListDialog 
                    handleClose={()=>self.setState({rlistDialog:false})}  
                    roles_list ={self.state.roles}
                    addRole = {self.addRole}
                    editRole = {self.editRole}
                    delRole = {self.delRole}
                    openRules ={self.rulesOpen}
                    dialogOpen={this.state.rlistDialog}/>   
              <RulesDialog 
                    handleClose={self.saveRule}  
                    rolePerm = {rolePerm}
                    fullPerm = {fullPerm}
                    roleName = {roleName}
                    dialogOpen={rulesDialog}/>           
                    
                    
               {isLoading && <div className="shadow"><div className="shadow_circle"><CircularProgress/></div></div>} 
			</div>
            
        );
    }
}


function mapStateToProps(state) {
    const {app, user, authentication} = state;
    
    return {
        user,
        app,
        authentication
    };
}

const connectedTeamPage = connect(mapStateToProps)(TeamPage);
export {connectedTeamPage as TeamPage};


import {combineReducers} from 'redux';

import {authentication} from './authentication.reducer';
import {user} from './user.reducer';
import {app} from './app.reducer';
import {schedule} from './schedule.reducer';

const rootReducer = combineReducers({
    app,
    authentication,
    user,
    schedule,
});

export default rootReducer;

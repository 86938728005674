import React from 'react';
import PropTypes from 'prop-types';
import { history } from '../_helpers';
import { SendButton, TextFieldCell, Snack} from "../_components";
import '../css/style.css';

import { storeData, setStore } from '../store.js';
import { connect } from "react-redux";
import {store} from '../_helpers'; 
import { userService, confService } from '../_services';

import PartItem from './PartItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import { userActions, appActions } from "../_actions";
import i18next from 'i18next';
import { CircularProgress } from '@material-ui/core';

class TicketsPage extends React.Component {

	constructor(props)
	{
		super(props);
        
		 this.state = { isLoading:false, team:[]};
        
	}

    componentDidMount() {
        
        
      this.props.dispatch(userActions.conf_student_list());
        
    }
    
    ShowTickets(conf) {
        
        let confName = conf.name;
        let conf_dates = conf.start_time.substr(0,10).split('-').reverse().join('-')+" - "+ conf.end_time.substr(0,10).split('-').reverse().join('-');
        let tickets = [];
        let tickets_names = [];
        
        let conftickets = [];
        
        for (var key in conf.tickets) {
                conftickets.push({...conf.tickets[key]})
        }
        
        console.log(conftickets);
        
        for (let i=0;i<conftickets.length;i++)
        {
            
            if (tickets_names.indexOf(conftickets[i].name)<0)
            {
                tickets_names.push(conftickets[i].name);
                tickets.push({...conftickets[i],conf_name:confName,dates:conf_dates});
            }
        }
        
        console.log(tickets);
        
        return(
        <div>
        {tickets.map((item,index)=> <PartItem data={item} />) }
        </div>
        )
    }
    

    render() {
	
		let self = this;
        const { isLoading, team } = this.state;
     const { classes,user } = this.props;
     
     console.log(this.props);
    //{this.props.conf_name}
        
        return (
         <div class="layout">
        <div class="layout__contains">
        <h1 class="layout__title">Билеты</h1>
        
				</div>

				<div class="tbl">
					<div class="tbl__top">
						<div class="tbl__items">
                            <div class="tbl__item tbl__item--city">
								<input type="text" placeholder="Поиск по конференции"/>
							</div>
                            <div class="tbl__item tbl__item--text">
								<input type="text" placeholder="Поиск по дате"/>
							</div>
		                    <div class="tbl__item tbl__item--text">
								<input type="text" placeholder="Поиск по билету"/>
							</div>
                            <div class="tbl__item tbl__item--text">
								<input type="text" placeholder="Поиск по цене"/>
							</div>
                            
        


						</div>
					</div>

					<div class="tbl__body">
                    
                    {user.conflist_student && user.conflist_student.filter(e=>e.hasTicket).map(function(item,index){
                        return(
                        
                        self.ShowTickets(item)
                        )
                    })
                    }
                    
        </div>
    </div>
           
               {isLoading && <div className="shadow"><div className="shadow_circle"><CircularProgress/></div></div>} 
        </div>
            
        );
        
    }
}


function mapStateToProps(state) {
    const {app, user, authentication} = state;
    
    return {
        user,
        app,
        authentication
    };
}

const connectedTicketsPage = connect(mapStateToProps)(TicketsPage);
export {connectedTicketsPage as TicketsPage};

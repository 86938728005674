import React from 'react';
import PropTypes from 'prop-types';
import { history } from '../_helpers';
import { SendButton, TextFieldCell, Snack} from "../_components";
import '../css/style.css';
import { storeData, setStore } from '../store.js';
import { connect } from "react-redux";
import {store} from '../_helpers'; 
import { userService, confService } from '../_services';    
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import { userActions, appActions } from "../_actions";
import i18next from 'i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import {serverUrl} from '../_constants'; 
import playLogo from '../img/play.png'; 
import inetLogo from '../img/inet.png'; 


const getDate = (time) => {

   if (!time) return "не определено";
   
   let time1 = time.substr(0,10);
   
   return time1.split('-').reverse().join('-');
   
   return time1;
   
}  

  
class ConfNavPage extends React.Component {


	constructor(props)
	{
		super(props);
        
		 this.state = { isLoading:false, addr:''}
        store.subscribe(this.storeChange)
	}

    componentDidMount() {
        
        console.log(this.props.user);
        
        let { selectedConf} = this.props.user;
        
        if (selectedConf && selectedConf.id && selectedConf.id>0)
        {
            
            
            this.setState({name:selectedConf.name,
                          format:selectedConf.type,
                          comment:selectedConf.description,
                          streamUrl:selectedConf.navigation_description,
                          id:selectedConf.id,
                          image:selectedConf.image,
                          building_id:selectedConf.building_id,
                          start_time:selectedConf.start_time,
                          end_time:selectedConf.end_time,
                          selectedBldId:selectedConf.building_id,
                          wifi:selectedConf.wifi,
                          map_description: selectedConf.map_description,
                          map_image: selectedConf.map_image,
                          navigation_building_description: selectedConf.navigation_building_description,
                          navigation_building_image: selectedConf.navigation_building_image,
                          navigation_description: selectedConf.navigation_description,
                          navigation_image: selectedConf.navigation_image});
               
            this.getBuildings();        
            this.getConfInfo(selectedConf.id);
        }
        
    }
    
    getConfInfo = (id) => {
        
        let self = this;
        
        	confService.confInfo(id,function(data) 
		   {
               if (data.status == 200)
               {
                   
                   self.setState({
                        map_description: data.data.map_description,
                          map_image: data.data.map_image,
                          navigation_building_description: data.data.navigation_building_description,
                          navigation_building_image: data.data.navigation_building_image,
                          navigation_description: data.data.navigation_description,
                          navigation_image: data.data.navigation_image
                       });
               }
                   
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
		
		   });
    }
    
    getBuildings = () => {
        
        var self = this;
        
		userService.getWrapper('api/building',function(data) 
		   {
               let addr = '';
               let blds = [];
               console.log(data);
               if (data.data)
               {
                   blds = [...data.data];
                   
                   for (let i=0;i<blds.length;i++)
                   if (blds[i].id == self.state.building_id)
                   {
                       addr = blds[i].address;
                       break;
                       
                   }
                   
                   self.setState({blds:blds,addr:addr});
               }
                   
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
		
		   });
    }
    
     storeChange = (e) => {
        
     }  
     
     delImage = (tp) => {
    
        this.uploadFile(null,tp);
     }
     
     onSave = (tp) => {
         
         let self = this;
         const { id, navigation_description, navigation_building_description, map_description } = this.state;
         
         //api/conference/1?
            let post_data = {}
     
        if (tp==0)
            post_data['navigation_description'] = navigation_description;
        
        if (tp==1)
            post_data['map_description'] = map_description;
        
        if (tp==2)
            post_data['navigation_building_description'] = navigation_building_description;
         
         userService.patchWrapperBody('api/conference/'+id,post_data,function(data) 
		   {
               console.log(data);
                   
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
		
		   });
     }
     
     onTextChange = (e,tp) => {
         
         if (tp==0)
             this.setState({navigation_description:e.target.value});
         
         if (tp==1)
             this.setState({map_description:e.target.value});
         
         if (tp==2)
             this.setState({navigation_building_description:e.target.value});
     }

    
    
    onDrop = (acceptedFiles, rejectedFiles, tp) => {
        
        	console.log(acceptedFiles);
			
			if (acceptedFiles.length>0)
			{
			
                this.uploadFile(acceptedFiles[0],tp);
            }
   
    }
    
    uploadFile = (file, tp) => {
        
        const data_upload = new FormData();
        
        if (tp==0)
            data_upload.append('navigation_image', file);
     
        if (tp==1)
		 data_upload.append('map_image', file);
     
        if (tp==2)
		 data_upload.append('navigation_building_image', file);
     
        let self = this;
      
         userService.uploadFile('api/conference/'+this.state.id+'/upload',data_upload,function(data) 
               {
                   if (tp == 0 && data && data.data) self.setState({navigation_image:data.data.navigation_image}); 
                   if (tp == 1 && data && data.data) self.setState({map_image:data.data.map_image}); 
                   if (tp == 2 && data && data.data) self.setState({navigation_building_image:data.data.navigation_building_image}); 
                       
              },function(error) { console.log(error);  });
        
    }
   

    render() {
        
        console.log(this.state);
	
		let self = this;
        const { id, isLoading, image,name,start_time,end_time, addr,
        navigation_building_description, navigation_description,map_description,
        map_image,navigation_building_image, navigation_image,wifi,
        
        } = this.state;
        
        const { classes, user } = this.props;
     
        
        return (
            <div class="layout">
           {!id && <label for="text1" class="form__label">Нет выбранной конференции</label>}
           {id>0 && 
           <div>
            <div class="tabs-top">
		
	
		<ul class="tabs-setting-conf-contents">
			<li>
				<div class="conf-detail">
		<div class="conf-img">
			<img src={image?serverUrl+image:""} alt=""/>
		</div>
		<div class="conf-info">
			<div class="title h1">
                    {name}
			</div>
			<div class="meta">
				<div class="date">
					<div class="day">
					<span>{getDate(start_time)} - {getDate(end_time)}</span>
					</div>
					
				</div>
				<div class="address">
					<span>
                    {addr}					
					</span>
				</div>
			</div>
		</div>
		<div class="conf-links">
        
                    <a class="btn-icon">
                        <img src={playLogo} style={{marginRight:10}}/>
                        <span>Настройки <br/> онлайн-трансляции</span>
                    </a>
                    <a class="btn-icon">
                        <img src={inetLogo} style={{marginRight:10}}/>
                        <span>Интернет на <br/> площадке</span>
                        
                    </a>
                    <span>{wifi}</span>
                </div>
            </div>
                    </li>
                </ul>
            </div>
            
            
            
         <div>
		<div class="header-block">
			<div class="title">Настройки навигации</div>
		</div>
		<div class="content-block">
			<div class="settings-block">
				<div class="item">
					<div class="title">Маршрут до площадки</div>
                    <div class="edit-btns">
							<DeleteIcon onClick={()=>this.delImage(0)}/>
						</div>


                    <Dropzone onDrop={(e1,e2)=>this.onDrop(e1,e2,0)} >
				{({getRootProps, getInputProps, isDragActive}) => {
				return (
					<div class="file-lable"
					  {...getRootProps()}
					  className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
					>
					  <input {...getInputProps()} />
                      <div class="file-label">
						
						<div class="file-label_info">
							
							<span>Выбрать файл</span>
						</div>
                        { navigation_image && <img src={navigation_image?serverUrl+navigation_image:''} 
                        alt=""/>}              
                        <span class="desc-file">
							Или перетащите изображение
						</span>
					</div>
                      
					</div>
				  )
				}}
                </Dropzone>    
					

					<div class="sub-title">
						Описание
					</div>

					<textarea name="" placeholder="Текст" 
                    onChange={(e)=>this.onTextChange(e,0)}
                    value={navigation_description}></textarea>
                    
                    <div class="layout__block-btn">
                    <button class="btn" type="submit" onClick={()=>this.onSave(0)}>Сохранить</button>
					</div>
                    
				</div>
				<div class="item">
					<div class="title">Карта площадки</div>
                    <div class="edit-btns">
							<DeleteIcon onClick={()=>this.delImage(1)}/>
						</div>
					<Dropzone onDrop={(e1,e2)=>this.onDrop(e1,e2,1)} >
				{({getRootProps, getInputProps, isDragActive}) => {
				return (
					<div class="file-lable"
					  {...getRootProps()}
					  className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
					>
					  <input {...getInputProps()} />
                      <div class="file-label">
						<div class="file-label_info">
							<span>Выбрать файл</span>
						</div>
						{ map_image && <img src={map_image?serverUrl+map_image:''} 
                        alt="" />}              
						<span class="desc-file">
							Или перетащите изображение
						</span>
					</div>
                      
					</div>
				  )
				}}
                </Dropzone>    

					<div class="sub-title">
						Описание
					</div>

					<textarea name="" placeholder="Текст" 
                    onChange={(e)=>this.onTextChange(e,1)}
                    value={map_description}></textarea>
                    
                    <div class="layout__block-btn">
                    <button class="btn" type="submit" onClick={()=>this.onSave(1)}>Сохранить</button>
					</div>
                    
				</div>
				<div class="item">
					<div class="title">Карта выставки</div>
                    <div class="edit-btns">
							<DeleteIcon onClick={()=>this.delImage(2)}/>
						</div>
					<Dropzone onDrop={(e1,e2)=>this.onDrop(e1,e2,2)} >
				{({getRootProps, getInputProps, isDragActive}) => {
				return (
					<div class="file-lable"
					  {...getRootProps()}
					  className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
					>
					  <input {...getInputProps()} />
                      <div class="file-label">
						<div class="file-label_info">
							<span>Выбрать файл</span>
						</div>
						{ navigation_building_image && <img src={navigation_building_image?serverUrl+navigation_building_image:''} alt=""/>}              
						<span class="desc-file">
							Или перетащите изображение
						</span>
					</div>
                      
					</div>
				  )
				}}
                </Dropzone>    
					<div class="sub-title">
						Описание
					</div>

					<textarea name="nav_b" placeholder="Текст" 
                    onChange={(e)=>this.onTextChange(e,2)}
                    value={navigation_building_description}></textarea>
                      <div class="layout__block-btn">
                    <button class="btn" type="submit" onClick={()=>this.onSave(2)}>Сохранить</button>
					</div>
				</div>
			</div>
		</div>
        </div>
        
           </div>}
            
		
			</div>
        );
    }
}


function mapStateToProps(state) {
    const {app, user, authentication} = state;
    
    return {
        user,
        app,
        authentication
    };
}

const connectedConfNavPage = connect(mapStateToProps)(ConfNavPage);
export {connectedConfNavPage as ConfNavPage};



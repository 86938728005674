import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import NativeSelect from '@mui/material/NativeSelect';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit'; 


export default function RoleListDialog(props) {
    
    	let { handleClose, editRole,addRole, delRole, dialogOpen, roles_list, openRules } = props;

    const sysRoles = ["admin","manager","analyst","place_manager"];

    const [ rulesList, setRulesList ] = React.useState([]);
  const [count,setCount ] = React.useState(0);
  const [ rolesNames, setRolesNames ] = React.useState([]);
  const [ rolesOld, setRolesOld ] = React.useState([]);
    
     React.useEffect(() => {
         
         console.log(count,roles_list);
         
         if (count == 0)
         {
             setRolesNames(roles_list);
             setRolesOld(roles_list);
             setCount(1);
         }
         else if (roles_list.length != rolesOld.length)
         {
            setRolesNames(roles_list);
            setRolesOld(roles_list);
              
         }
         
    });
    

    function editRules(ind) {
        
        console.log(ind);
        openRules(roles_list[ind]);
    }
    
    function editRoleStr(ind,e){
    
        let newRoles = [...rolesNames]
        newRoles[ind].name = e;
        setRolesNames(newRoles);
    }
	
	function saveRole(ind){
        
        let id = rolesNames[ind].id;
        let e = rolesNames[ind].name;
        
        if (id >0 && e)
        {
            setCount(0);
        	editRole(id,e);
			
        }
        else if (id == 0 && e)
        {
            setCount(0);
        	addRole(e);
			
        }
        
        
	}
    
    function delRoleStr(ind)
    {
        let id = rolesNames[ind].id;
        
        
        if (id>0)
        {
            setCount(0);
            delRole(id);
            
        }
        else
        {
            let newRoles = [...rolesNames];
            
            newRoles.splice(ind,1);
            setRolesNames(newRoles);
        }
        
    }
    
    
    function addString() {
        
        let newRoles = [...rolesNames];
        newRoles.push({id:0,name:''});
        setRolesNames(newRoles);
    }
    
	function cancelData() {
		handleClose('');
		setCount(0);
	}
 
  return (

      <Dialog open={dialogOpen}>
        <DialogTitle>{"Список ролей"}</DialogTitle>
        <DialogContent>
        <div class="form__group" style={{width:400}}>
        <div class="form__group-body">
        <div class="form__group-top">
        <button type="button" class="form__btn" onClick={addString}>
                                                            <svg width="29" height="29">
                                                            </svg>
                                                            <span>Добавить</span>
                                                        </button>
                                                    </div>
                                                    {rolesNames && rolesNames.map(function(item,index){
                                                        
                                                        let isSys = false;
                                                        
                                                        if (sysRoles.indexOf(item.name)>=0)
                                                                isSys=true;
                                                        
                                                        return(    
                                                        <div class="form__group-rows">
                                                            <div class="form__group-row">
                                                                <input type="text" 
                                                                onChange = {(e)=>editRoleStr(index,e.target.value)}
                                                                class="form__input" placeholder="Название" value={item.name}/>
                                                            </div>
                                                            {isSys ==false && <div style={{width:30}}><SaveIcon onClick={()=>saveRole(index)} width="15" height="15"/></div>}
                                                            {item.id>0 && <div style={{width:30}}><EditIcon onClick={()=>editRules(index)} width="15" height="15"/></div>}
                                                            {isSys ==false&& <div style={{width:30}}><DeleteIcon onClick={()=>delRoleStr(index)} width="15" height="15"/></div>}
                                                        </div>)
                                                    })}

												</div>
                                                
											</div>
				
        
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelData}>Закрыть</Button>
        </DialogActions>
      </Dialog>

  );
}
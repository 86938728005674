import React from 'react';
import PropTypes from 'prop-types';
import { history } from '../_helpers';
import { SendButton, TextFieldCell, Snack} from "../_components";
import '../css/style.css';

import { storeData, setStore } from '../store.js';
import { connect } from "react-redux";
import {store} from '../_helpers'; 
import { userService } from '../_services';

import MemberDialog from './MemberDialog';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import CheckItem from './CheckItem';
import SpeakerItem from './SpeakerItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { userActions, appActions } from "../_actions";
import i18next from 'i18next';
import { CircularProgress } from '@material-ui/core';


class SpeakersPage extends React.Component {


	constructor(props)
	{
		super(props);
        
		 this.state = { isLoading:false,conf_list:[], team:[],teamroles:[],openDialog:false, dName:'',dPhone:'',dMail:'',dCity:'',dDesc:'',dComm:'',
                        roles:[], roleDialog:false,roleId:0,confId:0,id:0, conf_id:0,phoneFilter:'',
                        nameFilter:'',cityFilter:'',descFilter:'',emailFilter:'',allChecked:false}
        store.subscribe(this.storeChange)
	}

    componentDidMount() {
        
        //this.props.dispatch(userActions.teamorg());
        console.log(this.props);
        
        let conf_list = [];
        
        /*
        if (this.props.user && this.props.user.conflist)
        {
            for (let i=0;i<this.props.user.conflist.length;i++)
                conf_list.push({id:this.props.user.conflist[i].id,name:this.props.user.conflist[i].name});
            if (conf_list.length>0)
                this.setState({conf_list:conf_list});
        }
        */
        
        var self = this;
        //this.getRole();
	    this.getTeam();
        this.getConfs();
    }
    
    getConfs = () => {
        
        let self =this;
                	userService.getWrapper('api/conference',function(data) 
		   {
               if (data.status == 200)
               {
                   self.setState({conf_list:data.data.filter(e=>e.contracts_for_speakers ==0)});
               }
           },function(error) {});   
    }
    
    editFilter = (name,val) => {
        
        console.log(name,val);
        if (name =="name")
            this.setState({nameFilter:val});
        else if (name =="desc")
            this.setState({descFilter:val});
        else if (name =="city")
            this.setState({cityFilter:val});
        else if (name =="email")
            this.setState({emailFilter:val});
        else if (name =="phone")
            this.setState({phoneFilter:val});
    }
    
    closeDialog = (val) => {
        console.log(val);

        const { id } = this.state;
        
        if (val)
        {
            this.setState({openDialog:false,isLoading:true});
              var self = this;
            
                let postData = {...val}
                if (id == 0)
                {                
                    Object.keys(postData).forEach(key => { if (postData[key] == null || postData[key] =='' ) delete postData[key] });
                   
            
                    userService.postWrapper('api/speaker',postData,function(data) 
                   {
                       console.log(data);
                       self.getTeam();
                            
                    },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
                
                   });
                }
                else
                {
                    userService.patchWrapperBody('api/speaker/'+id,postData,function(data) 
                   {
                       console.log(data);
                       self.getTeam();
                            
                    },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
                
                   });
                }
            
        }
        else
            this.setState({openDialog:false,isLoading:false});
        
    }
    
  
    
    getTeam = () => {
        
        var self = this;
		
		userService.getWrapper('api/speaker',function(data) 
		   {
               console.log(data);
               if (data.data)
               {
                   let newData = data.data.map((e)=> { return {...e,checked:false}});
                   self.setState({team:[...newData],isLoading:false});
                   
               }
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
		
		   })
    }
    
    addTeamMember = () => {
        
        //()=>self.props.dispatch(appActions.setpage('new_member'))}
        this.setState({openDialog:true,id:0,dName:'',dPhone:'',dEmail:'',dDesc:'',dCity:'',dComm:''});
        
    }
    
    
    
     storeChange = (e) => {
        
     }
     
     onSpeakerCheck = (id) => {
         
         const { team } = this.state;
         
         let newTeam = team.map((e)=> {
             
             if (e.id == id)
             {
                 let newCheck = !e.checked;
                 return {...e,checked:newCheck}
              }
              else
                  return {...e}
             
         });
         
         this.setState({team:newTeam});
     }

    userEdit = (id) => {
        
        const { team } = this.state;
        
        let name = '';
        let phone = '';
        let email = '';
        let comment = '';
        let desc = '';
        let city = '';
        
        for (let i=0;i<team.length;i++)
            if (team[i].id == id)
            {
                name = team[i].name;
                phone = team[i].phone;
                email = team[i].email;
                desc = team[i].description;
                comment = team[i].comment;
                city = team[i].city;
                break;
            }
         
         this.setState({id:id,openDialog:true,dName:name,dPhone:phone,dCity:city,dComm:comment,dDesc:desc,dMail:email});
         
     }     
     
    
    delUser = (val) => {
        console.log(val);
        this.setState({isLoading:true});
        let self = this;
        
        let reqStr = 'api/speaker/'+val.id;
        
        userService.delWrapper(reqStr,function(data) 
		   {
               self.getTeam();
                    
			},function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
		
		   });
        
    }
    
    assignSpeakers = () => {
        
        const { team, conf_id } = this.state;
        
        if (conf_id>0)
        {
            let fTeam = team.filter(e=> e.checked)
            //alert(fTeam.length);
            let spStr = '';
            for (let i=0;i<fTeam.length;i++)
                spStr+=fTeam[i].name+", ";
            
            if (spStr.length>0) alert(spStr);
            else
                alert('Выберите спикеров');
        }
        else
            alert('Выберите конференцию');
    }
  
    checkAll = () => {
        const { allChecked, team } = this.state;
        let newTeam = team.map((e)=> { return {...e,checked:!allChecked} });
        
        this.setState({allChecked:!allChecked,team:newTeam});
    }

    render() {
	
		let self = this;
        const { isLoading, team, roles_list,conf_list, conf_id, nameFilter, descFilter,cityFilter, phoneFilter, emailFilter, allChecked } = this.state;
     const { classes } = this.props;
     
     /*
     sort(function(a, b) {
                        if (b.name < a.name) {
                                return -1;
                        }
                        if (b.name > a.name) {
                            return 1;
                        }
                        
                        return 0;
                    }).
                    */
    
    console.log(team);
            
        return (
        <div class="layout">
				<div class="layout__contains">
					<h1 class="layout__title">Спикеры</h1>

					<div class="layout__btn">
						<button class="btn" onClick={self.addTeamMember}>Новый спикер</button>
					</div>
                </div>
                <div class="layout__contains" style={{paddingTop:0, marginBottom:10}}>
                <h3 class="layout__title">Выбрать конференцию</h3>
                <div>
                   <FormControl sx={{ m: 1, minWidth: 420 }}>
        <InputLabel id="demo-controlled-open-select-label">Конфереция</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          label="Конфереция"
          value = {conf_id}
          onChange={(e)=>self.setState({conf_id:e.target.value})}
        >
          <MenuItem value="">
            <em>Не выбрана</em>
          </MenuItem>
          {conf_list.length>0 && conf_list.map((e)=> (
          <MenuItem value={e.id}>{e.name}</MenuItem>
          ))}
          
        </Select>
      </FormControl>
                </div>
                <div class="layout__btn">
						<button class="btn" onClick={self.assignSpeakers}
                        >Назначить спикеров</button>
					</div>
                </div>
                

				<div class="tbl">
					<div class="tbl__top">

						<div class="tbl__items">
                           <div class="calendar__body--name">  
                              <FormGroup>
                              <FormControlLabel control={<Checkbox checked={allChecked} onClick={this.checkAll} />} label="Все" />
                              
                            </FormGroup>
                            </div>    
							<div class="tbl__item tbl__item--name">
								<input type="text" placeholder="Поиск по имени" value={nameFilter} onChange={(e)=>self.editFilter('name',e.target.value)}/>
							</div>

							<div class="tbl__item tbl__item--text">
								<input type="text" placeholder="Поиск по описанию" value={descFilter} onChange={(e)=>self.editFilter('desc',e.target.value)}/>
							</div>

							<div class="tbl__item tbl__item--text">
								<input type="text" placeholder="Поиск по email" value={emailFilter} onChange={(e)=>self.editFilter('email',e.target.value)}/>
							</div>
                            <div class="tbl__item tbl__item--text">
								<input type="text" placeholder="Поиск по телефону" value={phoneFilter} onChange={(e)=>self.editFilter('phone',e.target.value)}/>
							</div>
                            <div class="tbl__item tbl__item--city">
								<input type="text" placeholder="Поиск по городу" value={cityFilter} onChange={(e)=>self.editFilter('city',e.target.value)}/>
							</div>


							<div class="tbl__item tbl__item--text">
								<input type="text" placeholder="Поиск по комментариям"/>
							</div>
                            <div class="calendar__body--text">
                            </div>
						</div>
					</div>

					<div class="tbl__body" style={{}}>
                     
                    
                    {team && 
                    team.
                    filter(e=>e.name.toLowerCase().indexOf(nameFilter.toLowerCase())>=0).
                    filter(e=>descFilter=="" || (e.description && e.description.toLowerCase().indexOf(descFilter.toLowerCase())>=0)).
                    filter(e=>cityFilter=="" || (e.city && e.city.toLowerCase().indexOf(cityFilter.toLowerCase())>=0)).
                    filter(e=>phoneFilter=="" || (e.phone && e.phone.toLowerCase().indexOf(phoneFilter.toLowerCase())>=0)).
                    filter(e=>emailFilter=="" || (e.email && e.email.toLowerCase().indexOf(emailFilter.toLowerCase())>=0)).
                    map(function(item,index){
                        
                        return(
                            <SpeakerItem 
                                data={item} 
                                checked={item.checked}
                                isUser={true}
                                onCheck = {self.onSpeakerCheck}
                                userEdit={(e)=>self.userEdit(e)}
                                delAction= {(e)=>self.delUser(e)}
                            />)
                    })
                    }
                    
                    </div>
                </div>  
            <MemberDialog handleClose={self.closeDialog}  
                 label="Cпикер" 
                 title="Спикер"
                 id = {this.state.id}
                 dName = {this.state.dName}
                 dPhone = {this.state.dPhone}
                 dMail = {this.state.dMail}
                 dComm = {this.state.dComm}
                 dDesc = {this.state.dDesc}
                 dCity = {this.state.dCity}
				dialogOpen={this.state.openDialog}/>
                                        
               {isLoading && <div className="shadow"><div className="shadow_circle"><CircularProgress/></div></div>} 
			</div>
            
        );
    }
}


function mapStateToProps(state) {
    const {app, user, authentication} = state;
    
    return {
        user,
        app,
        authentication
    };
}

const connectedSpeakersPage = connect(mapStateToProps)(SpeakersPage);
export {connectedSpeakersPage as SpeakersPage};

import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import '../css/style.css';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import {serverUrl} from '../_constants'; 
 

export default function TeamItem(props) {

  return (
   <div class="tbl__body-row">
							<div class="tbl__items">
								<div class="tbl__item tbl__item--name">
									<span class="profile">
										<img src={props.data.logo?serverUrl+props.data.logo:''} alt="" class="profile__img"/>
										<span class="profile__name">{props.data?props.data.brandname:''}</span>
									</span>
								</div>

								<div class="tbl__item tbl__item--text">
									<span>{props.data?props.data.email:''}</span>
								</div>
                                
                                <div class="tbl__item tbl__item--city">
									<span>{props.data?props.data.comments:''}</span>
								</div>

                                <div class="item-edit">
                                        
                                      
                                      <div title="Редактировать" style={{width:20,marginLeft:10,marginRight:10}}>
                                      <EditIcon width="15" height="15" 
                                        onClick={(e)=>props.userEdit(props.data.id)}/>
                                      </div>
									 <div title="Удаление" style={{width:20}}>
                                     <DeleteIcon width="15" height="15" 
                                        onClick={(e)=>props.delAction(props.data)}/>
                                     </div>
                                    
                                
                                </div>
							</div>
						</div>
  );
}

import React from 'react';
import {Button, Grid} from "@material-ui/core";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';


import CustomizedButton from "../_components/buttons/CustomizedButton";
import TextField from '@material-ui/core/TextField';
import { SendButton, TextFieldCell, Snack} from "../_components";

import AgrInfo from './AgrInfo';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';

import {pdText} from '../_constants'; 
import {pdHtml} from '../_constants'; 
import { history } from '../_helpers';
import { CircularProgress } from '@material-ui/core';
import { confService } from '../_services';

import {connect} from 'react-redux';
import {userActions} from '../_actions';
import {store} from '../_helpers'; 


function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
 

class RegistrPage extends React.Component {
    constructor(props) {
        super(props);
        // reset login status
      
        this.state = {
            username: '',
            pwd: '',
            pwd2: '',
            submitted: false,
            wrong: false,
            toShort: false,
            isStudent:true,
            agreeOk: false,
            slotInfoOpen: false,
            userType: '',
        };
        
        store.subscribe(this.storeChange)
    }
    
    
    
    storeChange = (e) => {
        
        console.log(e);
        console.log(store.getState());
        if (store.getState().authentication.error && store.getState().authentication.isLoading == false)
        {
            //alert(store.getState().authentication.error);
            //alert("Неправильные email пользователя или пароль"); 'Ошибка при выполнении запроса'
            //console.log(store.getState().authentication.error+'');
            let snText = 'Пользователь с таким e-mail уже существует';
            this.setState({snackText:snText,alertShow:true,err_type:"error"});
            
        }
        
        
    }
    
    componentDidMount() {
     
     const {dispatch} = this.props;  
     
    }
    
    onCloseSnack = () => {
        this.setState({alertShow: false});
    };
    
    clickAgr = () => {
        
        this.setState({slotInfoOpen:true});
    }

    handleChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };

    handleSubmit = (e) => {
        
        var self = this;
        
        e.preventDefault();

        const {username, pwd, pwd2, isStudent,agreeOk, userType} = this.state;
        const {dispatch} = this.props;  
        
        let snText = '';
        
        if (agreeOk == false)
        {
            snText = 'Примите соглашение об обработке персональных данных';
            this.setState({snackText:snText,alertShow:true,err_type:"warning"});
            
        }
        else if (confService.validateEmail(username)==false)
        {
            //alert('Введите корректный email');
            snText = 'Введите корректный email';
            this.setState({snackText:snText,alertShow:true,err_type:"warning"});
        }
        else if (pwd.length<6)
        {
            //alert('Пароль должен быть не меньше 6 символов');
            snText = 'Пароль должен быть не меньше 6 символов';
            this.setState({snackText:snText,alertShow:true,err_type:"warning"});
        }
        else if (confService.validatePassword(pwd)==false)
        {
            //alert('Пароль должен содержать только латинские буквы и цифры и ');
            snText = 'Пароль должен содержать только латинские буквы, цифры и дефис';
            this.setState({snackText:snText,alertShow:true,err_type:"warning"});
        }
        else if (pwd != pwd2)
        {
            //alert('Пароли не совпадают');
            snText = 'Пароли не совпадают';
            this.setState({snackText:snText,alertShow:true,err_type:"warning"});
        }
        else if (userType == '')
        {
            snText = 'Выберите тип аккаунта';
            this.setState({snackText:snText,alertShow:true,err_type:"warning"});
        }
        else {
          
          //alert('ok');
            //history.push('/');
            //if (agreeOk == true)
                dispatch(userActions.registr({username:username,password:pwd,isStudent:userType =='visitor'}));
            
        }
    };
    
   
    render() {
        
        console.log(this.props);
        
        const { error, isLoading } = this.props;
        
        const {slotInfoOpen, username, submitted, pwd, pwd2, isStudent, agreeOk, snackText, err_type, alertShow, userType} = this.state;
        
        let errorMsg = 'Неверный токен';
        if (error) {
            if (error === "Forbidden") errorMsg = "Неправильный токен";
        }
        
        /*
        <a href="https://api.naviconf.com/auth/google" 
                    style={{marginTop: 30,fontSize:20}}>
                    <span style={{color:'blue', textDecoration: 'underline'}}>Войти через Google</span>
                    </a>
                    */

        return (
            <div>
            <Grid container justify="center" alignItems="center" style={{height: '100vh'}}>
                <Grid item style={{width: 600}}>
                    <form name="form" onSubmit={this.handleSubmit} style={{justifyContent: 'center'}}>
                        <div style={{verticalAlign: 'center'}}>
                      
                        </div>
                        <div className={(submitted && !username ? ' has-error' : '')}>
                            
                             <TextFieldCell id="login"
                                       label="E-mail пользователя"
                                       name="username"
                                       type="text"
                                       value={username}
                                       onChange={this.handleChange}/>             

                        </div>
                        <div className={(submitted && !username ? ' has-error' : '')}>
                            
                             <TextFieldCell id="pwd"
                                       label="Пароль"
                                       name="pwd"
                                       type="password"
                                       value={pwd}
                                       onChange={this.handleChange}/>             

                        </div>
                        <div className={(submitted && !username ? ' has-error' : '')}>
                            
                             <TextFieldCell id="pwd"
                                       label="Повторите пароль"
                                       name="pwd2"
                                       type="password"
                                       value={pwd2}
                                       onChange={this.handleChange}/>             

                        </div>
                        <FormGroup >
                         <RadioGroup
                                aria-labelledby="demo"
                                name="user_type"
                                value={userType}
                                row
                                onChange={(e)=>this.setState({userType:e.target.value})}
                              >
                                <FormControlLabel value="visitor" control={<Radio />} label="Посетитель" />
                                <FormControlLabel value="org" control={<Radio />} label="Организатор" />
                              </RadioGroup>
                        
                        </FormGroup>
                        
                         <div 
                            style={{marginTop: 10,marginBottom:10,  fontSize:20, marginLeft:-3, display:'flex',flexDirection:'row'}}>
                            <Checkbox color="default" style={{paddingLeft:0,paddingTop:0}}
                        checked={agreeOk} onClick={()=>this.setState({agreeOk:!agreeOk})} />
                            <p>Я ознакомился с <span> </span> 
                            <span style={{color:'blue', textDecoration: 'underline'}} onClick={this.clickAgr}>
                            Соглашением об обработке персональных данных</span>
                            и даю своё согласие на их обработку</p>
                            </div>
                        <div className="form-group"
                             style={{marginTop: 5, justifyContent: 'space-between', flex: 1, display: 'flex'}}>
                            
                                    <CustomizedButton
                                    style={{marginBottom: 5}}
                                    onClick={this.handleSubmit}
                        id="category-button-add"
                        type="submit"
                        title="Регистрация"
                        prim
                        />
                        
                        <a onClick={()=>history.push('/login')} 
                        style={{marginTop: 10,fontSize:20}}>
                        <span style={{color:'blue'}}>Назад</span>
                    </a>
                        </div>
                        
                    </form>
                    
                </Grid>
            </Grid>
            {isLoading && <div className="shadow"><div className="shadow_circle"><CircularProgress/></div></div>}    
            <AgrInfo
                         handleClose={()=>this.setState({slotInfoOpen:false})}  
                        data={pdHtml}
                        open={slotInfoOpen}/>
            <Snack open={alertShow} variant={err_type} text={snackText} onClose={this.onCloseSnack}/>
            </div>
            
        );
    }
}

function mapStateToProps(state) {
    const {loggingIn, error, isLoading} = state.authentication;
    return {
        loggingIn,
        isLoading,
        error
    };
}

const connectedRegistrPage = connect(mapStateToProps)(RegistrPage);
export {connectedRegistrPage as RegistrPage};
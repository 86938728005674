export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    REG_REQUEST: 'USERS_REG_REQUEST',
    REG_SUCCESS: 'USERS_REG_SUCCESS',
    REG_FAILURE: 'USERS_REG_FAILURE',
    USERINFO_REQUEST: 'USERS_INFO_REQUEST',
    USERINFO_SUCCESS: 'USERS_INFO_SUCCESS',
    USERINFO_FAILURE: 'USERS_INFO_FAILURE',
    LOGOUT: 'USERS_LOGOUT',  
    SET_ERROR: 'SET_ERROR',
    DISABLE_ERROR: 'DISABLE_ERROR',
    CONFLIST_REQUEST: 'USERS_CONFLIST_REQUEST',
    CONFLIST_SUCCESS: 'USERS_CONFLIST_SUCCESS',
    CONFLIST_FAILURE: 'USERS_CONFLIST_FAILURE',
    CONFLIST_STUDENT_REQUEST: 'USERS_CONFLIST_STUDENT_REQUEST',
    CONFLIST_STUDENT_SUCCESS: 'USERS_CONFLIST_STUDENT_SUCCESS',
    CONFLIST_STUDENT_FAILURE: 'USERS_CONFLIST_STUDENT_FAILURE',
    ADDCONF_REQUEST: 'USERS_ADDCONF_REQUEST',
    ADDCONF_SUCCESS: 'USERS_ADDCONF_SUCCESS',
    ADDCONF_FAILURE: 'USERS_ADDCONF_FAILURE',
    TEAMORG_REQUEST: 'USERS_TEAMORG_REQUEST',
    TEAMORG_SUCCESS: 'USERS_TEAMORG_SUCCESS',
    TEAMORG_FAILURE: 'USERS_TEAMORG_FAILURE',
    SET_CONF: 'USERS_SET_CONF',
    SET_CONF_SELECTED: 'USERS_SET_CONF_SELECTED',
    
    EDITCONF_REQUEST: 'USERS_EDITCONF_REQUEST',
    EDITCONF_SUCCESS: 'USERS_EDITCONF_SUCCESS',
    EDITCONF_FAILURE: 'USERS_EDITCONF_FAILURE',
    
    DELCONF_REQUEST: 'USERS_DELCONF_REQUEST',   
    DELCONF_SUCCESS: 'USERS_DELCONF_SUCCESS',
    DELCONF_FAILURE: 'USERS_DELCONF_FAILURE',
    
    SCHEDULE_REQUEST: 'USERS_SCHEDULE_REQUEST',
    SCHEDULE_SUCCESS: 'USERS_SCHEDULE_SUCCESS',
    SCHEDULE_FAILURE: 'USERS_SCHEDULE_FAILURE',
    
    SECTIONS_REQUEST: 'USERS_SECTIONS_REQUEST',
    SECTIONS_SUCCESS: 'USERS_SECTIONS_SUCCESS',
    SECTIONS_FAILURE: 'USERS_SECTIONS_FAILURE',
    
};

export const serverUrl = 'https://api1.naviconf.com/'; 

export const pdText = `
Предоставляя свои персональные данные Пользователь даёт согласие на обработку, хранение и использование своих персональных данных на основании ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006 г. в следующих целях:

Осуществление клиентской поддержки
Получения Пользователем информации о маркетинговых событиях
Проведения аудита и прочих внутренних исследований с целью повышения качества предоставляемых услуг.
Под персональными данными подразумевается любая информация личного характера, позволяющая установить личность Пользователя/Покупателя такая как:

Фамилия, Имя, Отчество
Дата рождения
Контактный телефон
Адрес электронной почты
Почтовый адрес
Персональные данные Пользователей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства.

Компания обязуется не передавать полученные персональные данные третьим лицам, за исключением следующих случаев:

По запросам уполномоченных органов государственной власти РФ только по основаниям и в порядке, установленным законодательством РФ
Стратегическим партнерам, которые работают с Компанией для предоставления продуктов и услуг, или тем из них, которые помогают Компании реализовывать продукты и услуги потребителям. Мы предоставляем третьим лицам минимальный объем персональных данных, необходимый только для оказания требуемой услуги или проведения необходимой транзакции.
Компания оставляет за собой право вносить изменения в одностороннем порядке в настоящие правила, при условии, что изменения не противоречат действующему законодательству РФ. Изменения условий настоящих правил вступают в силу после их публикации на Сайте.`;

export const cookieText = 'Продолжая пользоваться сайтом, вы даёте cогласие на автоматический сбор и анализ ваших данных, необходимых для работы сайта и его улучшения, а также использование файлов cookie';

import React from 'react';
import PropTypes from 'prop-types';
import { history } from '../_helpers';
import { SendButton, TextFieldCell, Snack} from "../_components";
import '../css/style.css';
import { storeData, setStore } from '../store.js';
import { connect } from "react-redux";
import {store} from '../_helpers'; 
import ClientConfItem from './ClientConfItem';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import { userActions, appActions } from "../_actions";
import { userService, confService } from '../_services';
import i18next from 'i18next';

class ClientListPage extends React.Component {


	constructor(props)
	{
		super(props);
        
		 this.state = { isLoading:false}
        store.subscribe(this.storeChange)
	}

    componentDidMount() {
        
        this.props.dispatch(userActions.conf_student_list());
        //confService.confListParticipants(true);
        
    }
    
     storeChange = (e) => {
        
     }  

    onItemClick = (e) => {
        
    }
    
    onItemEdit = (e) => {
        
          this.props.dispatch(userActions.set_conf(e));
        this.props.dispatch(appActions.setpage('conf_view',e.id));
    }
    
    onItemDelete = (e) => {
        
    }
   

    render() {
	
		let self = this;
        const { isLoading } = this.state;
     const { classes, user } = this.props;
     console.log(user);
     
        
        return (
        <div class="layout">
				<div class="layout__contains">
					<h1 class="layout__title">Список Конференций</h1>

					
				</div>

				<ul class="conf-list">
                {user.conflist_student.length>0 && user.conflist_student.map(function(item,index){
                    return <ClientConfItem data={item} 
                            key={index}
                            onClick={()=>self.onItemClick(item.id)} 
                            onEdit={()=>self.onItemEdit(item)} 
                            onDelete={()=>self.onItemDelete(item.id)}         
                                    />
                    })}
				
					
				</ul>
			</div>
        );
    }
}


function mapStateToProps(state) {
    const {app, user, authentication} = state;
    
    return {
        user,
        app,
        authentication
    };
}

const connectedClientListPage = connect(mapStateToProps)(ClientListPage);
export {connectedClientListPage as ClientListPage};

import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function MemberDialog(props) {
  const [name, setName] = React.useState('');
  const [description, setDesc] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [city, setCity] = React.useState('');
  const [comm, setComm] = React.useState('');
  const [count,setCount ] = React.useState(0);

	let { handleClose, label, title, dialogOpen, dName,dPhone,dMail, dDesc,dComm, dCity, id } = props;
    
    console.log("ID",id);
    
    React.useEffect(() => {
         
         if (count == 0)
		  {
			setPhone(dPhone);
            setName(dName);
            setDesc(dDesc);
            setCity(dCity);
            setEmail(dMail);
            setComm(dComm);
		  }
    
    });
	
	function saveData(e){
        
        e.preventDefault();
        
        if (name != '' )
        {
            handleClose({name,phone,city,description,email,comment:comm});
                setCount(0);
        }
            
	}
	
	function cancelData() {
		handleClose('');
		setCount(0);
	}
 
  return (

      <Dialog open={dialogOpen}>
	<DialogTitle>{title}</DialogTitle>
    <form onSubmit={saveData}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Имя"
            fullWidth
            variant="standard"
			value={name}
            required
			onChange={(e)=>{setName(e.target.value);setCount(count+1)}}
          />
          <TextField
            autoFocus
            margin="dense"
            id="desc"
            label="Описание"
            fullWidth
            variant="standard"
			value={description}
			onChange={(e)=>{setDesc(e.target.value);setCount(count+1)}}
          />
          <TextField
            margin="dense"
            id="email"
            label="Email"
            fullWidth
            variant="standard"
			value={email}
			onChange={(e)=>{setEmail(e.target.value);setCount(count+1)}}
          />
          <TextField
            margin="dense"
            id="phone"
            label="Телефон"
            fullWidth
            variant="standard"
			value={phone}
			onChange={(e)=>{setPhone(e.target.value);setCount(count+1)}}
          />
            <TextField
            margin="dense"
            id="phone"
            label="Город"
            fullWidth
            variant="standard"
			value={city}
			onChange={(e)=>{setCity(e.target.value);setCount(count+1)}}
          />
            <TextField
            margin="dense"
            id="phone"
            label="Комментарий"
            fullWidth
            variant="standard"
			value={comm}
			onChange={(e)=>{setComm(e.target.value);setCount(count+1)}}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Сохранить</Button>
          <Button onClick={cancelData}>Отмена</Button>
        </DialogActions>
        </form>
      </Dialog>

  );
}
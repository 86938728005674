import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import '../css/style.css';
import { history } from '../_helpers';
import { connect } from "react-redux";
import {appActions} from "../_actions";
//import Popover from '@mui/material/Popover';

import i18next from 'i18next';


function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
} 

  function setCookie(name, value, options) {
                options = options || {};
                let expires = options.expires;
                if (typeof expires == "number" && expires) {
                    var d = new Date();
                    d.setTime(d.getTime() + expires * 1000);
                    expires = options.expires = d;
                }

                if (expires && expires.toUTCString)
                    options.expires = expires.toUTCString();

                value = encodeURIComponent(value);
                let updatedCookie = name + "=" + value;
                for (let propName in options) {
                    updatedCookie += "; " + propName;
                    let propValue = options[propName];
                    if (propValue !== true) {
                        updatedCookie += "=" + propValue;
                    }
                }

                document.cookie = updatedCookie;
            }    

class HeadMenu extends React.Component {
  
changeLang = () => {
    
    let { lang } = this.props.app;
    if (lang == "ru")
        lang = "en"
    else
        lang="ru"

    i18next.init({
      lng: lang,
      resources: require(`../_lang/${lang}.json`)
    });

    this.props.dispatch(appActions.setlang(lang));
    
    
}



onLogout = () => {
    
    setCookie('user','');
    setCookie('token','');
    localStorage.setItem('conf','{"id":0}'); 
    
    //alert(getCookie('token'));
    
    
    history.push('/login'); 
    
}
  
render()
{
    
    console.log(this.props);
    var self = this;
        
    const { lang } = this.props.app;
    
  return (
    <div >
      <ul class="header__blocks">
					<li class="header__block header__form">
						<form action="" class="header__form-search">
							<button type="submit" class="header__form-submit">
								<svg width="24" height="24" width="22" height="22" fill="none">
									<use href="./img/sprite.svg#search-icon"></use>
								</svg>
							</button>

							<input type="text" placeholder="Поиск по личному кабинету и базам" class="header__form-input" />
						</form>
					</li>

					<li class="header__block">
						<a href="#" class="profile">
							<img src="" alt="" class="profile__img"/>
							<span class="profile__name">{self.props.name?self.props.name:self.props.email}</span>
						</a>
					</li>

					<li class="header__block">
                        
						<div class="block-dropdown">
                        
                        
							<button class="block-dropdwon__btn">
                                {self.props.type== "org" &&<span>Организатор</span>}
                                {self.props.type== "student" &&<span>Участник</span>}
                                {self.props.type== "team_member" &&<span>Член команды</span>}
								<svg class="block-dropdwon__icon" width="10" height="7" fill="none">
									<use href="./img/sprite.svg#dropdown-icon"></use>
								</svg>
							</button>
						</div>
					</li>

					<li class="header__block">
						<div class="lang">
							<button class="lang__btn" onClick={self.changeLang}>
								<img src="./img/lang_ru.png" alt="" class="lang__flag"/>
								<span>{lang}</span>
								<svg class="lang__icon" width="10" height="7" fill="none">
									<use href="./img/sprite.svg#dropdown-icon"></use>
								</svg>
							</button>
						</div>
					</li>
                    
                    <li class="header__block">
						<div class="lang">
							<button class="block-dropdwon__btn" onClick={self.onLogout}>
                               <span>Выйти</span>
							</button>
						</div>
					</li>
				</ul>
    </div>
  );
}
}



function mapStateToProps(state) {
    const {app, user, authentication} = state;
    //const {user} = authentication;
    const {unresolvedAppealCount} = app;
    return {
        user,
        app,
        authentication
    };
}

const connectedHeadMenu = connect(mapStateToProps)(HeadMenu);
export {connectedHeadMenu as HeadMenu};

import  { userService }  from './user.service';
import { store } from '../_helpers';
import {appActions, userActions, scheduleActions} from "../_actions";

export const scheduleService = {
    
    getSchedule,
    updateSchedule,
    getThemeList,
    getRoomList,
    getSpeakerList,
    addTheme,
    delTheme,
    editTheme,
    delRoom,
    addRoom,
    editRoom,
    addSpeaker,
    delSpeaker,
    editSpeaker,
    delSection,
    addSection,
    editSection,
    setSlotMode,
    setSchedule,
	setSlots,
    changeSectionName,
    saveUrl,
};




function getSchedule(id,curDate) {
        
          const state = store.getState();
        console.log(state);
        
        if (curDate)
            store.dispatch(scheduleActions.set_date(curDate));  
        else
            curDate = state.curDate;
            
        userService.getWrapper('api/schedule/'+id,function(data) 
		   {
               console.log(data);
                getLessons(data,id,curDate);
                   
                    
			},function(error) {  if (error!='')   { 
            store.dispatch(userActions.set_error(error));  
             }
		
		   })
        
        
}

function getLessons(data,id,curDate) {
        
        let self = this;
        let lessons = [...data.data.lessons];
        let allThemesId = [];
                           
        for (let i=0;i<lessons.length;i++)
        {
            
            let oldDate = ''
            if (lessons[i].date)
            {
                oldDate = new Date(lessons[i].date);
                
                let dayData = ""+oldDate.getDate();
                if (dayData.length<2) dayData = "0"+dayData;
                
                let mData = ""+(1+oldDate.getMonth());
                if (mData.length<2) mData = "0"+mData;
                
                let newDate = oldDate.getFullYear()+'-'+mData+'-'+dayData;
                lessons[i].date = newDate;
            }
            
            if (lessons[i].section)
                                  allThemesId.push(data.data.lessons[i].id);
        }                      
         
        //self.setState({allLessons:[...lessons],allThemeList:allThemesId});
        store.dispatch(scheduleActions.set_lessons(lessons));
        store.dispatch(scheduleActions.set_themes_id(allThemesId));
        
        getThemeList(id,allThemesId);
        filterLessons(lessons,id,curDate);
                        
}

function filterLessons(lessonsSrc,id,curDate) {
        
        let sections = [];
		let slots = [];
        let lessons = [];
        let allThemesId = [];
        
        
        if (curDate)
        {
         
            for (let i=0;i<lessonsSrc.length;i++)
            {
                if (lessonsSrc[i].date && lessonsSrc[i].date.indexOf(curDate)>=0)
                {
                    lessons.push({...lessonsSrc[i]});
                }
            }
        }
        else
            lessons = [...lessonsSrc];
        
        for (let i=0;i<lessons.length;i++)
                   {
					   
					       let tmp_obj = {theme:'',room:'',speakers:[],
                            id:lessons[i].id,
                            url_online:lessons[i].url_online,
                            type:lessons[i].type,
                            status:lessons[i].status,
                            payment_status:lessons[i].payment_status,
                            date:lessons[i].date,
                            start_time:lessons[i].start_time,
                            duration:lessons[i].duration};
                            
                         
                            tmp_obj.theme = lessons[i].name;
                            tmp_obj.speakers = [...lessons[i].speakers];
                            if (tmp_obj.speakers.length==0)
                                tmp_obj.speakers = [{name:'',status:"0",payment_status:"0"}]
                                
                            
                            if (lessons[i].room_number)
                                tmp_obj = {...tmp_obj,room:lessons[i].room_number,room_id:lessons[i].room_id};
                                
					   
					   
                        if (lessons[i].section)
                        {
                            let sect_id = lessons[i].section.id;
                            let sect_index = -1;
                            
                            if (sections.length>0)
                                for (let j=0;j<sections.length;j++)
                                if (sections[j].id == sect_id)
                                {
                                    sect_index = j;
                                    break;
                                }
                                
                            if (sect_index<0)
                                {
                                    sections.push({...lessons[i].section,slots:[]});
                                    sect_index = sections.length-1;
                                }
                            
							   
                            if (lessons[i].section)
                                allThemesId.push(lessons[i].id);
                            
                        
                            sections[sect_index].slots.push({...tmp_obj});
                        }
						else  if (lessons[i].date)
						{
							slots.push({...tmp_obj});	
						}
                   }
				   
				   store.dispatch(scheduleActions.set_slots(slots)); 
                   
                   let sections1 = sections.filter(e=>e.slots && e.slots.length>0);
                   
                   getSectionAPI(id,sections1,lessonsSrc);
}

function getSectionAPI(id, sections_src,allLessons){
        
        let sections = [...sections_src];
        
        //let sectIdArray = sections.map(e=>e.id);
        
        let sectIdArray = [];
        for (let i=0;i<allLessons.length;i++)
            if (allLessons[i].section && allLessons[i].section.id && sectIdArray.indexOf(allLessons[i].section.id)<0 )
                sectIdArray.push(allLessons[i].section.id);
            
        userService.getWrapper('api/section?conference_id='+id,function(data) 
		   {
        
            if (data.status == 200)
            {
                let tmpSect = [...data.data];
                for (let i=0;i<tmpSect.length;i++)
                    if (sectIdArray.indexOf(tmpSect[i].id)<0)
                    {
                        sections.push({...tmpSect[i],slots:[]});
                    }
                    
                sections.sort(function(a,b){
                  
                  if (a.order_id && !b.order_id)
                      return 1;
                  
                  if (!a.order_id && b.order_id)
                      return -1;
                  
                  if (!a.order_id && !b.order_id)
                      return 0;
                  
                  if (a.order_id && b.order_id)
                      return a.order_id-b.order_id;
                    
                });
                
                //self.setState({sections:[...sections],isLoading:false});
                store.dispatch(scheduleActions.set_schedule(sections));      
            }
            else
               //self.setState({isLoading:false}); 
                store.dispatch(userActions.set_error(data.status));  
           },
           function(error)
           {
               store.dispatch(userActions.set_error(error));  
           });
        
}

function updateSchedule(id,post_data,curDate,is_reload = false) {
         
        
          userService.postWrapper('api/schedule/'+id,post_data,function(data) 
           {
               
                    if (data.data && data.data.lessons && is_reload == false)
                            getLessons(data,id,curDate);
                    else
                         getSchedule(id,curDate);
                            
            },function(error) { 
                store.dispatch(userActions.set_error(error));  
            
          });
}
   

function getThemeList(id,allThemeList=null){
    
        if (!allThemeList)
        {
            const state = store.getState();    
            console.log(state);
            allThemeList = [...state.schedule.themes_id];
            
        }
    
		userService.getWrapper('api/lesson?conference_id='+id,function(data) 
		   {
				if (data.data && data.data.length>0) 
                {
                    let tmpThemes = data.data.filter(e=>e.name);
					
                    for (let i=0;i<tmpThemes.length;i++)
                    {
                        if (allThemeList.indexOf(tmpThemes[i].id)>=0)
                            tmpThemes[i].selected = true;
                        if (!tmpThemes[i].name)
                            tmpThemes[i].name = '';
                    }
                    
                    store.dispatch(scheduleActions.set_themes(tmpThemes));  
                }
                else
                    store.dispatch(scheduleActions.set_themes([]));  
			},function(error) {  
                store.dispatch(userActions.set_error(error));          
            
		   })
}

function addTheme(id,post_data,formData=null) {
    
    userService.postWrapper('api/lesson?conference_id='+id,post_data,function(data) 
                    {
                        
                        if (formData && data.data)
                        {
                            formData.append('documentable_type','lesson');
                            formData.append('documentable_id',data.data);
                            uploadFileData(formData,id);
                        }
                        else 
                            getThemeList(id);
                    
                    },function(error) {  store.dispatch(userActions.set_error(error)); })
}

function editTheme(id,lesson_id,p_data,formData=null) {
    
    userService.patchWrapperBody('api/lesson/'+lesson_id+'?conference_id='+id,{...p_data},function(data) 
                    {
                         if (formData)
                        {
                            formData.append('documentable_type','lesson');
                            formData.append('documentable_id',lesson_id);
                            uploadFileData(formData,id);
                        }
                        else 
                            getThemeList(id);
                     
                    
                    },function(error) {  store.dispatch(userActions.set_error(error)); })
}

function delTheme(c_id,id,post_data) {
    
       const state = store.getState();
       
    let { themes, curDate } = state.schedule;
       
        userService.delWrapper('api/lesson/'+id,function(data) 
		{
                if (data.status  == 200)
                {
                    if (post_data)
                    {
                        updateSchedule(c_id,post_data,curDate);
                    }
                    else 
                    {
                        getThemeList(c_id);
                    }
                }
                    
			},function(error) {  
			
                store.dispatch(userActions.set_error(error.statusText));
		   })	
    
    
}

function getRoomList(id){
		
		userService.getWrapper('api/room?conference_id='+id,function(data) 
		   {
            	if (data.data && data.data.length>0)
                    store.dispatch(scheduleActions.set_rooms([...data.data]));  
                    
                    
			},function(error) { store.dispatch(userActions.set_error(error));  })
}

function getSpeakerList(id) {
    
    var self = this;
		
        userService.getWrapper('api/speaker',function(data) 
		   {
				if (data.data && data.data.length>0) 
                    store.dispatch(scheduleActions.set_speakers([...data.data]));  
                else
                    store.dispatch(scheduleActions.set_speakers([]));  
                
                    
			},function(error) { store.dispatch(userActions.set_error(error)) });
		
}

function addSpeaker(id,json_data) {
    
     userService.postWrapper('api/speaker',json_data,function(data) 
                    {
                            getSpeakerList(id);
                    
                    },function(error) {  store.dispatch(userActions.set_error(error)); })
}

function editSpeaker(c_id,id,data) {
    
                
            userService.patchWrapperBody('api/speaker/'+id,data,function(data) 
           {
                if (data.status == 200)
                {
                    getSpeakerList(c_id);
                    getSchedule(c_id);
                }
                            
            },function(error) {  store.dispatch(userActions.set_error(error));
            });

    
}

function delSpeaker(c_id,id) {
    
    const state = store.getState();
    let { speakers } = state.schedule;
		
			userService.delWrapper('api/speaker/'+id,function(data) 
			{
	              getSpeakerList(c_id);    
                  
        	},function(error) {  
                store.dispatch(userActions.set_error('Спикер добавлен к леции, невозможно удалить'));
		   })	
 
}

function addRoom(id,json_data) {
                    
                userService.postWrapper('api/room',json_data,function(data) 
                    {
                    if (data.status == 200)
                        getRoomList(id);
                    
                    },function(error) { 
                     store.dispatch(userActions.set_error(error)); 
                   })                
}

function editRoom(c_id,id,val) {
                    
    userService.patchWrapper('api/room/'+id+'?number='+val+'&conference_id='+c_id,function(data) 
           {
                    
                if (data.status == 200)
                {
                    getRoomList(c_id);
                    getSchedule(c_id);
                }
                else
                    store.dispatch(userActions.set_error(data.status)); 
                    
                            
            },function(error) {  store.dispatch(userActions.set_error(error)); });
}            

function delRoom(c_id,id) {
    
    const state = store.getState();
    
    let { rooms, sections } = state.schedule;
    
		let roomName = ''
        
         for (let i=0;i<rooms.length;i++)
                if (rooms[i].id == id)
                {
                    roomName = rooms[i].number;
                    break;
                }
        
        let isCanDelete = true;
        
        for (let i=0;i<sections.length;i++)
            for (let j=0;j<sections[i].slots.length;j++)
                if (sections[i].slots[j].room == roomName && roomName != '')
                {
                    isCanDelete = false;
                    break;
                }
                                
        if (isCanDelete == true)
        {
		
            for (let i=0;i<rooms.length;i++)
                if (rooms[i].id == id)
                {
                    rooms = rooms.filter(e=>e.id != id)
                    break;
                }
			
			userService.delWrapper('api/room/'+id,function(data) 
			{
			       getRoomList(c_id);
			},function(error) {  
			
            store.dispatch(userActions.set_error('Помещение используется'));
    	
		   });
        }
        else
        {
           store.dispatch(userActions.set_error('Помещение используется'));
        }
    
}

function delSection(c_id,ind) 
{
        
        const state = store.getState();
    
        let { themes, sections } = state.schedule;
        
        let allThemes = sections[ind].slots;
        
        if (sections[ind].id)
        {
            userService.delWrapper('api/section/'+sections[ind].id,function(data) 
			{
                    getSchedule(c_id);
			        
			},function(error) {  store.dispatch(userActions.set_error(error));     });
        }           
        else
        {
                getSchedule(c_id);
        }
        
}
    
    
function addSection(c_id,val) {
    
    
}

function editSection(c_id,id,val) {
    
    
}

function setSchedule(sections)
{
    store.dispatch(scheduleActions.set_schedule(sections));     
    
}

function setSlots(slots)
{
    store.dispatch(scheduleActions.set_slots(slots));     
    
}



function setSlotMode(c_id,sectIndex,slotIndex,format) {
    
        const state = store.getState();
        let { sections, slots } = state.schedule;
		let id = null;
		let tp = null;
		
		if (sectIndex>=0)
		{
			id = sections[sectIndex].slots[slotIndex].id;
			tp = sections[sectIndex].slots[slotIndex].type;
		}
		else
		{
			id = slots[slotIndex].id;
			tp = slots[slotIndex].type;
		}
        
        
        if (format == "hybrid")
        {
        
        let newTp = "offline";
        if (!tp || tp == "offline")
            newTp = "online"
        else if (tp == "online")
            newTp = "hybrid";
        
        
        userService.patchWrapper('api/lesson/'+id+'?type='+newTp+'&conference_id='+c_id,function(data) 
           {
               console.log(data);
                    
                if (data.status == 200)
                {
					if (sectIndex>=0)
					{
						sections[sectIndex].slots[slotIndex].type = newTp;
						store.dispatch(scheduleActions.set_schedule(sections));     
					}
					else
					{
						slots[slotIndex].type = newTp;
						store.dispatch(scheduleActions.set_slots(slots));     
					}
                }
                            
            },function(error) {  store.dispatch(userActions.set_error(error));  
            });
        
        }    
}

function changeSectionName(id,e,index)
{
        const state = store.getState();
        let { sections } = state.schedule;
        
        let sectNameExists = false;
        
        for (let i=0;i<sections.length;i++)
            if (sections[i].name == e)
            {
                sectNameExists = true;
                break;
            }
		
        
        if (sectNameExists == false)
        {
            if (index>=-10)
            {
                let sectId = null;
                if (index>=0) sectId = sections[index].id;
                
                if (sectId)
                    userService.patchWrapper('api/section/'+sectId+'?name='+e+'&conference_id='+id,function(data) 
                    {
                    
                    if (data.status == 200)
                    {
                        getSchedule(id);
                    }
                    
                            
                    },function(error) { store.dispatch(userActions.set_error(error)); 
                   })
               else
               {    
               
                    let max_order = 0;
                    for (let i=0;i<sections.length;i++)
                    if (sections[i].order_id && sections[i].order_id>0 && sections[i].order_id>max_order)
                        max_order = sections[i].order_id;
                    
                    userService.postWrapper('api/section',{name:e,order_id:max_order+1,conference_id:id},function(data) 
                    {
                    if (data.status == 200)
                    {
                        getSchedule(id);
                    }
                    
                            
                    },function(error) { 
                    
                    store.dispatch(userActions.set_error(error)); 
                
                   });
               }   
            }
            else
            {
                store.dispatch(userActions.set_error('Ошибка обновления секции')); 
            }
        }
}

function saveUrl(conf_id,id,url,itemSect)
{
    
        userService.patchWrapper('api/lesson/'+id+'?url_online='+url+'&conference_id='+conf_id,function(data) 
           {
                    
                if (data.status == 200)
                    getSchedule(conf_id);
                            
            },function(error) {  store.dispatch(userActions.set_error(error)); 
            });
        
}

function uploadFileData(data_upload,c_id)
	{
      
         userService.uploadFile('api/document',data_upload,function(data) 
               {
                   getThemeList(c_id);
                   
              },function(error) {store.dispatch(userActions.set_error(error)); getThemeList(c_id); }
            
               );
       
	}
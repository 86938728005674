import React from 'react';
import PropTypes from 'prop-types';
import { history } from '../_helpers';
import { SendButton, TextFieldCell, Snack} from "../_components";
import '../css/style.css';

import { storeData, setStore } from '../store.js';
import { connect } from "react-redux";
import {store} from '../_helpers'; 
import { userService, confService } from '../_services';

import PartItem from './PartItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import { userActions, appActions } from "../_actions";
import i18next from 'i18next';
import { CircularProgress } from '@material-ui/core';

class PartPage extends React.Component {

	constructor(props)
	{
		super(props);
        
		 this.state = { isLoading:false, team:[]};
        
	}

    componentDidMount() {
        
        
            this.getTeam();
        
    }
    
    getTickets = (id) => {
        
        //userService.getAsync(['api/ticket-type?conference_id='+id,'api/ticket-type?conference_id='+id],function(data){console.log(data)});
        
        
    }
    
    getConfs = () => {
        
        
    }
   
    getTeam = () => {
        
        var self = this;
        
        console.log(this.props.user);
        
        confService.confListStudents(
        function(data){ console.log(data); self.setState({team:[...data],isLoading:false});},
        function(err) { console.log(err);}
        );
                
    }
    
    

    render() {
	
		let self = this;
        const { isLoading, team, roles_list } = this.state;
     const { classes } = this.props;
     
     console.log(this.props.conf_id);
    //{this.props.conf_name}
        
        return (
         <div class="layout">
        <div class="layout__contains">
        <h1 class="layout__title">Участники конференций</h1>
        
				</div>

				<div class="tbl">
					<div class="tbl__top">
						<div class="tbl__items">
                            <div class="tbl__item tbl__item--city">
								<input type="text" placeholder="Поиск по конференции"/>
							</div>
							<div class="tbl__item tbl__item--name">
								<input type="text" placeholder="Поиск по имени"/>
							</div>
                            <div class="tbl__item tbl__item--text">
								<input type="text" placeholder="Поиск по билету"/>
							</div>
                            <div class="tbl__item tbl__item--text">
								<input type="text" placeholder="Поиск по дате"/>
							</div>
                            <div class="tbl__item tbl__item--text">
								<input type="text" placeholder="Кол-во"/>
							</div>


						</div>
					</div>

					<div class="tbl__body">
                    
                    {team && team.map(function(item,index){
                        
                        return(
                            <PartItem 
                                data={item} 
           
                            />)
                    })
                    }
                    
        </div>
    </div>
           
               {isLoading && <div className="shadow"><div className="shadow_circle"><CircularProgress/></div></div>} 
        </div>
            
        );
        
    }
}


function mapStateToProps(state) {
    const {app, user, authentication} = state;
    
    return {
        user,
        app,
        authentication
    };
}

const connectedPartPage = connect(mapStateToProps)(PartPage);
export {connectedPartPage as PartPage};

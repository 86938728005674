import React from 'react';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import '../css/style.css';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@mui/material/Tooltip';
import RightIcon from '@material-ui/icons/ArrowRight';
import LeftIcon from '@material-ui/icons/ArrowLeft';
import themeLogo from '../img/themes.png';
import spLogo from '../img/sp.png';
import roomLogo from '../img/rooms.png';
import addLogo from '../img/add.png';
import resetLogo from '../img/reset.png';


export default function DragMenu(props) {

    let {
        addSection,
		addSlot,
        themes,
        speakers,
        rooms,
        isOpen,
        onDragClick,
        addThemeDialog,
        editTheme,
        delTheme,
        addSpeakerDialog,
        editSpeaker,
        delSpeaker,
        addRoomDialog,
        editRoom,
        delRoom
    } = props;

    let [curTab, setTab] = React.useState('theme');
    let [themeFilter, setThemeFilter] = React.useState('');
    let [speakerFilter, setSpeakerFilter] = React.useState('');
    let [roomFilter, setRoomFilter] = React.useState('');


    return (
        <div class={isOpen ? "constructor-tabs open" : "constructor-tabs"}>
            <div class="tabs-nav">
                <ul class="top-links">
                    <li class="tab-link active" onClick={() => setTab('theme')}>
                        <img src={themeLogo} style={{marginLeft: 5, marginBottom: 5}}/>
                        <span class="link-name">Темы</span>
                    </li>
                    <li class="tab-link" onClick={() => setTab('speaker')}>
                        <img src={spLogo} style={{marginLeft: 5, marginBottom: 5}}/>
                        <span class="link-name">Спикеры</span>
                    </li>
                    <li class="tab-link" onClick={() => setTab('room')}>
                        <img src={roomLogo} style={{marginLeft: 12, marginBottom: 5}}/>
                        <span class="link-name">Помещения</span>
                    </li>
                    <div class="top-links__active"></div>
                </ul>

                <div class="toggle-tabs" onClick={() => onDragClick()}>
                    {isOpen?<RightIcon/>:<LeftIcon/>}
                </div>

                <ul class="bottom-links">
                    <li>
                        <div onClick={addSection}>
                            <img src={addLogo} style={{marginLeft: 5, marginBottom: 5}}/>
                            <span class="link-name">Добавить секцию</span>
                        </div>
                    </li>

                </ul>
				 <ul class="bottom-links">
                    <li>
                        <div onClick={addSlot}>
                            <img src={addLogo} style={{marginLeft: 5, marginBottom: 5}}/>
                            <span class="link-name">Добавить тему</span>
                        </div>
                    </li>

                </ul>
            </div>
            <div class="tabs-content" style={{width: isOpen ? 300 : 0}}>
                <div class="tab active">
                    <div class="search-tab">
                        <div>
                            {curTab == "theme" &&
                                <div className='form'>
                                    <label htmlFor="filter-theme">
                                        <i className="icon-search-two"></i>
                                    </label>
                                    <input
                                        id="filter-theme"
                                        value={themeFilter}
                                        onChange={(e) => setThemeFilter(e.target.value)}
                                        title="Фильтр по темам" placeholder="Поиск"
                                    />
                                    <button class="calendar__body-add" onClick={addThemeDialog}
                                            title="Добавление новой темы">
                                        <i className="icon-plus-border"></i>
                                    </button>
                                </div>
                            }

                            {curTab == "speaker" &&
                                <div className='form'>
                                    <label htmlFor="filter-speaker">
                                        <i className="icon-search-two"></i>
                                    </label>
                                    <input
                                        id="filter-speaker"
                                        value={speakerFilter}
                                        placeholder="Поиск"
                                        onChange={(e) => setSpeakerFilter(e.target.value)}
                                        title="Фильтр по спикерам"
                                    />
                                    <button class="calendar__body-add" onClick={addSpeakerDialog}
                                            title="Добавление нового спикера">
                                        <i className="icon-plus-border"></i>
                                    </button>
                                </div>}

                            {curTab == "room" &&
                                <div className='form'>
                                    <label htmlFor="filter-room">
                                        <i className="icon-search-two"></i>
                                    </label>
                                    <input
                                        id='filter-room'
                                        placeholder="Поиск"
                                        value={roomFilter}
                                        onChange={(e) => setRoomFilter(e.target.value)}
                                        title="Фильтр по помещениям"/>
                                    <button class="calendar__body-add" onClick={addRoomDialog}
                                            title="Добавление нового помещения">
                                        <i className="icon-plus-border"></i>
                                    </button>
                                </div>
                            }


                        </div>
                    </div>
                    {curTab == "theme" && <Droppable droppableId="theme">
                        {(provided) => (
                            <ul class="list-tab" {...provided.droppableProps} ref={provided.innerRef}>

                                {themes.filter(e => !e.selected).filter(e => e.name && e.name.indexOf(themeFilter) > -1).filter(e => e.section_id == null).map(function (item, index) {
                                    return (

                                        <Draggable key={'' + item.id} draggableId={'theme_' + item.id} index={index}>
                                            {(provided) => (
                                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                    class="calendar__item">
                                                    <div class="calendar__body-block">
                                                        <span class="input"
                                                              title="Перетащите в нужный таймслот">{item.name}</span>
                                                        <div className="option-icons">
                                                            <div title="Редактирование темы">
                                                                <EditIcon width="15" height="15"
                                                                          onClick={() => editTheme(item.id)}/></div>
                                                            <div title="Удаление темы"><DeleteIcon
                                                                width="15" height="15"
                                                                onClick={() => delTheme(item.id)}/>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </li>

                                            )}
                                        </Draggable>)
                                })}


                            </ul>
                        )}
                    </Droppable>}

                    {curTab == "speaker" && <Droppable droppableId={"speakers"}>
                        {(provided) => (
                            <ul class="list-tab" {...provided.droppableProps} ref={provided.innerRef}>

                                {speakers.filter(e => e.name.indexOf(speakerFilter) > -1).map(function (item, index) {
                                    return (

                                        <Draggable key={'' + item.id} draggableId={'speaker_' + item.id} index={index}>
                                            {(provided) => (
                                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                    class="calendar__item">
                                                    <div class="calendar__body-block" title={item.description}>
                                                        <span class="input">{item.name}</span>
                                                        <div className="option-icons">
                                                            <div title="Редактирование спикера">
                                                                <EditIcon width="15" height="15"
                                                                          onClick={() => editSpeaker(item.id)}/></div>
                                                            <div title="Удаление спикера">
                                                                <DeleteIcon
                                                                    width="15" height="15"
                                                                    onClick={() => delSpeaker(item.id)}/>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </li>

                                            )}
                                        </Draggable>)
                                })}


                            </ul>
                        )}
                    </Droppable>}


                    {curTab == "room" && <Droppable droppableId={"rooms"}>
                        {(provided) => (
                            <ul class="list-tab" {...provided.droppableProps} ref={provided.innerRef}>

                                {rooms.filter(e => e.number.indexOf(roomFilter) > -1).map(function (item, index) {
                                    return (

                                        <Draggable key={'' + item.id} draggableId={'room_' + item.id} index={index}>
                                            {(provided) => (
                                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                    class="calendar__item">
                                                    <div class="calendar__body-block">
                                                        <span class="input">{item.number}</span>
                                                        <div className="option-icons">
                                                            <div title="Редактирование помещения">
                                                                <EditIcon width="15" height="15"
                                                                          onClick={() => editRoom(item.id)}/></div>
                                                            <div title="Удаление помещения">
                                                                <DeleteIcon
                                                                    width="15" height="15"
                                                                    onClick={() => delRoom(item.id)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                            )}
                                        </Draggable>)
                                })}


                            </ul>
                        )}
                    </Droppable>}


                </div>
            </div>
        </div>
    );
}
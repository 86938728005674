import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import NativeSelect from '@mui/material/NativeSelect';


export default function RoleDialog(props) {
  const [roleId, setRole] = React.useState(0);
  const [confId, setConf] = React.useState(0);
  const [count,setCount ] = React.useState(0);

	let { handleClose, roles_list, conf_list, dialogOpen, old_confId, old_roleId } = props;
    
     React.useEffect(() => {
         
         if (count == 0)
		  {
			setConf(old_confId);
            setRole(old_roleId);
		  }
    
    });
    
    function selectRole(e)
    {
        setCount(count+1);
        setRole(e);
    }
    
    function selectConf(e)
    {
        setCount(count+1);
        setConf(e);
    }
	
	function saveData(){
        
        console.log(roleId,confId);
        
        if (roleId >0 && confId > 0)
        {
        	handleClose({roleId,confId});
			setCount(0);
        }
	}
    
    
	function cancelData() {
		handleClose('');
		setCount(0);
	}
 
  return (

      <Dialog open={dialogOpen}>
	<DialogTitle>{old_confId==0?"Добавить роль":"Редактировать роль"}</DialogTitle>
        <form onSubmit={saveData}>
        <DialogContent>
        <div style={{paddingBottom:10}}>
        <select onChange={(e)=>selectRole(e.target.value)}>
        
        <option value={0} selected={roleId==0}>Выберите роль</option>
        {roles_list.map(function(item,index){
           return (<option value={item.id} selected={roleId==item.id}>{item.name}</option>)
        })}
									
        </select>
        </div>
         <select onChange={(e)=>selectConf(e.target.value)}>
        <option value={0} selected={confId==0}>Выберите конференцию</option>
        {conf_list.map(function(item,index){
           return (<option value={item.id} selected={confId==item.id}>{item.name}</option>)
        })}
									
        </select>         
         
        </DialogContent>
        <DialogActions>
          <Button type="submit">Сохранить</Button>
          <Button onClick={cancelData}>Отмена</Button>
        </DialogActions>
        </form>
      </Dialog>

  );
}
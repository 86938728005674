import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import '../css/style.css';
import {serverUrl} from '../_constants'; 
 

export default function ConfItem(props) {
    
    console.log(props);
    
    let { data, onEdit, onDelete, onClick, onSchedule } = props;
    

  return (
   <li class="conf-list__item">
						<div class="card" onClick={props.onClick}>
							<div class="card__top" onClick = {props.onEdit}>
								<img src={data.image?serverUrl+data.image:''} alt="" class="object-fit is--cover"/>
                                {data.type == 0 && <span class="card__label">оффлайн</span>}
                                {data.type == 1 && <span class="card__label">онлайн</span>}
                                {data.type == 2 && <span class="card__label">гибридный</span>}
							</div>

							<div class="card__body">
								<p style={{marginBottom:10,fontSize:18,fontWeight:500}}>{data.name}</p>
                                <p style={{marginBottom:10,fontSize:14,fontWeight:500}}>{data.is_published==1?'Опубликована':'Не опубликована'}</p>
                                <div class="layout__inf">
                                <ul class="layout__inf-list">
										<li class="layout__inf-item"><a onClick = {props.onEdit} class="card__link"><span>Редактировать</span></a></li>
										<li class="layout__inf-item"><a onClick = {props.onDelete}  class="card__link"><span>Удалить</span></a></li>
									</ul></div>

								<div class="layout__inf">
									
									<p class="layout__inf-text">{data.start_time?data.start_time.substr(0,10):'не выбрано'} - {data.end_time?data.end_time.substr(0,10):'не выбрано'}</p>
	
                                    {!data.building_id && <p class="layout__inf-text">Нет адреса</p>}
                                    {data.building_id && <p class="layout__inf-text">{data.building_id.address}</p>}
                                    
                                    <ul class="layout__inf-blocks">
										<li class="layout__inf-block">
											<div class="layout__inf-media">
												<svg width="24" height="24">
													<use href="./img/sprite.svg#user-icon"></use>
												</svg>
	
												<p class="layout__inf-contains">
													<strong>{data.participants_count?data.participants_count:"0"}</strong> <br/>
													участников
												</p>
											</div>
										</li>
	
										<li class="layout__inf-block">
											<div class="layout__inf-media">
												<svg width="24" height="24">
													<use href="./img/sprite.svg#star-icon"></use>
												</svg>
	
												<p class="layout__inf-contains">
													<strong>{data.sponsors_count?data.sponsors_count:"0"}</strong> <br/>
													спонсоров
												</p>
											</div>
										</li>   
									</ul>
	
									
								</div>
                                

								<a class="card__link" onClick = {props.onSchedule}>
									<span>Расписание</span>
								</a>
							</div>
						</div>
					</li>
  );
}
import React from 'react';
import SlotItem from './SlotItem';
import '../css/style.css';
import '../css/constructor.css';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import MoreIcon from '@material-ui/icons/MoreHoriz';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import addLogo from '../img/add.png';

export default function SectionItem(props) {
    
	const [ isOpen,setOpen] = React.useState(true);
    const [ sectName, setSectName ] = React.useState(''); 
    
    const [anchorEl, setAnchorEl] = React.useState(null);
	 
	  const open = Boolean(anchorEl);
      
      	function openMenu(event){
		
		setAnchorEl(event.currentTarget);
	  };
	  
	  
	  const handleClose = () => {
		setAnchorEl(null);
	  };
   
    
    let { slot, indexSect, themes, rooms,changeThemes, changeRoom, addTheme, sectionNameChange, sectionOnDelete, delSlot,unbindSlot, addSpeaker, delSpeaker, changeSpeakers
	, speakers, sectUp, sectDown, editSectionName, plusDuration,minusDuration,
    changeSpeakerContract, changeSpeakerPartip, saveTime,saveUrl, changeMode, moveSlot, showDeclined } = props;
    
    
    function menuSectUp(){
        
        handleClose();
        sectUp(indexSect);
    }
    
    function menuSectDown(){
        
        handleClose();
        sectDown(indexSect);
    }
    
    function menuSectDelete() {
        
        handleClose();
        sectionOnDelete();
    }
    
    function onNameChange(e){
        
        if (slot.name != 'Другое')
            setSectName(e.target.value);
    }
    
    function onEdit() {
        handleClose();
        editSectionName();
    }
    
    function onSave() {
        
        if (sectName != '')
            sectionNameChange(sectName);
    }
     
    React.useEffect(() => {
        
		if (slot && sectName == '')
         	setSectName(slot.name);
    });
    

  return (
  
    <div class="line-theme" data-open={isOpen}>
        <div class={!isOpen ? "line-constructor title-constructor" : "line-constructor title-constructor open"}>
            <div class="time"></div>
            <div class="move">
            
            </div>
            <div class="name-parent">
                <div class="left-name">
                     {/*<div className={isOpen?"section_arrow-down":"section_arrow-right"} onClick={()=>setOpen(!isOpen)}></div>*/}
                    <div className="collapse-icon" onClick={()=>setOpen(!isOpen)}>
                        {/*<span className={isOpen?"section_arrow-down":"section_arrow-right"}></span>*/}
                        <i className="icon-arrow-up"></i>
                    </div>
                    <span>{slot.name}</span>
                </div>
                <div class="add-theme-button" onClick={()=>addTheme(indexSect)} style={{width:100}}>
                    <img src={addLogo} style={{width:30,height:30,marginRight:5}}/>
                    <span>Добавить тему</span>
                </div>
            </div>
            <div class="options" style={{marginRight:10}}>
               <Menu
								id="fade-menu"
								MenuListProps={{
								  'aria-labelledby': 'fade-button',
								}}
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								TransitionComponent={Fade}
								  anchorOrigin={{
								  vertical: 'top',
								  horizontal: 'right',
								}}
							
							  >
								<MenuItem onClick={menuSectUp}>Переместить вверх</MenuItem>
								<MenuItem onClick={menuSectDown}>Переместить вниз</MenuItem>
                                <MenuItem onClick={onEdit}>Редактировать секцию</MenuItem>
								<MenuItem onClick={menuSectDelete}>Удалить секцию</MenuItem>
							  </Menu>
								<MoreIcon onClick={openMenu}/>
            </div>
        </div>
        <div class="list-theme" style={{maxHeight:isOpen?2000:0}}>
     { slot.slots && slot.slots.length>0 && slot.slots.map(function(item,slotIndex) {
		 
		return (
			<SlotItem item={item} mainIndex = {indexSect} index ={slotIndex} themes = {themes}
			addSpeaker = {addSpeaker}
			delSpeaker = {delSpeaker}
			speakers = {speakers}
			plusDuration = {plusDuration}
			minusDuration = {minusDuration}
			rooms = {rooms}
            changeMode={(e)=>changeMode(e,indexSect,slotIndex)}
			changeThemes = {(e,eOld)=>changeThemes(e,eOld,indexSect,slotIndex)}
			changeRoom = {(a,b)=>changeRoom(a,b,indexSect,slotIndex)}
			changeSpeakers = {(e,i)=>changeSpeakers(e,indexSect,slotIndex,i)}
			changeSpeakerPartip = {(e,i)=>changeSpeakerPartip(e,indexSect,slotIndex,i)}
			changeSpeakerContract = {(e,i)=>changeSpeakerContract(e,indexSect,slotIndex,i)}
            saveTime={saveTime}
            saveUrl={saveUrl}
			moveSlot={moveSlot}
            delSlot={()=>delSlot(indexSect,slotIndex)}
			unbindSlot={()=>unbindSlot(indexSect,slotIndex)}
            showDeclined={showDeclined}
			/>
		)		
	 })}
        
    
    </div>
    </div>
  
  );
}
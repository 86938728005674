import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { userService } from '../_services';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import Select, { SelectChangeEvent } from '@mui/material/Select';

import '../css/style.css';

export default function BldDialog(props) {
    
   const [ loading,setLoad ] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [curSp, setCS] = React.useState(null);
  const [splist, setSplist] = React.useState([]);
   
  const [count,setCount ] = React.useState(0);

	let { handleClose, sp_list,all_list, id, dialogOpen } = props;
    
    React.useEffect(() => {
         
         
         if (count == 0 && dialogOpen)
		  {
            
            setSplist([...all_list]);
            setCount(1);
            setLoad(true);
            getSp();
		  }
    
    });
    
    function getSp() {
        
         let getUrl = 'api/sponsor?conference_id='+id;
        
		userService.getWrapper(getUrl,function(data) 
		   {
               setData([...data.data]);
                setLoad(false);
                   
          },function(error) { setLoad(false);});
        
    }
    
    function addSp() {
        let tmpData = [...data];
        tmpData.push({});
        setData(tmpData);
    }
	
    
    function detachSp(e) {
        
         let post_data = {detach_conference_id:id}
         let getUrl = 'api/sponsor/'+e;
         
         setLoad(true);
        
            userService.patchWrapperBody(getUrl,post_data,function(data) 
		   {
              getSp();
              
                   
          },function(error) { setLoad(false);});
    }
    
    function changeSp(e,index) {
        
        console.log(e,index);
        let oldData = data[index];
        
        if (oldData && oldData.id)
        {
            if (oldData.id != e)
            {
                 let post_data = {attach_conference_id:id}
              let getUrl = 'api/sponsor/'+e;
               setLoad(true);
        
            userService.patchWrapperBody(getUrl,post_data,function(data) 
		   {
              detachSp(oldData.id);
                   
          },function(error) {  setLoad(false);});
                
            }
        }
        else        
        {
            let post_data = {attach_conference_id:id}
              let getUrl = 'api/sponsor/'+e;
               setLoad(true);
        
            userService.patchWrapperBody(getUrl,post_data,function(data) 
		   {
              
              getSp();
                   
          },function(error) { setLoad(false);});
            
        }
        
    }
    
    function delSp(index) {
        //getSp();
         
        let oldData = data[index];
        
        if (oldData && oldData.id)
        {
            detachSp(oldData.id);
        }
        else        
        {
            
            let tmpData = [...data];
            tmpData.splice(index,1);
            setData(tmpData);
        }
        
    }
	
	function cancelData() {
		handleClose('');
        setLoad(false);
		setCount(0);
	}
    
    
  return (

      <Dialog open={dialogOpen}>
	<DialogTitle>Спонсоры</DialogTitle>
        <DialogContent style={{width:500}}>
                        
                                    <div class="form__group-top" style={{marginTop:5,marginBottom:5}}>
                                                        <button type="button" class="form__btn" onClick={addSp}>
                                                            <span>Добавить</span>
                                                        </button>
                                                    </div>
                                        {data.length>0 && data.map((itemData,indexData) => (
                                        <div class="form__group-rows" style={{marginBottom:10}}>
                                                
                                                 <Select
                                                      style={{width:'100%'}}
                                                      labelId="demo-simple-select-label"
                                                      id="demo-simple-select"
                                                      value={itemData.id}
                                                      label="Sp"
                                                      onChange={(e)=>changeSp(e.target.value,indexData)}
                                                    >
                                                    {splist.length>0 && splist.map((item,index)=> (
                                                      <MenuItem value={item.id}>{item.brandname}</MenuItem>
                                                    ))}
                                                      
                                                    </Select>
                                                 <div style={{width:30,marginLeft:10}}><DeleteIcon onClick={()=>delSp(indexData)} width="15" height="15"/></div>
                                                </div>))
                                        }        
          
          
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelData}>Закрыть</Button>
        </DialogActions>
        {loading && <div className="shadow"><div className="shadow_circle"><CircularProgress/></div></div>}
      </Dialog>

  );
}
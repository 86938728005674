import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { userService, scheduleService } from '../_services';
import SectionItem from './SectionItem';
import SlotItem from './SlotItem';
import MoveDialog from './MoveDialog';
import SectionDialog from './SectionDialog';
import SettingsDialog from './SettingsDialog';
import AddDialog from '../_components/AddDialog';
import EditDialog from '../_components/EditDialog';
import CloseIcon from '@material-ui/icons/Close';
import '../css/style.css';
import '../css/constructor.css';
import '../css/responsive.css';
import settingsImg from '../img/settings.png';

import { connect } from "react-redux";
import {serverUrl} from '../_constants'; 
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import DragMenu from './DragMenu';
import { CircularProgress } from '@material-ui/core';

import RightIcon from '@material-ui/icons/ChevronRight';
import LeftIcon from '@material-ui/icons/ChevronLeft';

const add_textes = {'theme':'Добавить тему','speaker':'Добавить спикера','room':'Добавить помещение'};
const edit_textes = {'theme':'Редактировать тему','speaker':'Редактировать спикера','room':'Редактировать помещение'};

const styles = theme => ({
     root: {
    flexGrow:1,
  
  },
  
   
});

function formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        }

function newAroundDays(cDate,minDate,maxDate)
{
      const minDateF = new Date(minDate);
        let maxDateF = new Date(maxDate);
        let curDateF = new Date(cDate);
        
        let aDays = [];
        let minVal = -1;
        let maxVal = 1;
        let oldDateF = new Date(cDate);
        
        if (formatDate(curDateF) == formatDate(minDateF))
        {
            minVal = 0;maxVal = 2;
            oldDateF = minDateF;
        }
             
        
        if (formatDate(curDateF) == formatDate(maxDateF))
        {
            minVal = -2;
            maxVal = 0;
            oldDateF = maxDateF;
        }
            

        for (let i=minVal;i<maxVal+1;i++)
        {
            curDateF = new Date(oldDateF);
            if (i!=0)
                curDateF.setDate(oldDateF.getDate() + i);    
            
            let fd = formatDate(curDateF);
            if (curDateF>=minDateF && curDateF<=maxDateF)
                aDays.push(fd);
            
            
        }
        
        return aDays;
}


class TimeTable extends React.Component {
    
   constructor(props)
   {
        super(props);
        
		
        this.state = { dragMenuOpen:true, 
		themesCounter:-1, speakersCounter:-200, roomsCounter: -300, themeDialogOpen: false, editThemeId:-1,
        selectedDate:null,
        aroundDates: ['2022-05-29','2022-05-30','2022-05-31'],
        sectionInd: -1,
        sectionName: '',
        sectionDialogOpen: false,
        minDate:'2020-01-01',
        maxDate:'2030-12-31',
        fileData: null,
        showDeclined: true,    
        editThemeVal:'',showEditTheme: false, error:'',curDay:'0',
        editType:'',addType:'',oldTheme:'', fileStatus:0}
        
        
   }       
   
   componentDidMount() 
   {
       
	   let { error, allLessons } = this.state;
	   var self = this;
       
       console.log(this.props);
       
       if (this.props.conf_data && this.props.conf_data.type)
           this.setState({format:this.props.conf_data.type});
       else 
           this.setState({format:"hybrid"});
       
       if (this.props.conf_id>0 && this.props.conf_data && this.props.conf_data.start_time && this.props.conf_data.end_time)
           this.getDates(this.props.conf_data.start_time,this.props.conf_data.end_time);
	   
       
	   
   }
   
   clickDay = (day) => {
       
       this.changeDate(day);
   }
   
    dateShift = (day_shift) => {
        
        let { selectedDate, minDate,maxDate } = this.state;
        
        let minDateF = new Date(minDate);
        let maxDateF = new Date(maxDate);
        let curDateF = new Date(selectedDate);
        let newDate = '';
        
        if (day_shift<0 && curDateF>minDateF)
        {
            curDateF.setDate(curDateF.getDate() + day_shift);
            newDate = formatDate(curDateF);
            this.changeDate(newDate);
        }
        
        if (day_shift>0 && curDateF<maxDateF)
        {
            curDateF.setDate(curDateF.getDate() + day_shift);
            newDate = formatDate(curDateF);
            this.changeDate(newDate);
        }
        
    }
   
    changeDate = (e) => {
       
       let cDate =e;
       let self = this;
       console.log("CD",cDate);
       
       let { minDate, maxDate } = this.state;
       
       let aDays = newAroundDays(cDate,minDate,maxDate);
       
       this.setState({selectedDate:cDate,aroundDates:aDays});
        const { allLessons } = this.state;
       scheduleService.getSchedule(this.props.conf_id,cDate);
       
   }
   
   fileUpload = (selectedFile) => {
       
       //this.setState({themeFormData:formdata});
       
           const data_upload = new FormData();
		 data_upload.append('path', selectedFile);
         data_upload.append('type','presentation');
         
         
         const { themeFormId } = this.state;
         
         this.setState({fileStatus:1,fileData:data_upload});
        
       
   }
   
   getDates = (startDate,endDate) =>
   {
        console.log(startDate,endDate);  
        let startD = startDate.substr(0,10);    
        let endD = endDate.substr(0,10);
        
       let aDays = newAroundDays(startD,startD,endD);
        this.setState({selectedDate:startD,minDate:startD,maxDate:endD,aroundDates:aDays});
        
        if (this.props.conf_id>0)
	   {
		   
		   let c_id = this.props.conf_id;
		   
		   this.getSpeakerList(c_id);
		   this.getRoomList(c_id);
           this.getSchedule(c_id,startD);
       }
        
   }
   
   dragMenuClick = () => {
	   this.setState({dragMenuOpen:!this.state.dragMenuOpen})
	   
   }
   
   
    deleteThemeBeforeSchedule = (id,th_name,post_data ) => {
        
        if (th_name)
            post_data.push({name:th_name});
        
        this.delThemeFromList(id,[...post_data]);
        
    }
    
    updateSchedule = (post_data,is_reload=false) => {
        
        let self = this;
        
        let { selectedDate } = this.state;
        
        scheduleService.updateSchedule(this.props.conf_id,post_data,selectedDate,is_reload);
       
    }
   
   
    handleStart = (e, i) => {	
        this.setState({isDragging: true});
		
    };


     handleStop = (e, i) => {	
     
        let self = this;
     
        let { sections,rooms, themes, speakers, slots } = this.props.schedule;
     
        this.setState({isDragging: false});
        
        let post_data = [];
        
        if (e.destination && e.destination.droppableId)
        {
			
			console.log(e.destination.droppableId);	
			console.log(e.draggableId);
			let drag_type=""
			
            let dragId = -1;
			
			let tmp_drag = e.draggableId.split('_');
			if (tmp_drag.length ==2)
			{
				drag_type = tmp_drag[0];
				dragId = parseInt(tmp_drag[1]);
			}
			
            let drop_data = e.destination.droppableId.split('_');
			
			if (drop_data.length<3)
				return 0;
			
            let drop_name = drop_data[0];
            let section_id = drop_data[1];
			let drop_id = drop_data[2];
			let sp_id = -1
			if (drop_name == "speaker")
			{
				sp_id = drop_data[3]
			}
            console.log(drop_name,section_id,drop_id,sp_id);
			
			let slot_data = null;
			
			if (section_id>=0)
				slot_data = {...sections[section_id].slots[drop_id]}
			else
				slot_data = {...slots[drop_id]}
			
            let drag_name = [];
            if (drop_name == "theme")
                drag_name = themes.filter(e=>e.id == dragId);
            
            if (drop_name == "speaker")
                drag_name = speakers.filter(e=>e.id == dragId);
            
            if (drop_name == "room")
                drag_name = rooms.filter(e=>e.id == dragId);
            
            if (drag_type=="theme" && drop_name == "theme" && drag_name.length>0)
            {
                let theme = slot_data.theme;
				let id = slot_data.id;
                let new_data = [{id:id,name:drag_name[0].name}];
                
                this.deleteThemeBeforeSchedule(drag_name[0].id,theme,new_data);
                
            }
            
            console.log(dragId, drop_name, drag_name);
            
            if (drag_type=="speaker" && drop_name == "speaker" && drag_name.length>0)
            {
               
			   let sp_list = [...slot_data.speakers];
			   
			   let is_new = true;
			   
			   for (let k=0;k<sp_list.length;k++)
				   if (sp_list[k].name == drag_name[0].name)
				   {
					   is_new = false;
					   break;
				   }
			   
			   if (is_new)
			   {
                   let spOld = {...slot_data.speakers[sp_id]}
                    let id = slot_data.id; 
                    
                    if (spOld && dragId != spOld.id || !spOld)
                    {
                        if (id>0)
                            post_data= [{id:id,speakers:[{id:dragId}]}];
                       
                       if (spOld && spOld.id && spOld.id>0)  
                          post_data[0].speakers.push({id:spOld.id,action:'unlink'}); 
                      
                    }
                   
               }
			   else
			   {
				   alert('Такой спикер уже есть в слоте');
			   }
            }
            
            if (drag_type=="room" && drop_name == "room" && drag_name.length>0)
            {
                console.log(drag_name);
                
                let room_id = drag_name[0].id;
                
                let id = slot_data.id; 
                
                if (id>0)
                post_data= [{id:id,room:{id:room_id,number:drag_name[0].number}}];
                
                
            }
               if (post_data.length>0)
                    self.updateSchedule(post_data);
    
        }
        
    };
	
	editThemeFromList = (id) => {
		
		let { themes } = this.props.schedule;
		let themeVal = '';
        let thObj = null;
		
		for (let i=0;i<themes.length;i++)
			if (themes[i].id == id)
			{
				themeVal = themes[i].name;	
                thObj = {...themes[i]}
                break;
			}
			
		this.setState({editVal:thObj,editId:id,editDialog:true, editType:"theme",fileData:null,fileStatus:0});
	}
	
	delThemeFromList = (id,post_data = null) => {
        
        let c_id = this.props.conf_id;
        scheduleService.delTheme(c_id,id,post_data);
        
	}
	
	editSpeakerFromList = (id) => {
		
		let { speakers } = this.props.schedule;
		let speakerVal = '';
        let spObj = null;
		
		for (let i=0;i<speakers.length;i++)
			if (speakers[i].id == id)
			{
				speakerVal = speakers[i].name;	
                spObj = {...speakers[i]};
				break;
			}
			
        console.log(spObj);
		this.setState({editVal:spObj,editId:id,editDialog:true,editType:'speaker'});
	}
	
	delSpeakerFromList = (id) => {
		
		scheduleService.delSpeaker(this.props.conf_id,id);
	}	
	
	editRoomFromList = (id) => {
		
		let { rooms } = this.props.schedule;
		let roomVal = '';
        let roomObj = null;
		
        
        
		for (let i=0;i<rooms.length;i++)
			if (rooms[i].id == id)
			{
				roomVal = rooms[i].number;	
                roomObj = {name:rooms[i].number};
				break;
			}
			
		this.setState({editVal:roomObj,editId:id,editDialog:true,editType:'room'});
	}
	
	delRoomFromList = (id) => {
		
        let c_id = this.props.conf_id;
        scheduleService.delRoom(c_id,id);
        
	}	
    
    addRoomDialog = () => {    
        this.setState({addDialog:true,addType:'room'});
    }
    
    addSpeakerDialog = () => {    
        this.setState({addDialog:true,addType:'speaker'});
    }
    
    addThemeDialog = () => {    
        this.setState({addDialog:true,addType:'theme',fileData:null,fileStatus:0});
    }
	
	addDialog = () => {
		
		this.setState({dialogOpen:true});
	}
	
	closeDialog = (data) => {
        
        this.setState({addDialog:false});
        
        if (!data)
            return;
        
        let val = data.name;
        
        const { themes, rooms, speakers, sections } = this.props.schedule;
        const { addType, fileData } = this.state;
        
        let isExists = false;
        var self = this;
        
        if (addType == "speaker" && val)
            for (let i=0;i<speakers.length;i++)
                if (speakers[i].name == val)
                {
                    isExists = true;
                    break;
                }
        
        if (addType == "theme" && val)
            for (let i=0;i<themes.length;i++)
                if (themes[i].name == val)
                {
                    isExists = true;
                    break;
                }
                
        if (addType == "room" && val)
            for (let i=0;i<rooms.length;i++)
                if (rooms[i].number == val)
                {
                    isExists = true;
                    break;
                }
        
        if (val && isExists == false && addType == "theme")
        {
            
            scheduleService.addTheme(this.props.conf_id,{...data},fileData);
            
        }
        
        if (val && isExists == false && addType == "room")
        {
            let json_data = {conference_id:this.props.conf_id,number:val,building_id:this.props.building_id};
        
            scheduleService.addRoom(this.props.conf_id,json_data);
        }
        
        if (val && isExists == false && addType == "speaker")
        {
            let json_data = {name:val};
        
            scheduleService.addSpeaker(this.props.conf_id,json_data);
        }
    	
	}
    
    closeSettingsDialog = (val) => {
        
        console.log(val);
        if (val)
            this.setState({showSettingsDialog:false, showDeclined:val.showDeclined});
        else
            this.setState({showSettingsDialog:false});
    }
    
    closeEditDialog = (val) => {
        
        
        if (val && val.name)
            this.closeSaveDialog(val);
        else
            this.setState({editId:-1,editDialog:false,editVal:null,editType:'',themeFormData:null});
    }
	
	closeSaveDialog = (data) => {
		
		let { fileData, editId, editVal, editType } = this.state;
        
        var self = this;
        let val = data.name;
        
        if (val && editType == "speaker")
		{
             self.setState({editId:-1,editDialog:false,editVal:''});   
            scheduleService.editSpeaker(self.props.conf_id,editId,{...data});
		}	
        
		if (val && editType == "theme")
		{
            self.setState({editId:-1,editDialog:false,editVal:''});   
            scheduleService.editTheme(self.props.conf_id,editId,{...data},fileData);
        }	
        
        if (val && editType == "room")
		{
            self.setState({editId:-1,editDialog:false,editVal:''});   
            scheduleService.editRoom(self.props.conf_id,editId,val);
		}	
        
	}
    
	changeSectionName = (e,index) => {
		
		scheduleService.changeSectionName(this.props.conf_id,e,index);

	}
	
	unbindSlot = (sectIndex, slotIndex) => {
		
		let { sections, themes, slots } = this.props.schedule;
		let tId = sections[sectIndex].slots[slotIndex].id;
		let  post_all = [{id:tId,section:{action:'unlink'}}];
        this.updateSchedule(post_all);
	}
	
	delSlot = (sectIndex, slotIndex) => {
	    
		
		let { sections, themes, slots } = this.props.schedule;
		let theme =  null;
		let tId = null;
		
		if (sectIndex>=0)
		{
			tId = sections[sectIndex].slots[slotIndex].id;
		}
		else
		{
			tId = slots[slotIndex].id;
		}
        
        if (tId)
        {
            let  post_all = [{id:tId,action:'delete'}];
            this.updateSchedule(post_all);
        }
        else
        {
            alert('del slot');
        }
		
	}
    
    moveSect =(sections) => {
        
        let conf_id = this.props.conf_id;
        let post_data = [];
        var self = this;
        
            for (let i=0;i<sections.length;i++)
            {
                if (sections[i].slots && sections[i].slots.length>0)
                {
                    let l_id = sections[i].slots[0].id;
                    post_data.push({id:l_id,section:{id:sections[i].id,order_id:i+1}});
                }
            }
            
            this.setState({sections:[]});
            
            this.updateSchedule(post_data);
            
    }
    
	
	sectUp = (sectIndex) => {
		
		let { sections } = this.props.schedule;
        
		if (sectIndex>0)
		{
            let tmpElem = {...sections[sectIndex]}
			sections.splice(sectIndex,1);
			sections.splice(sectIndex-1,0,tmpElem);
			this.moveSect(sections);
		}
	}
	
	sectDown = (sectIndex) => {
		
		let { sections } = this.props.schedule;
        
		if (sectIndex<sections.length-1)
		{
            let tmpElem = {...sections[sectIndex]}
			sections.splice(sectIndex,1);
			sections.splice(sectIndex+1,0,tmpElem);
			this.moveSect(sections);
		}
	}
	
	getThemeList = (id) => {
		
		scheduleService.getThemeList(id);
	}
	
	getSpeakerList = (id) => {
		
		scheduleService.getSpeakerList(id);
	}
	
	getRoomList = (id) => {
		
        scheduleService.getRoomList(id);
		
	}
    
    getSchedule = (id, sd) => {
        
        var self = this;
        const { selectedDate } = this.state;
        
        if (!sd)
            sd = selectedDate;
        
        scheduleService.getSchedule(id,sd,function(data) {},function(error){});
    }
	
	addThemeToList = (val) => {
		
		let c_id = this.props.conf_id;
        scheduleService.addTheme(c_id,val);
	}
	
	addTheme = (index) => {
		
        console.log(index);
		let { selectedDate, format } = this.state;
        let { sections } = this.props.schedule;
        
        if (!sections[index].slots)
            sections[index].slots = [];
        
		//sections[index].slots.push({tag:'time_0',time:'00.00',duration:0,theme:'',room:'',speakers:[{name:'',status:0}]})
        let sectId = sections[index].id;
        let post_data = [{name:'',section:{id:sectId},date:selectedDate}]
        
        post_data[0]['type'] = format;
            
        
        this.updateSchedule(post_data,true);
        
	}
	
	delSpeaker = (index,slotIndex, sectionIndex) => {
		
        
		let { sections } = this.props.schedule;
        let spId =sections[sectionIndex].slots[slotIndex].speakers[index].id;
		sections[sectionIndex].slots[slotIndex].speakers = sections[sectionIndex].slots[slotIndex].speakers.filter((e,i)=>index != i)
        if (sections[sectionIndex].slots[slotIndex].speakers.length == 0)
        {
            sections[sectionIndex].slots[slotIndex].speakers=[{name:'',status:"0",payment_status:"0"}];
        }
        
        if (spId)    
        {
            let lessonId = sections[sectionIndex].slots[slotIndex].id;
            let post_data = [{id:lessonId,speakers:[{'action':'unlink',id:spId}]}];
            this.updateSchedule(post_data);
        }
        else
        {
		  //this.setState({sections:sections});
        }
	}
	
	
	changeSpeakerPartip = (e,sectionIndex,slotIndex,index) => {
        
        /*
    let sp_statuses = {
            'участие запрошено':'invited',
        'договор не нужен:':'approved',
        'участие отклонено':'declined';
        'contract_sent';
        'contract_signed';
    'no_contract';
    'paid';
     'sent';
    'waiting_for_payment';
    }
    */
        
        let { sections, slots } = this.props.schedule;
        
        if (e != '1')
        {
            let id = null;
			let slot_data = null;
			
			if (sectionIndex>=0)
				slot_data = {...sections[sectionIndex].slots[slotIndex]}
			else
				slot_data =  {...slots[slotIndex]}
			
			
			id = slot_data.speakers[index].id;
            let post_data = [{id:slot_data.id,speakers:[{id:id,status:e}]}];
            this.updateSchedule(post_data);
        }
		
	}
	
	addSpeaker = (index,sectionIndex) => {
		
		let { sections, slots } = this.props.schedule;
        
		if (sectionIndex>=0)
			sections[sectionIndex].slots[index].speakers.push({name:'',status:"0"})
		else 
			slots[index].speakers.push({name:'',status:"0"})
        scheduleService.setSlots([...slots]);
        
	}
	
	deleteSectionDialog = (ind) => {
		
		let res_dialog = window.confirm("Удалить секцию?");
		if (res_dialog == true)
			this.delSection(ind)
			
	}
	
	closeMoveSection = (val) => {
		
		let { slot_id, sect_id } = this.state;
		
		if (val!= '' && val>0)
		{
			let  post_all = [{id:slot_id,section:{id:val}}];
			this.updateSchedule(post_all);
		}
		
		this.setState({sect_id:0,slot_id:0,moveDialogOpen:false});
	}
	
	moveSlotToSection = (sectIndex,id) => {
		
		let { sections } = this.props.schedule;
		
		let sect_id = 0;
		
		if (sectIndex>=0)
			sect_id = sections[sectIndex].id;
		
		this.setState({sect_id:sect_id,slot_id:id,moveDialogOpen:true});
	}
	
	addSection = () => {
		
		this.setState({sectionDialogOpen: true,sectionInd:-1,sectionName:''});
	}
	
	addSlot = () => {
		
		//this.setState({sectionDialogOpen: true,sectionInd:-1,sectionName:''});
		   
		let { selectedDate, format } = this.state;
        let post_data = [{name:'',date:selectedDate}]
        
        post_data[0]['type'] = format;
            
        this.updateSchedule(post_data,true);
	}

    delSection = (ind) => {
        
        scheduleService.delSection(this.props.conf_id,ind);
        
    }
    
    changeMode  = (e,sectIndex,slotIndex) => {
        
        scheduleService.setSlotMode(this.props.conf_id,sectIndex,slotIndex,this.state.format);
    }
	
	changeSpeakers = (e,sectIndex,slotIndex,spIndex) => {
		
		let {  speakersCounter } =  this.state;
        let { sections, slots, speakers } = this.props.schedule;
		let isNew = true;
		let newId = -1;
		
		if (e)
		{
			for (let i=0;i<speakers.length;i++)
			{
				if (speakers[i].name == e)
				{
					isNew = false;
					newId = speakers[i].id;
				}
			}	
            
			
			let oldSp = null;
			
			if (sectIndex>=0)
				oldSp = {...sections[sectIndex].slots[slotIndex].speakers[spIndex]};
			else
				oldSp = {...slots[slotIndex].speakers[spIndex]};
			//sections[sectIndex].slots[slotIndex].speakers[spIndex] = {...oldSp,id:newId,name:e};
			
            let oldId = 0;
            if (oldSp && oldSp.id)
                oldId = oldSp.id;
            
            let lessonId = null;
			
			if (sectIndex>=0)
				lessonId = sections[sectIndex].slots[slotIndex].id;
			else
				lessonId = slots[slotIndex].id;
			
			
            let post_data = [{id:lessonId,speakers:[]}];
            
            if (oldId>0)
                post_data[0].speakers.push({'action':'unlink',id:oldId});
                
            
			if (newId<1)
            {
                post_data[0].speakers.push({name:e});
                this.updateSchedule(post_data);
                    
				//this.setState({speakers:speakers,speakersCounter:newId});
            }
			else
            {
                post_data[0].speakers.push({id:newId});
                this.updateSchedule(post_data);
                
				this.setState({speakers:speakers});
            }
		}
		else
		{
            
				let oldSp = null;
				
				if (sectIndex>=0)
					oldSp = {...sections[sectIndex].slots[slotIndex].speakers[spIndex]};
				else
					oldSp = {...slots[slotIndex].speakers[spIndex]};
				
				//sections[sectIndex].slots[slotIndex].speakers[spIndex] = {...oldSp,name:''}
                let oldId = 0;
                if (oldSp && oldSp.id)
                    oldId = oldSp.id;
                
                if (oldId>0)
                {
                    
					let lessonId = null;
			
					if (sectIndex>=0)
						lessonId = sections[sectIndex].slots[slotIndex].id;
					else
						lessonId = slots[slotIndex].id;
					
					
                    let post_data = [{id:lessonId,speakers:[{id:oldId,'action':'unlink'}]}];
                    this.updateSchedule(post_data);
                }
       }
	}
    
    closeEditSection = (e) => {
        
        const { sectionInd } = this.state;
        
        
        this.setState({sectionDialogOpen: false,sectionInd:-1,sectionName:''});
        if (e && e.name)
        {
            this.changeSectionName(e.name,sectionInd);
        }
    }
    
    
    editSectionName = (index) => {
        
        const { sections } = this.props.schedule;
        let name = sections[index].name;
        this.setState({sectionDialogOpen: true,sectionInd:index,sectionName:name});
    }
    
    formatServerTime = (tS,tE) => {
        
        if (tS && tE)
        {
            let tS_arr = tS.split(' ');
            let time_start_arr = tS_arr[1].split(':');
            
            let time1 = new Date(tS);
            let time2 = new Date(tE);
            let duration = Math.floor((time2.getTime()-time1.getTime())/60000);
            
            return {time:time_start_arr[0]+':'+time_start_arr[1],duration:duration};
        }
        
        return { time:null,duration:null};
    }
    
    formatTime = (timeSrc) => {
        
        let resStr = timeSrc.getFullYear()+"-"+(1+timeSrc.getMonth())+"-"+timeSrc.getDate()+" "+timeSrc.getHours()+":"+timeSrc.getMinutes();
        console.log(resStr);
        return resStr;
    }
    
    saveUrl = (id,url,itemSect) => {
    
        
       scheduleService.saveUrl(this.props.conf_id,id,url,itemSect); 
    }
	
	plusDuration = (sectIndex,index) => {
		
		let duration = 0;
		let { sections, slots } = this.props.schedule;
		let id=-1;
		let post_all = {}
		
		if (sectIndex>=0)
		{
			duration = sections[sectIndex].slots[index].duration;
			id = sections[sectIndex].slots[index].id;
		}
		else
		{
			duration = slots[index].duration;
			id = slots[index].id;
		}
		
		duration +=1;
		  
		if (duration < 1) duration = 1;
        post_all.duration = duration;  
		
		if (id>0) post_all.id = id;
                
        post_all = [{...post_all}];
        this.updateSchedule(post_all);  	
		
	}
	
	minusDuration = (sectIndex,index) => {
		
		let duration = 0;
		let { sections, slots } = this.props.schedule;
		let id=-1;
		let post_all = {}
		
		if (sectIndex>=0)
		{
			duration = sections[sectIndex].slots[index].duration;
			id = sections[sectIndex].slots[index].id;
		}
		else
		{
			duration = slots[index].duration;
			id = slots[index].id;
		}
		
		duration -=1;
		  
		if (duration < 1) duration = 1;
        post_all.duration = duration;  
		
		if (id>0) post_all.id = id;
                
        post_all = [{...post_all}];
        this.updateSchedule(post_all);  	
		
	}
    
    saveTime = (id,time,duration,itemSect) => {
        
        console.log(id,time,duration);
        console.log(itemSect);
        const { selectedDate } = this.state;
        
        let self = this;
        
        if (time || duration)
        {
            let post_all = {};
            
                if (time)
                {
                    let time_arr = time.split(':');
                    if (time_arr.length>1)
                        post_all.start_time = time_arr[0]+":"+time_arr[1];
                }
               
                let dateStart = new Date(selectedDate);
                
                post_all.date = selectedDate;
                
                if (duration < 1) duration = 1;
                
                if (duration && duration>0)
                    post_all.duration = duration;
                
     
           if (id) post_all.id = id;
                
                post_all = [{...post_all}];
                
                this.updateSchedule(post_all);        
        }
    }
    
	changeThemes = (e,oldVal,index,slotIndex) => {
		
        let { sections, slots, themes } = this.props.schedule;
        let id = 0;
		
		if (index>=0)
			id = sections[index].slots[slotIndex].id;
		else
			id = slots[slotIndex].id;
        
        let post_data = [];
        
        if (e)
            post_data = [{id:id,name:e}];
        else
            post_data = [{id:id,name:''}];
        
         if (oldVal)
               post_data.push({name:oldVal});
        
        if (e)
        {
            
            let fThemes = themes.filter(e1=>e1.name == e);
           
            if (fThemes.length>0)
            {
                console.log(fThemes);
                let thId = fThemes[0].id;
                this.deleteThemeBeforeSchedule(thId,'',post_data);
            }
            else
            {
               this.updateSchedule(post_data); 
            }
        }
        else
        {
          
           this.updateSchedule(post_data);
        }
        
	}
	
	changeRoom = (e,item,index,slotIndex) => {
        
        console.log(item);
		
		let {  roomsCounter } =  this.state;
        let { sections, slots, rooms } = this.props.schedule;
        
        let new_sections = [...sections];
        
		let isNewRoom = true;
		let roomId = roomsCounter-1;
        
        let oldId = 0;
        
		if (e)
		{
			for (let i=0;i<rooms.length;i++)
			{
				if (rooms[i].number == e)
				{
					isNewRoom = false;
					roomId = rooms[i].id;
				}
			}	
				
			if (roomId<0) 
				rooms.push({id:roomId,number:e});
			
			
                let lessonId = null;
				
				if (index>=0)
					lessonId = sections[index].slots[slotIndex].id;
				else
					lessonId = slots[slotIndex].id;
				
                let post_data = [{id:lessonId,room:{}}];
                if (roomId>0)
                    post_data[0].room['id'] = roomId;
                else 
                    post_data[0].room['number'] = e;
                
                this.updateSchedule(post_data);
            
		}
		else
		{
			if (new_sections[index].slots[slotIndex] && new_sections[index].slots[slotIndex].room)
			{
				
                let lessonId = sections[index].slots[slotIndex].id;
                let post_data = [{id:lessonId,room:{'action':'unlink',id:item.room_id}}];
                this.updateSchedule(post_data);
                
			}	
		}
	}

    render()
    {
    
     const { classes } = this.props;
     const { selectedDate,aroundDates,minDate,maxDate,isLoading, dragMenuOpen, fileStatus,showDeclined,
     isDragging, addType,addDialog, editDialog, editType, editVal, sect_id, moveDialogOpen } = this.state;
     
     let { sections,slots, rooms, themes, speakers } = this.props.schedule;
     
     
     //console.log(JSON.stringify(this.props.schedule));
     console.log(this.props.schedule);
     
     var self = this;
    
	//let dragOverfl = isDragging?'hidden':'auto';
    //style={{overflowY:dragOverfl}}
	if (this.props.conf_id>0)
	return (
    
     <DragDropContext onDragEnd={this.handleStop} onDragStart={this.handleStart}>
                <div

                    className={dragMenuOpen?"contstructor-page open-tabs":"contstructor-page"}
                >
                    <section class="constructor-row">
                        <div class="constructor-table">
                          <Breadcrumbs separator="›" aria-label="breadcrumb" style={{height:30}}>
                            <Link underline="hover" color="inherit" href="/">
                              Конференции
                            </Link>
                          <Typography color="text.primary">Конструктор</Typography>
                            <Typography color="text.primary">{this.props.conf_data.name}</Typography>
                              </Breadcrumbs>
                            <div class="head-page">
                                <div class="head-title-row">        
                                <h1 class="title-page">
                                    Конструктор программы
                                </h1>
                                <div onClick={()=>this.setState({showSettingsDialog:true})}>
                                <img src={settingsImg} style={{marginLeft: 15}}/>

                                </div>
                                </div>
                                
                                <div class="tab-switcher" style={{marginBottom: 10, marginTop: 10}}>
                                    <ul class="tabs-member">
                                        {aroundDates.map((item, index) => (
                                            <li class={item == selectedDate ? 'active' : ''}
                                                onClick={() => self.clickDay(item)}>{item}</li>))}
                                    </ul>
                                </div>


                                <div class="filter-date">
                                    <button class="filter-arrows"><LeftIcon onClick={() => self.dateShift(-1)}/>
                                    </button>
                                    <input type="date" class="form__input"
                                           id="start" name="trip-start"
                                           value={selectedDate}
                                           min={minDate}
                                           max={maxDate} onChange={(e) => self.changeDate(e.target.value)}/>

                                    <button class="filter-arrows"><RightIcon onClick={() => self.dateShift(1)}/>
                                    </button>
                                </div>
                                <button class="block-dropdwon__btn">
                                Сохранить
                                </button>
                            </div>
                            <div class="table">
                                <div class="head-contstructor">
                                    <div class="time">
                                        <div class="col-name">Время</div>
                                    </div>
                                    <div class="duration">
                                        <div class="col-name">Длительность</div>
                                    </div>
                                    <div class="theme">
                                        <div class="col-name">Тема</div>

                                    </div>
                                    <div class="speaker">
                                        <div class="col-name">Спикер</div>

                                    </div>
                                    <div class="room">
                                        <div class="col-name">Помещение</div>

                                    </div>
                                </div>
                                <div class="body-contstructor custom-scroll" data-simplebar="init">
								
									{slots.length > 0 && slots.map(function (item, slotIndex) {
										return (
											<SlotItem item={item} mainIndex = {-1} index ={slotIndex} themes = {themes}
											addSpeaker = {self.addSpeaker}
											delSpeaker = {self.delSpeaker}
											speakers={speakers.map(e => e.name)}
											rooms={rooms.map(e => e.number)}themes={themes.filter(e => !e.selected).map(e => e.name)}
											changeMode={(e)=>self.changeMode(e,-1,slotIndex)}
											changeThemes = {(e,eOld)=>self.changeThemes(e,eOld,-1,slotIndex)}
											changeRoom = {(a,b)=>self.changeRoom(a,b,-1,slotIndex)}
											changeSpeakers = {(e,i)=>self.changeSpeakers(e,-1,slotIndex,i)}
											changeSpeakerPartip = {(e,i)=>self.changeSpeakerPartip(e,-1,slotIndex,i)}
											changeSpeakerContract = {(e,i)=>self.changeSpeakerContract(e,-1,slotIndex,i)}
											saveTime={self.saveTime}
											saveUrl={self.saveUrl}
											plusDuration={self.plusDuration}
											minusDuration={self.minusDuration}
											moveSlot = {self.moveSlotToSection}
                                            delSlot={()=>self.delSlot(-1,slotIndex)}	/>
											)
										})}
									

                                    {sections.length > 0 && sections.map(function (itemSect, indexSect) {
                                        return (
                                            <SectionItem
                                                showDeclined={showDeclined}
                                                slot={itemSect} indexSect={indexSect}
                                                themes={themes.filter(e => !e.selected).map(e => e.name)}
                                                speakers={speakers.map(e => e.name)}
                                                rooms={rooms.map(e => e.number)}
                                                editSectionName={() => self.editSectionName(indexSect)}
												plusDuration={self.plusDuration}
												minusDuration={self.minusDuration}
                                                saveTime={(a, b, c) => self.saveTime(a, b, c, itemSect)}
                                                saveUrl={(a, b) => self.saveUrl(a, b, itemSect)}
                                                sectionNameChange={(e) => self.changeSectionName(e, indexSect)}
                                                sectionOnDelete={() => self.deleteSectionDialog(indexSect)}
                                                changeThemes={self.changeThemes}
                                                changeMode={self.changeMode}
                                                changeSpeakers={self.changeSpeakers}
                                                changeSpeakerPartip={self.changeSpeakerPartip}
                                                changeRoom={self.changeRoom}
                                                addTheme={self.addTheme}
                                                addSpeaker={self.addSpeaker}
                                                delSpeaker={self.delSpeaker}
                                                delSlot={self.delSlot}
												unbindSlot={self.unbindSlot}
												moveSlot = {self.moveSlotToSection}
                                                sectUp={self.sectUp}
                                                sectDown={self.sectDown}
                                            />
                                        )
                                    })}


                                </div>
                            </div>
                        </div>
                        <DragMenu speakers={speakers}
                                  rooms={rooms}
                                  themes={themes}
                                  isOpen={dragMenuOpen}
                                  onDragClick={() => self.dragMenuClick()}
                                  addSection={() => self.addSection()}
								  addSlot={() => self.addSlot()}
                                  addThemeDialog={self.addThemeDialog}
                                  addSpeakerDialog={self.addSpeakerDialog}
                                  addRoomDialog={self.addRoomDialog}
                                  editTheme={self.editThemeFromList}
                                  delTheme={self.delThemeFromList}
                                  editSpeaker={self.editSpeakerFromList}
                                  delSpeaker={self.delSpeakerFromList}
                                  editRoom={self.editRoomFromList}
                                  delRoom={self.delRoomFromList}
                        />

                    </section>

                </div>

    <SectionDialog handleClose={self.closeEditSection}  
    dName= {this.state.sectionName}
     dialogOpen={this.state.sectionDialogOpen}/>
	 <MoveDialog handleClose={self.closeMoveSection}  
    sect_id = {sect_id}
	sect_list = {sections}
     dialogOpen={moveDialogOpen}/>
	<AddDialog handleClose={self.closeDialog}  
	 label={addType?add_textes[addType]:''} 
     title={addType?add_textes[addType]:''}
      onFileUpload={self.fileUpload}
      fileStatus={fileStatus}
      dialogType={addType}
	 dialogOpen={addDialog}/>
                
	<EditDialog handleClose={self.closeEditDialog}  
	 label={editType?edit_textes[editType]:''} 
     title={editType?edit_textes[editType]:''}
	 oldVal={editVal}
      onFileUpload={self.fileUpload}
      fileStatus={fileStatus}
     dialogType={editType}
	dialogOpen={editDialog}/>
    <SettingsDialog handleClose={self.closeSettingsDialog}  
    showDecl ={showDeclined}
    dialogOpen={this.state.showSettingsDialog}/>
   
    {isLoading && <div className="shadow"><div className="shadow_circle"><CircularProgress/></div></div>}    
   </DragDropContext>
   
    
  );
  else
	  return(<div style={{fontSize:20,paddingTop: 20,paddingLeft: 20}}>Нет выбранной конференции</div>);
}

}

function mapStateToProps(state) {
    const {app, user, authentication, schedule} = state;
    
    return {
        user,
        app,
        authentication,
        schedule,
    };
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(TimeTable));

import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { confService } from '../_services';


export default function MemberDialog(props) {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [city,setCity ] = React.useState('');
  const [count,setCount ] = React.useState(0);
  

	let { handleClose, label, title, dialogOpen, dName,dPhone,dMail, dCity, id } = props;
    
    console.log("ID",id);
    
    React.useEffect(() => {
         
         if (count == 0)
		  {
			setPhone(dPhone);
            setName(dName);
            setCity(dCity);
            setEmail(''); 
		  }
    
    });
	
	function saveData(e){
        
        e.preventDefault();
        
        if (id==0)
        {
            if (name != '' && email != '')
            {
                // && city!='' && phone!=''
                
                if (confService.validateEmail(email))
                {
                 handleClose({name,email,phone,city});
                 setCount(0);
                }
                else
                {
                    alert('Введите корректный email');
                }
            }
            else
            {
                alert('Заполните имя и email');
            }
        }
        else if (name != '' && id>0)
        {
            
            handleClose({name,phone,city});
                setCount(0);
        }
            
	}
	
	function cancelData() {
		handleClose('');
		setCount(0);
	}
 
  return (

      <Dialog open={dialogOpen}>
	<DialogTitle>{title}</DialogTitle>
    <form onSubmit={saveData}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Имя"
            fullWidth
            variant="standard"
			value={name}
            required
			onChange={(e)=>{setName(e.target.value);setCount(count+1)}}
          />
          {id==0 && <TextField
            margin="dense"
            id="email"
            label="Email"
            fullWidth
            variant="standard"
			value={email}
            required
			onChange={(e)=>{setEmail(e.target.value);setCount(count+1)}}
          />}
          <TextField
            margin="dense"
            id="phone"
            label="Телефон"
            fullWidth
            variant="standard"
			value={phone}
            onChange={(e)=>{setPhone(e.target.value);setCount(count+1)}}
          />
          <TextField
            margin="dense"
            id="city"
            label="Город"
            fullWidth
            variant="standard"
			value={city}
			onChange={(e)=>{setCity(e.target.value);setCount(count+1)}}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Сохранить</Button>
          <Button onClick={cancelData}>Отмена</Button>
        </DialogActions>
        </form>
      </Dialog>

  );
}

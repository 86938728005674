import React from 'react';
import {service} from "../_services";
import {setErrors} from "../_helpers";
import Dialog from "@material-ui/core/Dialog";
import StylizedDialogTitle from "../_components/StylizedDialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { SendButton, TextFieldCell, Snack} from "../_components";
import DialogActions from "@material-ui/core/DialogActions";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import {SelectCell} from "../_components/SelectCell";

export default class CompanyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {name:props.isEdit?props.editItem.name:''},
            name:'',
            commonError: '',
            id:props.isEdit?props.editItem.id:0,
            error: {}
        };
    }

    handleChange = (e) => {
        
        this.setState({
            item: {
                ...this.state.item,
                [e.target.name]: e.target.value
            },
           
        });
    };
    
     onCloseSnack = () => {
        this.setState({alertShow: false});
    };

    onSave = () => {
        let data = this.state.item.name;
        //data.updateFields = ['name', 'description', 'part', 'icon_base64'];
        
        /*
        
        if (data.length<3)
        {
            this.setState({alertShow:true,snackText:'Название меньше 3 символов'});
        }
        else
        {
        
        let result = data.match(/^[a-zA-Zа-яА-ЯёЁ0-9]+$/);
        if (result)
        {
            this.props.onSave(data);
        }
       else
               this.setState({alertShow:true,snackText:'Некорректные символы'});
                    
        }
       */
       this.props.onSave(data);
        
        //this.props.onSave(data.name);
        
    };

    onCancel = () => () => {
        this.props.onClose();
    };

    render() {
        const { commonError} = this.state;

        return (
            <Dialog open maxWidth="md" onClose={this.onCancel}>
                <StylizedDialogTitle
                    dialogTitle={this.state.id==0?'Добавление новой компании':'Редактирование компании'}>
                    {commonError !== '' &&
                    <Typography variant="body1" color="error">{this.state.commonError}</Typography>}
                </StylizedDialogTitle>
                <DialogContent>
                    
                        <TextFieldCell id="newcat-text-name"
                                       label="Название"
                                       name="name"
                                       type="text"
                                       value={this.state.item.name}
                                       error={this.state.error.name}
                                       onChange={this.handleChange}/>
                        
                    
                </DialogContent>
                <DialogActions  className="dialog-actions">
                    <CustomizedButton
                        id="newcat-button-cancel"
                        onClick={this.onCancel()}
                        title="Отмена"
                    />
                    <SendButton
                        id="newcat-button-save"
                        onClick={this.onSave}
                        title="Сохранить"
                        prim
                    />
                </DialogActions>
                <Snack open={this.state.alertShow} variant="error" text={this.state.snackText} onClose={this.onCloseSnack}/>
            </Dialog>
        );
    }
}
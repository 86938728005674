export const scheduleConstants = {
    
    SET_ROOMS: 'SC_SETROOMS',
    SET_THEMES: 'SC_SETTHEMES',
    SET_SPEAKERS: 'SC_SETSPEAKERS',
    SET_SCHEDULE: 'SC_SETSCHEDULE',
	SET_SLOTS: 'SC_SETSLOTS',
    SET_LESSONS:'SC_SETLESSONS',
    SET_THEMES_ID: 'SC_SETTHEMES_ID',
    SET_LOADIND: 'SC_SETLOADING',
    SET_DATE: 'SC_SETDATE',
    IS_NEED_UPDATE: 'SC_NEEDUPDATE',
    SET_FILE_STATUS: 'SC_SETFILESTATUS',
    SET_FILE_DATA: 'SC_SETFILEDATA',
    
};

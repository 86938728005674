import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import classNames from 'classnames'
import Dropzone from 'react-dropzone'

export default function EditDialog(props) {
  

	let { handleClose, label, title, oldVal, dialogOpen, dialogType, fileStatus, onFileUpload } = props;
	
	const [count,setCount] = React.useState(0);
	const [value, setValue] = React.useState(oldVal?oldVal.name:'');
     
   const [description, setDesc] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [city, setCity] = React.useState('');
  const [comm, setComm] = React.useState('');
  
	
	  React.useEffect(() => {
          
		  if (count == 0)
		  {
              
			setValue(oldVal?oldVal.name:'');
            
            if (dialogType == "speaker")
            {
                
                console.log(oldVal);
                setDesc(oldVal.description?oldVal.description:'');
                setCity(oldVal.city?oldVal.city:'');    
                setPhone(oldVal.phone?oldVal.phone:'');
                setComm(oldVal.comment?oldVal.comment:'');
                setEmail(oldVal.email?oldVal.email:'');
                
            }
            else if (dialogType == "theme")
                setDesc(oldVal.description?oldVal.description:'');
                
		  }
    
  });

	
	function editVal(e) {
	
		setValue(e.target.value);
        setCount(count+1);		
	}
	
	
	function saveTheme(){
		
        
		if (value != '')
		{
			
            let tmpObj = {name:value};
            
            if (dialogType == "speaker")
            {
                tmpObj.description = description;
                tmpObj.phone = phone;
                tmpObj.city = city;
                tmpObj.comment = comm;
                tmpObj.email = email;
                
            }
            else if (dialogType == "theme")
                tmpObj.description = description;
                
            
            handleClose(tmpObj);
			setCount(0);
			setValue('');
            setValue('');
            setPhone('');
            setDesc('');
            setEmail('');
            setComm('');
            setCity('');
		}
	}
	
	function cancelTheme() {
		
		handleClose(null);
		setCount(0);
		setValue('');
        setValue('');
            setPhone('');
            setDesc('');
            setEmail('');
            setComm('');
            setCity('');
	
	}
    
    function onDrop(acceptedFiles, rejectedFiles){
	   
			if (acceptedFiles.length>0)
			{
                onFileUpload(acceptedFiles[0]);
            }
	}
 
  return (

      <Dialog open={dialogOpen}>
	<DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={label}
            fullWidth
            variant="standard"
			value={value}
			onChange={(e)=>editVal(e)}
          />
          {(dialogType == "speaker" || dialogType == "theme") && 
              <TextField
                autoFocus
                margin="dense"
                id="desc"
                label="Описание"
                fullWidth
                variant="standard"
                value={description}
                onChange={(e)=>{setDesc(e.target.value);setCount(count+1)}}
              />}
            {dialogType == "speaker" &&
              <TextField
                margin="dense"
                id="email"
                label="Email"
                fullWidth
                variant="standard"
                value={email}
                onChange={(e)=>{setEmail(e.target.value);setCount(count+1)}}
                />}
              {dialogType == "speaker" &&
              <TextField
                margin="dense"
                id="phone"
                label="Телефон"
                fullWidth
                variant="standard"
                value={phone}
                onChange={(e)=>{setPhone(e.target.value);setCount(count+1)}}
              />}
                {dialogType == "speaker" &&
                <TextField
                margin="dense"
                id="phone"
                label="Город"
                fullWidth
                variant="standard"
                value={city}
                onChange={(e)=>{setCity(e.target.value);setCount(count+1)}}
                />}
                  {dialogType == "speaker" &&
                    <TextField
                    margin="dense"
                    id="comm"
                    label="Комментарий"
                    fullWidth
                    variant="standard"
                    value={comm}
                    onChange={(e)=>{setComm(e.target.value);setCount(count+1)}}
                  />
          }
          
          {dialogType == "theme" &&
                <div style={{marginTop:10}}>
                <div style={{paddingBottom:5}}>Файл презентации</div>
                    <Dropzone onDrop={onDrop} class="visually--hidden">
				{({getRootProps, getInputProps, isDragActive}) => {
				return (
					<div style={{textAlign: 'center', verticalAlign: 'center', width:400,height:50,background:'#36B2F1',borderRadius:5}}
					  {...getRootProps()}
					  className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
					>
					  <input {...getInputProps()} />
					 
                       { fileStatus == 0 && (isDragActive ?
                          <p style={{fontSize: 12 }}>Загрузить файл</p> :
						  <p style={{fontSize: 12,padding:10,borderRadius:5}}>Перетащите файл сюда или нажмите для загрузки файла</p>)
					   }
                      { fileStatus == 1 && <p style={{fontSize: 12,padding:10}}>Файл загружен</p> } 
                      
					</div>
				  )
				}}
                                                </Dropzone>
                                                </div>
                }
        </DialogContent>
        <DialogActions>
          <Button onClick={saveTheme}>Сохранить</Button>
          <Button onClick={cancelTheme}>Отмена</Button>
        </DialogActions>
      </Dialog>

  );
}

import { authHeader } from '../_helpers';
import axios from 'axios';

import {serverUrl} from '../_constants'; 


export const userService = {
    
    getAsync,
    getWrapper,
    putWrapper,
    postWrapper,
    patchWrapper,
    patchWrapperBody,
	delWrapper,
    awaitPost,
    uploadFile,
};

function getAsync(urls,cbF) {
        
        let appToken = getCookie('token');
        axios.defaults.headers.get['Authorization'] = 'Bearer '+appToken; 
    
       axios.defaults.headers.get['Content-Type'] = "application/json"; 
       axios.defaults.withCredentials = true;
       
       let aUrls = [];
       
       for (let i=0;i<urls.length;i++)
           aUrls.push(axios.get(serverUrl+urls[i]));
       
       Promise.all(aUrls).then(function(values) {
                  
                  cbF(values);
       });
        
  
    }


function uploadFile(url,data,onSuccess,onError)
{
    let appToken = getCookie('token');
	axios.defaults.headers.post['Authorization'] = 'Bearer '+appToken; 
       
		 
    axios.post(serverUrl+url, data, {
        
      })
      .then(function (response){ onSuccess(response)  }).catch(function (error) { onError(error)  });
}

function getWrapper(url,onSuccess,onError)
{
    let appToken = getCookie('token');
	axios.defaults.headers.get['Authorization'] = 'Bearer '+appToken; 
    //alert(appToken);    
    
       axios.defaults.headers.get['Content-Type'] = "application/json"; 
       axios.defaults.withCredentials = true;
    	
        axios.get(serverUrl+url).then(function (response) 
									{ onSuccess(response)  }).catch(function (error) { onError(error)  });
}

function putWrapper(url,data,onSuccess,onError)
{
    let appToken = getCookie('token');
    axios.defaults.headers.put['X-Api-Key'] = appToken; 
    
       axios.defaults.headers.put['Content-Type'] = "application/json"; 
       axios.defaults.withCredentials = true;
       
        axios.put(serverUrl+url,data).then(function (response) 
									{ onSuccess(response)  }).catch(function (error) { onError(error)  });
}

function patchWrapper(url,onSuccess,onError)
{
    let appToken = getCookie('token');
    axios.defaults.headers.patch['Authorization'] = 'Bearer '+appToken; 
	
       axios.defaults.headers.patch['Content-Type'] = "application/json"; 
       axios.defaults.withCredentials = true;
    	
        axios.patch(serverUrl+url).then(function (response) 
									{ onSuccess(response)  }).catch(function (error) { onError(error)  });
}

function patchWrapperBody(url,data,onSuccess,onError)
{
    let appToken = getCookie('token');
    axios.defaults.headers.patch['Authorization'] = 'Bearer '+appToken; 
	
       axios.defaults.headers.patch['Content-Type'] = "application/json"; 
       axios.defaults.withCredentials = true;
    	
        axios.patch(serverUrl+url,data).then(function (response) 
									{ onSuccess(response)  }).catch(function (error) { onError(error)  });
}

function postWrapper(url,data,onSuccess,onError)
{
    let appToken = getCookie('token');
    axios.defaults.headers.post['Authorization'] = 'Bearer '+appToken; 
	
       axios.defaults.headers.post['Content-Type'] = "application/json"; 
       axios.defaults.withCredentials = true;
    	
        axios.post(serverUrl+url,data).then(function (response) 
									{ onSuccess(response)  }).catch(function (error) { onError(error)  });
}

function awaitPost(url)
{
    let appToken = getCookie('token');
    axios.defaults.headers.post['Authorization'] = 'Bearer '+appToken; 
	
       axios.defaults.headers.post['Content-Type'] = "application/json"; 
       axios.defaults.withCredentials = true;
       
       let data = axios.post(serverUrl+url); 
       console.log(data);
       
        return data;
}


function delWrapper(url,onSuccess,onError)
{
    let appToken = getCookie('token');
    axios.defaults.headers.delete['Authorization'] = 'Bearer '+appToken; 
	
       axios.defaults.withCredentials = true;
    	
        axios.delete(serverUrl+url).then(function (response) 
									{ onSuccess(response)  }).catch(function (error) { onError(error)  });
}

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}





import React from 'react';

export function getDate(dt) {
    if (dt) {
        let date = new Date(dt);
        if (date != null) {
            dt = '';
            if (date.getDate() < 10) {
                dt += '0';
            }
            dt += date.getDate() + ".";
            if ((date.getMonth() + 1) < 10) {
                dt += '0';
            }
            dt += (date.getMonth() + 1) + "." + date.getFullYear();
        }
    } else {
        dt = '';
    }

    return dt;
}
export function getTime(dt) {
    let ret = '';
    if (dt !== null && dt !== "") {
        let date = new Date(dt);
        ret = (date.getHours() < 10 ? '0' : '') + date.getHours() + ':'
            + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    }
    return ret;
}

export function getDateTime(dt) {
    let ret = '';
    if (dt !== null && dt !== "") {
        ret = getDate(dt) + ' ' + getTime(dt);
    }
    return ret;
}

export function parseDate(str) {
    let ymd = str.split('-');
    return new Date(ymd[0], ymd[1] - 1, ymd[2]);
}

export function getToken() {
    return localStorage.getItem('user_token');
}

export function getUser() {
    return localStorage.getItem('email');
}

export const getBase64 = (file) => {
    return new Promise(resolve => {
        let reader = new FileReader();
        // Read file content on file loaded event
        reader.onload = function (event) {
            let s = event.target.result;
            resolve(s.substring(s.indexOf(',') + 1));
        };

        // Convert data to base64
        reader.readAsDataURL(file);

    });
};

export const stringTruncate = (str, limit) => {
    if (str.length > limit) {
        return str.substr(0, limit-1).trimRight() + '…';
    }
    return str;
};

export const nl2br = str => {
    if (!str) {
        return '';
    }
    return str.split('\n').map((item, key) => {
        return <span key={key}>{item}<br/></span>
    })
};

import  { userService }  from './user.service';
import { store } from '../_helpers';
import {appActions, userActions, scheduleActions} from "../_actions";

export const confService = {
    
    confInfo,
    confList,
    confListStudents,
    ticketsInfo,
    validateEmail,
    validatePassword,
    confListParticipants,
};



function validateEmail(email) 
    {
        //var re = /\S+@\S+\.\S+/;
        //^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$
        var re = /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*/;
        return re.test(email);
    }
    
function validatePhone(val) 
    {
        var re = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
        return re.test(val);
    }

function validatePassword(pwd)
{
    var re = /^[a-zA-Z0-9-]+$/;
        return re.test(pwd);
    
}

function confInfo(id,cbD,cbE) {
        
        userService.getWrapper('api/conference/'+id,function(data) 
		   {
               cbD(data);
                   
          },function(error) {  if (error!='')   { cbE(error);  }
		
		   });
}

function confList(for_student,cbD,cbE) {
    
    
    let url =  'api/conference';
    if (for_student)
        url =url+'/all';
    
    userService.getWrapper(url,function(data) 
		   {
               cbD(data);
                   
          },function(error) {  if (error!='')   { cbE(error);  }
		
		   });
}

function checkUserTicketExists(data,user_id,ticket_id) {
    
    for (let i=0;i<data.length;i++)
        if (data[i].user_id == user_id && data[i].ticket_id == ticket_id)
            return i;
    
    return -1;    
}

function confListStudents(cbD,cbE) {
    
        let confs_data = [];
        
        confList(false,function(data)
        {
            console.log(data.data);
            
            let urls = [];
            let confs_data = [];
            let partip_data = [];
            
            if (data.data.length>0)
            {
                confs_data = [...data.data];
                
                let tmp_partips = [];
                
                for (let i=0;i<data.data.length;i++)
                    urls.push('api/conference/'+data.data[i].id+'/students');
                
                userService.getAsync(urls,function(data){ 
                
                console.log(data);
                
                for (let i=0;i<data.length;i++)
                {
                    tmp_partips = [];
                    
                    console.log(data[i].data);
                    
                    for (let j=0;j<data[i].data.length;j++)
                    {
                        
                        let posId = checkUserTicketExists([...tmp_partips],data[i].data[j].user_id,data[i].data[j].ticket_type_id);
                        if (posId>=0) 
                            tmp_partips[posId].count +=1;
                        else
                        {
                            tmp_partips.push({conf_id:confs_data[i].id,
                                              conf_name:confs_data[i].name,
                                             user_id:data[i].data[j].user.id,
                                              user_name:data[i].data[j].user.name,
                                              ticket_id:data[i].data[j].ticket_type.id,
                                              ticket_name:data[i].data[j].ticket_type.name,
                                              updated_at:data[i].data[j].updated_at,
                                              count:1});
                        }                            
                    }
                    
                    partip_data = partip_data.concat(tmp_partips)
                    
                }        
                    
                cbD(partip_data);
                
                },
                function(err) { console.log(err)}
                );
            }
            else
            {
                cbD([]);
            }
    
        },
        function(err)
        {
            //cbE(err);
            store.dispatch(userActions.set_error(err));
        })           
}

function confListParticipants(forStudent=false) {
    
        let confs_data = [];
        
        
        confList(forStudent,function(data)
        {
            let urls = [];
            let confs_data = [];
            let partip_data = [];
            
            if (data.data.length>0)
            {
                confs_data = [...data.data];
                store.dispatch(userActions.set_conf_list(confs_data));  
                let tmp_partips = [];
                
                for (let i=0;i<data.data.length;i++)
                    urls.push('api/conference/'+data.data[i].id);
                
                userService.getAsync(urls,function(data){ 
                
                for (let i=0;i<data.length;i++)
                {
                    confs_data[i].participants_count=data[i].data.participants_count;
                    confs_data[i].sponsors_count=data[i].data.sponsors_count;
                    
                }        
                
                console.log(confs_data);
                    
                const state = store.getState();

                store.dispatch(userActions.set_conf_list(confs_data));  
                
                },
                function(err) { store.dispatch(userActions.set_error(err)); }
                );
            }
            else
            {
                store.dispatch(userActions.set_conf_list([]));
            }
    
        },
        function(err)
        {
            store.dispatch(userActions.set_error(err));
        })           
}


function ticketsInfo(id,cbD,cbE) {
        userService.getWrapper('api/ticket-type?conference_id='+id,function(data) 
		   {
               cbD(data);
                   
          },function(error) {  if (error!='')   { cbE(error);  }
		
		   });
}      
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { userService } from '../_services';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import Select, { SelectChangeEvent } from '@mui/material/Select';

import '../css/style.css';

export default function BldDialog(props) {
    
   const [ loading,setLoad ] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [curSp, setCS] = React.useState(null);
  const [splist, setSplist] = React.useState([]);
   
  const [count,setCount ] = React.useState(0);

	let { handleClose, tickets, dialogOpen } = props;
    
    React.useEffect(() => {
         
    
    });
    
    
	function cancelData() {
		handleClose(-1);
        setCount(0);
	}
    
    function selectTicket(id) {
        
        handleClose(id);
        setCount(0);
    }
    
    console.log(tickets);
    
  return (

      <Dialog open={dialogOpen}>
	<DialogTitle>Виды билетов</DialogTitle>
        <DialogContent style={{width:500}}>
        <div>
                    {tickets.length>0 && tickets.map((item,index)=> (
                                                     <button class="btn-border" style={{marginBottom:10,width: 400}} onClick={()=>selectTicket(item.id)}>
                                                        <span>
                                                            {item.name}, {item.cost?item.cost:'0.00'} руб
                                                        </span>
                                                    </button>
                                                    ))}
                                                 
        </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelData}>Закрыть</Button>
        </DialogActions>
        {loading && <div className="shadow"><div className="shadow_circle"><CircularProgress/></div></div>}
      </Dialog>

  );
}
import React from 'react';
import PropTypes from 'prop-types';
import { history } from '../_helpers';
import { userService, confService } from '../_services';
import CompanyForm from "./CompanyForm";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import { SendButton, TextFieldCell, Snack} from "../_components";
import {DeleteButton} from "../_components/buttons/DeleteButton";
import {EditButton} from "../_components/buttons/EditButton";
import '../css/style.css';
import { CircularProgress } from '@material-ui/core';
import {DeleteDialog} from "../_components";
import { storeData, setStore } from '../store.js';
import { connect } from "react-redux";
import { userActions, appActions } from "../_actions";
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import i18next from 'i18next';
import {store} from '../_helpers'; 

import BldDialog from './BldDialog';
import SponsorDialog from './SponsorDialog';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import {serverUrl} from '../_constants'; 
 

const TEXT_NAME_NOT_EMPTY = "Имя файла не должно быть пустым";
const TEXT_LOAD_FILE = "Загрузите файл";
const TITLE_LOAD_FILE = "Загрузить файл";
const TEXT_DROP_FOR_LOAD = "Перетащите файл сюда или нажмите для загрузки файла";
const TEXT_FILE_LOADED = "Файл загружен";
const TEXT_SELECT_DIR = "Выберите каталог!";    

const err_text = "Не удалось выполнить запрос";

class CreateConfPage extends React.Component {


	constructor(props)
	{
		super(props);
        
		 this.state = { value: 'one', 
                        isOpen:false,
                        loadingTickets: false,
                        streamUrl:'',
                        sponsors:[],
                        sp_list:[],
                        tickets:[],
                        tickets_tmp:[],
                        name:"",
                        id:0,
                        theme:'ИТ, Технологии, экономика',
                        date:'3', 
                        adress:'4',
                        part_count:'0', 
                        spons_count:'0',
                        start_date:null,
                        end_date:null,
                        blds:[],
                        selectedBldId:0,
                        bldAddr:'',
                        bldDialog: false,
                        bldId:0,
                        spId:0,
                        spDialog:false,
                        dName:'',
                        dAddress:'',
                        dEmail:'',
                        format:"hybrid",
                        contacts:'',                        
                        wifi:'',
                        faq:'',
                        comment:'',
                        is_published:0,
                        }
        //store.subscribe(this.storeChange)
	}
    
    
     storeChange = (e) => {
        
        const { classes, dispatch } = this.props;
        
        if (store.getState().user.isLoading == false)
        {
            if (store.getState().user.error == '')
            {
                dispatch(appActions.setpage('conf_list'));
            }
        }
     }    

    componentDidMount() {
        
        console.log(this.props);        
        
        let { selectedConf} = this.props.user;
        
        if (!(selectedConf && selectedConf.id))
        {
            this.props.dispatch(appActions.setpage("new_conf"));
        }
        
        if (selectedConf && selectedConf.id)
        {
            
            this.setState({name:selectedConf.name,
                          format:selectedConf.type,
                          comment:selectedConf.description,
                          streamUrl:selectedConf.navigation_description,
                          id:selectedConf.id,
                          faq:selectedConf.faq,
                          wifi:selectedConf.wifi,
                          contacts:selectedConf.contacts,
                          is_published:selectedConf.is_published,
                          image:selectedConf.image,
                          start_time:selectedConf.start_time?selectedConf.start_time.split(' ')[0]:null,
                          end_time:selectedConf.end_time?selectedConf.end_time.split(' ')[0]:null,
                          selectedBldId:selectedConf.building_id});
                          
                          
            this.getSponsors(selectedConf.id)              
            this.getPartip(selectedConf.id)              
            this.getTickets(selectedConf.id);
            this.getBuildings(selectedConf.building_id);     
            this.getConfInfo(selectedConf.id);
        }
        else 
            this.getBuildings();     
        this.getSponsors(0);
            
    }
    
    getConfInfo = (id,is_save = false) => {
        
        let self = this;
        const { classes, dispatch } = this.props;
        
         confService.confInfo(id,function(data) 
		   {
               if (data.status == 200)
               {
                   
                   self.setState({
                       name:data.data.name,
                          format:data.data.type,
                          comment:data.data.description,
                          streamUrl:data.data.navigation_description,
                          image:data.data.image?data.data.image.replace('public','storage'):null,
                          building_id:data.data.building_id,
                          start_time:data.data.start_time?data.data.start_time.split(' ')[0]:null,
                          end_time:data.data.end_time?data.data.end_time.split(' ')[0]:null,
                          selectedBldId:data.data.building_id,
                          wifi:data.data.wifi,
                          faq:data.data.faq,
                          contacts:data.data.contacts,
                          is_published:data.data.is_published,
                          
                          
                       });
                      
                    if (is_save)
                        dispatch(userActions.set_conf({...data.data}));    
                       
               }
                   
          },function(error) { 
                dispatch(userActions.set_error(err_text));    
           });
    }
    
    editSponsors = () => {
        
        const { id } = this.state;
        
        this.setState({spId:id,spDialog:true});
    }
    
    resetConf = () => {
        this.setState({name:'',start_time:null,end_time:null,selectedBldId:null,wifi:'',comment:'',faq:'',contacts:''});
    }
    
    
    
    onDrop = (acceptedFiles, rejectedFiles) => {
	   
			console.log(acceptedFiles);
			
			if (acceptedFiles.length>0)
			{
			
            //this.setState({ selectedFile: acceptedFiles[0],loadedProgress: 0, uploadState:0});
			
			this.uploadFile(acceptedFiles[0]);
		}
	}
    
    uploadFile(selectedFile)
	{
        let self = this;
        const { classes, dispatch } = this.props;
        const data_upload = new FormData();
		 data_upload.append('image', selectedFile);
         userService.uploadFile('api/conference/'+this.state.id+'/upload',data_upload,function(data) 
               {
                   console.log(data);
                   if (data && data.data && data.data.image) self.setState({image:data.data.image});
                    else
                       dispatch(userActions.set_error(err_text));    
                       
              },function(error) { console.log(error);  
              
                dispatch(userActions.set_error(err_text));    
              }
            
               );
       
	}
    
    saveTicket = (index) => {
        
            const { id, tickets_tmp } = this.state;
            const { classes, dispatch } = this.props;
           
            let tmp_t = {...tickets_tmp[index]};
            
            if (tmp_t.name != '')
            {
            
            let ticketId =  tmp_t.id;
            
            //let post_data = {conference_id: id,...tmp_t};
            let post_data = {conference_id: id,name:tmp_t.name};
            
            if (tmp_t.cost>0)
                post_data.cost = tmp_t.cost;
            else
                post_data.cost = null;
            
             var self = this;
             this.setState({loadingTickets:true});
             
             
            if (ticketId>0)
            {
                
                userService.patchWrapperBody('api/ticket-type/'+ticketId,post_data,function(data) 
               {
                   self.getTickets(self.state.id);
                       
              },function(error) {  if (error!='')   { self.setState({error:error,loadingTickets:false}); 
                    dispatch(userActions.set_error(err_text));    
              }
            
               });
            }
            else
            {
                userService.postWrapper('api/ticket-type',post_data,function(data) 
                       {
                           self.getTickets(self.state.id);
                               
                      },function(error) {  if (error!='')   { 
                      
                      dispatch(userActions.set_error(err_text));    
                      }
                    
                       });
            }
        }
    }
    
    delTicket = (index) => {
        
            const { id, tickets_tmp } = this.state;
            const { classes, dispatch } = this.props;
            let tmp_t = {...tickets_tmp[index]};
            let ticketId =  tmp_t.id;
            var self = this;
            
             
            if (ticketId>0)
            {
                this.setState({loadingTickets:true});    
             
               userService.delWrapper('api/ticket-type/'+ticketId,function(data) 
                {
                   self.getTickets(self.state.id);
                       
                },function(error) {  if (error!='')   { 
                
                dispatch(userActions.set_error(error));    

                }
            
               });
            }
            else
            {
                tickets_tmp.splice(index,1);
                this.setState({tickets_tmp:[...tickets_tmp]});
            }
        
    }
    
    editTicket = (indexT,val, type) => {
        
        if (val != null)
        {
            const { tickets_tmp } = this.state;
            
            if (indexT>=0)
            {
                let tmp_obj = {...tickets_tmp[indexT]};
                if (type == "name") tmp_obj['name'] = val; 
                if (type == "cost") tmp_obj['cost'] = val;
                
                tickets_tmp[indexT] = {...tmp_obj};
                this.setState({tickets_tmp:tickets_tmp});
            }
            
        }
    }
    
    
    addTicket = () => {
        
        const { tickets_tmp } = this.state;
        
        tickets_tmp.push({id:0,name:'',cost:''});
        this.setState({tickets_tmp:tickets_tmp});
    }
    
    getTickets = (id) => {
        
        var self = this;
        const { classes, dispatch } = this.props;
        
        if (id>0)
        {
        this.setState({loadingTickets:true});
        
        
		userService.getWrapper('api/ticket-type?conference_id='+id,function(data) 
		   {
               console.log(data);
               if (data.data)
               {
                   let tmp_data = [...data.data];
                   tmp_data = tmp_data.map(function(item,index){ return {...item,cost:item.cost?item.cost:0}});
                   self.setState({tickets_tmp:tmp_data,loadingTickets:false});
               }
                else
                    self.setState({tickets_tmp:[],loadingTickets:false});
                   
          },function(error) {  if (error!='')   { 
          dispatch(userActions.set_error(error));    
          }
		
		   });
        }
        
    }
    
    getPartip = (id) => {
        
        let self = this;
        const { classes, dispatch } = this.props;
        
        userService.getWrapper('api/conference/'+id+'/students',function(data) 
		   {
               console.log(data);
               if (data.data)
                   self.setState({partip:[...data.data],part_count:data.data.length});
                else
                    self.setState({part_count:0});
                   
          },function(error) {  if (error!='')   { 
          dispatch(userActions.set_error(error));    
          }
		
		   });
        
    }
    
    getSponsors = (id) => {
        
        var self = this;
        
        let getUrl = 'api/sponsor';
        if (id>0) getUrl += '?conference_id='+id;
        
        
		userService.getWrapper(getUrl,function(data) 
		   {
               
               if (id>0)
               {
               if (data.data)
                   self.setState({sponsors:[...data.data],spons_count:data.data.length+''});
                else
                    self.setState({sponsors:[]});
               }
               else
               {
                    if (data.data)
                   self.setState({sp_list:[...data.data]});
                else
                    self.setState({sp_list:[]});
               }
                   
          },function(error) {  if (error!='')   { self.setState({error:error}); alert(error); }
		
		   });
        
        
    }
    
   setBld = (id) => {

        let addr = '';
        const { blds } = this.state;
        
        for (let i=0;i<blds.length;i++)
            if (blds[i].id == id)
                addr = blds[i].address;

        this.setState({selectedBldId:id,bldAddr:addr});
   }       
    
    getBuildings = (id=0) => {
        
        var self = this;
        
		userService.getWrapper('api/building',function(data) 
		   {
               console.log(data);
               if (data.data)
               {
                   if (id>0)
                   {
                       let addr = '';
                       for (let i=0;i<data.data.length;i++)
                        if (data.data[i].id == id)
                       {
                           addr = data.data[i].address;
                           break
                       }
                       self.setState({blds:[...data.data],bldAddr:addr,selectedBldId:id});
                   }
                   else
                    self.setState({blds:[...data.data]});
               }
                   
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
		
		   });
    }
   
    saveConf = (is_publish=0) => {
        
        
          const { classes, dispatch } = this.props;
        const { name,theme,date, adress,part_count, spons_count,comment,start_time,end_time,selectedBldId, faq,wifi,contacts, format } = this.state;
        
        let self =this;
        console.log(this.state);
        
        //if (name && theme  && date && adress  && part_count  && spons_count )
        let selectedBldOk = true;
        
        if (format != "online" && !selectedBldId) 
            selectedBldOk = false;
        
            
        if (name && start_time && end_time && selectedBldOk)
        {
            let endDate = new Date(end_time);
            let startDate = new Date(start_time);
            
            if (endDate<startDate)
            {
                dispatch(userActions.set_error("Дата окончания не должна быть раньше даты начала","warning"));    
            }
            else
            {
                let post_obj = {name:name,start_time:start_time+"  00:00:01",end_time:end_time+"  23:59:59",
                description:comment,faq:faq,wifi:wifi,contacts:contacts,type:format}
                
                if (selectedBldId)
                   post_obj.building_id = selectedBldId;
                
                if (is_publish>=0)
                    post_obj.is_published = is_publish;

                 userService.patchWrapperBody('api/conference/'+this.state.id,post_obj,function(data){ 
                        let tmp_str = 'Изменения сохранены';
                    dispatch(userActions.set_error(tmp_str,"success"));    
                
                        if (is_publish>=0)
                            self.setState({is_published:is_publish});
                    
                        self.getConfInfo(self.state.id,true);
                
                },function(error) {console.log(error)});
            }
        }
        else 
        {
            
            let tmp_str = 'Заполните поля - название, даты';
            
            if (format != "online" && !selectedBldId) 
                tmp_str+=', выберите площадку';
                        
            dispatch(userActions.set_error(tmp_str,"warning"));    
        }
    }
    
    addConf = (is_publish=0) => {
        
        let self = this;
         const { classes, dispatch } = this.props;
        const { name,theme,date, format, adress,part_count, spons_count,comment,start_time,end_time,selectedBldId, faq,wifi,contacts } = this.state;
        
        console.log(this.state);
        
        let selectedBldOk = true;
        
        if (format != "online" && !selectedBldId) 
            selectedBldOk = false;
        
        if (name && start_time && end_time && selectedBldOk)
        {
            let endDate = new Date(end_time);
            let startDate = new Date(start_time);
            console.log(startDate,endDate);
            
            if (endDate<startDate)
            {
                dispatch(userActions.set_error("Дата окончания не должна быть раньше даты начала","warning"));    
            }
            else
            {
                
               let post_obj = {name:name,start_time:start_time+"  00:00:01",end_time:end_time+"  23:59:59",is_published:is_publish,description:comment,faq:faq,wifi:wifi,contacts:contacts,type:format}
               
               if (selectedBldId)
                   post_obj.building_id = selectedBldId;
         
            userService.postWrapper('api/conference',post_obj,function(data){ 
            
                console.log(data);
                if (data.data && data.data>0)
                {
                    self.setState({id:data.data,is_published:is_publish});
                    self.getConfInfo(self.state.id,true);
                }
                else
                {
                        let tmp_str = 'Изменения сохранены';
                dispatch(userActions.set_error(tmp_str,"success"));    
                self.getConfInfo(self.state.id,true);
                }
            
            },function(error) { 
            dispatch(userActions.set_error(error));    
            });
            }
            //dispatch(userActions.add_conf(post_obj));    
        }
        else 
        {
            let tmp_str = 'Заполните поля - название, даты';
            
            if (format != "online" && !selectedBldId) 
                tmp_str +=", выберите площадку";
            
            dispatch(userActions.set_error(tmp_str,"warning"));    
        }
        
    }
    
    onPublic = (n) => {
        
        const { id } = this.state;
        
        
        if (id>0)
            this.saveConf(n);
        else
            this.addConf(n);
    }
   
    onAdd = () => {
        
        this.addConf();
    }
   
     onSave =  () => {
        this.saveConf(-1);
      
    };
    
    handleChange = (event) => {
        
    
    if (event.target.name == "name")
        this.setState({name:event.target.value})
    
    if (event.target.name == "theme")
        this.setState({theme:event.target.value})
    
    if (event.target.name == "comment")
        this.setState({comment:event.target.value})
    
    if (event.target.name == "contacts")
        this.setState({contacts:event.target.value})
    
    if (event.target.name == "faq")
        this.setState({faq:event.target.value})
    
    if (event.target.name == "wifi")
        this.setState({wifi:event.target.value})
    
    if (event.target.name == "part_count")
        this.setState({part_count:event.target.value})
    
    if (event.target.name == "spons_count")
        this.setState({spons_count:event.target.value})
  };


    changeDate = (index,event) => {
        
        console.log(event.target.value);
        
        if (index == 0)
            this.setState({start_time:event.target.value});
        else 
            this.setState({end_time:event.target.value});
    }
    
    
    
    handleChangeAdress = (index,col,event)=> {

        /*
        let { blds } = this.state;
        let tmp_item = adresses[index];
        tmp_item[col] = event.target.value;
        adresses[index] = {...tmp_item}
        this.setState({adresses:adresses});
        */
    }       
    
    deleteAddr = (id) => {
        
        let self = this;
        const { selectedBldId} = this.state;
        if (selectedBldId>0)
        userService.delWrapper('api/building/'+selectedBldId,function(data) 
               {
                   console.log(data);
                   if (data.status == 200) self.getBuildings();
                       
              },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }});
    }
    
    editAddr = (id) => {
        
        const { blds } = this.state;
        let bldAddr = '';
        let bldName = '';
        let bldEmail = '';
        for (let i=0;i<blds.length;i++)
            if (blds[i].id == id)
            {
                bldAddr = blds[i].address;
                bldName  = blds[i].name;
                bldEmail = blds[i].email;
            }
        
        this.setState({bldId:id,dAddress:bldAddr,dName:bldName, dEmail:bldEmail,bldDialog:true});
    }
    
    addAddr = () => {
          this.setState({bldId:0,dAddress:'',dName:'', dEmail:'',bldDialog:true});
    }
    
    closeSpDialog = () => {
    
        this.setState({spId:0,spDialog:false});
    }
    
    closeBldDialog = (val) => {
        
        let self = this;
        const { bldId } = this.state;
        console.log(val);
        
        let post_data = {};
        
        if (val.address) post_data.address = val.address;
        if (val.name) post_data.name = val.name;
        if (val.email) post_data.email = val.email;
        
        if (post_data && post_data.name)
        {
            
            if (bldId>0)
            {
                userService.patchWrapperBody('api/building/'+bldId,post_data,function(data) 
               {
                   console.log(data);
                   if (data.status == 200) self.getBuildings();
                       
              },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
            
               })
            }
            else
            {
                userService.postWrapper('api/building',post_data,function(data) 
               {
                   console.log(data);
                   
                   if (data.status == 200) self.getBuildings();
                   
                       
              },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
            
               })
                
            }                
            
            
            this.setState({bldDialog:false});
        }
        else
        {
            this.setState({bldDialog:false});
        }
    }

    render() {
	
		let self = this;
        const { id,bldAddr, sponsors, sp_list,spId, spDialog, image, streamUrl, loadingTickets,tickets_tmp, bldDialog,bldId, dName, dEmail, dAddress, selectedBldId,
        start_time,end_time,value, blds,format, part_count,spons_count, name,theme, comment,building_id, contacts, wifi, faq, is_published } = this.state;
     const { classes } = this.props;
     let { selectedConf, isConfSelected} = this.props.user;
     
        
        return (
     <div class="layout__wrap">
     
                        {(isConfSelected && !selectedConf) && <label for="text1" class="form__label">Нет выбранной конференции</label>}
                        {(!isConfSelected || selectedConf) && <div class="layout__cols">
                            <div class="layout__block">
								<div class="form">
                                    <div class="form__col form__col-small">
											<div class="form__group">
												<span class="form__label">Изображение</span>

												<div class="file">
                                                
                                                {id>0 && <Dropzone onDrop={this.onDrop} class="visually--hidden">
				{({getRootProps, getInputProps, isDragActive}) => {
				return (
					<div style={{textAlign: 'center', verticalAlign: 'center', width:250,height:250,background:'#eee',borderRadius:5}}
					  {...getRootProps()}
					  className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
					>
					  <input {...getInputProps()} />
					 
                      { image && 
                      <img src={image?serverUrl+image:''} alt="" class="object-fit is--cover"/>
                      }
                       {
						isDragActive ?
						  <p style={{fontSize: 12, position:'absolute',top:110,left:15}}>Загрузить файл</p> :
						  <p style={{fontSize: 12, position:'absolute',top:110,left:15,width:220,background:'#eee',padding:10,borderRadius:5}}>Перетащите файл сюда или нажмите для загрузки файла</p>
					  }
					</div>
				  )
				}}
                                                </Dropzone>}
                {!id>0 && <div style={{textAlign: 'center', verticalAlign: 'center', width:250,height:250,backgroundColor:'#eee',borderRadius:5}}><span>Сохраните конференцию чтобы добавить лого</span></div>}
                                                
												</div>
											</div>
                                            <div class="form__group">
												<label for="area1" class="form__label">Контакты организатора</label>

												<input id="area1" class="form__input" placeholder="Текст" 
                                                name="contacts"
                                                onChange={this.handleChange}
                                                value={contacts}/>
											</div>

											<div class="form__group">
												<label for="area1" class="form__label">Описание конференции</label>

												<textarea id="area1" class="form__input" placeholder="Текст" 
                                                name="comment"
                                                onChange={this.handleChange}
                                                value={comment}></textarea>
											</div>
                                            <div class="form__group">
												<label for="area1" class="form__label">Вопросы и ответы</label>

												<textarea id="area1" class="form__input" placeholder="Текст" 
                                                name="faq"
                                                onChange={this.handleChange}
                                                value={faq}></textarea>
											</div>
                                            <div class="form__group">
												<label for="area1" class="form__label">Wi-fi на площадке</label>

												<input id="area1" class="form__input" placeholder="Текст" 
                                                name="wifi"
                                                onChange={this.handleChange}
                                                value={wifi}/>
											</div>
									</div>
                                    <div class="form__col">
											<div class="form__group">
												<label for="text1" class="form__label">Название конференции</label>
												<input id="text1" type="text" class="form__input" 
                                                name="name"
                                                value={name}
                                                onChange={this.handleChange}
                                                placeholder="Название конференции" />
											</div>
                                            
                                            <div class="form__group">
												<span class="form__label">Формат мероприятия</span>

												<div class="radio-buttons">
													<div class="radio-button" onClick={()=>self.setState({format:"online"})}>
														<span id="r1" name="category"  
                                                        class={format=="online"?"radio-button__checked":"radio-button__part"}>Онлайн</span>
													</div>
                                                    <div class="radio-button" onClick={()=>self.setState({format:"hybrid"})}>
														<span id="r2" name="category"  
                                                        class={format=="hybrid"?"radio-button__checked":"radio-button__part"}>Гибрид</span>
													</div>
                                                    <div class="radio-button" onClick={()=>self.setState({format:"offline"})}>
														<span id="r3" name="category"  
                                                        class={format=="offline"?"radio-button__checked":"radio-button__part"}>Оффлайн</span>
													</div>

												</div>
											</div>



											<div class="form__group">
												<div class="form__group-top">
													<span class="form__label">Даты проведения</span>
													
												</div>

												<div class="form__group-body">
													<div class="form__group-rows">
														<div class="date-wrap form__group-row">
															<label for="start">Дата начала</label>
															<input type="date" class="form__input" id="start" 
                                                            value = {start_time}
                                                            name="trip-start" onChange={(e)=>this.changeDate(0,e)}/>
														</div>

														<div class="date-wrap form__group-row">
															<label for="date">Дата окончания</label>
															<input type="date" 
                                                            value = {end_time}
                                                            class="form__input" id="start" name="trip-start" 
                                                            onChange={(e)=>this.changeDate(1,e)}/>
														</div>
													</div>
												</div>
											</div>

											<div class="form__group">
												<div class="form__group-rows">
													<div class="form__group-row">
														<label for="text2" class="form__label">Количество участников: {part_count}</label>
														
													</div>

													
                                                    {id>0 && <div class="form__group-top">
                                                    <span class="form__label">Спонсоры</span>
                                                    <div style={{width:30}}>
                                                    <EditButton 
                                                    
                                                    onClick={self.editSponsors} width="15" height="15"/></div>
                                                    </div>}
												</div>
											</div>

											

											{format !="online" && <div class="form__group">
                                                <div class="form__group-top">
													<span class="form__label">Адрес проведения</span>
													<button type="button" class="form__btn" onClick={this.addAddr}>
														<svg width="29" height="29">
															<use href="./img/sprite.svg#add-icon"></use>
														</svg>
														<span>Добавить</span>
													</button>
												</div>
                                                
												<div class="form__group-body">
                                                <div style={{display:'flex',flex:1,flexDirection:'row'}}>
                                                 <Select
                                                      style={{width:'100%'}}
                                                      labelId="demo-simple-select-label"
                                                      id="demo-simple-select"
                                                      value={selectedBldId}
                                                      label="Blds"
                                                      onChange={(e)=>this.setBld(e.target.value)}
                                                    >
                                                    {blds.length>0 && blds.map((item,index)=> (
                                                      <MenuItem value={item.id}>{item.address} {item.name?"("+item.name+")":""} </MenuItem>
                                                    ))}
                                                      
                                                    </Select>
                                                    <div style={{width:20,paddingLeft:10,marginTop:10}}><DeleteIcon onClick={()=>self.setState({selectedBldId:null})} width="15" height="15"/></div>
                                                    </div>
                                                    {selectedBldId>0 && <button type="button" class="form__btn" 
                                                      onClick={(e)=>this.editAddr(selectedBldId)}>
                                                            <span>Редактировать</span>
                                                                <svg width="29" height="29" fill="none">
                                                                    
                                                                </svg>
                                                    </button>}
                                                    {selectedBldId>0 &&<button type="button" class="form__btn" 
                                                      onClick={(e)=>this.deleteAddr(selectedBldId)}>
                                                            <span>Удалить</span>
                                                                <svg width="29" height="29" fill="none">
                                                                    
                                                                </svg>
                                                    </button>}
                                                </div>
											</div>}

											<div class="form__group">
												
                                                
                                                {id>0 && <div class="form__group-body">
                                                    {loadingTickets && <div className="shadow"><div className="shadow_circle"><CircularProgress/></div></div>} 
                                                
                                                    {!loadingTickets && <div class="form__group-top">
                                                        <span class="form__label">Типы билетов</span>
                                                        <button type="button" class="form__btn" onClick={self.addTicket}>
                                                            <svg width="29" height="29">
                                                                <use href="./img/sprite.svg#add-icon"></use>
                                                            </svg>
                                                            <span>Добавить</span>
                                                        </button>
                                                    </div>}
                                                    {!loadingTickets && tickets_tmp.map(function(item,index){
                                                        return(    
                                                        <div class="form__group-rows" >
                                                            <div class="form__group-row" onBlur={()=>self.saveTicket(index)}>
                                                                <input type="text" 
                                                                onChange = {(e)=>self.editTicket(index,e.target.value,'name')}
                                                                class="form__input" placeholder="Название" value={item.name}/>
                                                            </div>
                                                            <div class="form__group-row" onBlur={()=>self.saveTicket(index)}>
                                                                <input type="text" 
                                                                onChange = {(e)=>self.editTicket(index,e.target.value,'cost')}
                                                                class="form__input" placeholder="Цена" value={item.cost}/>
                                                            </div>

                                                            <div style={{width:30}}><DeleteIcon onClick={()=>self.delTicket(index)} width="15" height="15"/></div>
                                                        </div>)
                                                    })}

												</div>}
                                               
                                                
											</div>
										</div>
                                    
                                </div>
                            </div>     
                            
                            
                            <div class="layout__col layout__col-aside">
								<div class="layout__block">
									<h2 class="layout__block-title">Предосмотр</h2>

									<div class="layout__block-monitor">
										
                                        <img src={image?serverUrl+image:''} alt="" class="layout__block-img"/>
                                        <span class="layout__block-label">гибридный</span>
									</div>
                                    
                                    <div class="layout__inf">
										<h3 class="layout__inf-title">{name}</h3>
										
										<p class="layout__inf-text">{start_time?start_time.split('-').reverse().join('-'):'Не выбрано'} - {end_time?end_time.split('-').reverse().join('-'):'Не выбрано'}</p>
										{selectedBldId && <p class="layout__inf-text">{bldAddr}</p>}
                                        {!selectedBldId && <p class="layout__inf-text">Нет адреса</p>}
										
                                        <ul class="layout__inf-blocks">
											<li class="layout__inf-block">
												<div class="layout__inf-media">
                                              
													<p class="layout__inf-contains">
														<strong>{part_count}</strong> <br />
														участников
													</p>
												</div>
											</li>
                                            <li class="layout__inf-block">
												<div class="layout__inf-media">
                                              
													<p class="layout__inf-contains">
														<strong>{spons_count}</strong> <br />
														спонсоров
													</p>
												</div>
											</li>

											
										</ul>
                                        
									</div>
                                    
                                    
									<div class="layout__block-btns">
										<div class="layout__block-btn">
											<button class="btn btn--border" type="button" onClick={this.resetConf}>Сбросить</button>
										</div>

										<div class="layout__block-btn">
                                        {id==0 && <button onClick={this.onAdd} class="btn" type="submit">Добавить конференцию</button>}
                                        {id>0 && <button onClick={this.onSave} class="btn" type="submit">Сохранить конференцию</button>}
                                        </div>
                                        <div class="layout__block-btn">
                                        <button onClick={()=>this.onPublic(1-is_published)} class="btn" type="submit">{is_published==0?"Опубликовать конференцию":"Снять с публикации"}</button>
										</div>
									</div>
                                </div>
							</div>
                            
                        </div>}
                          <BldDialog handleClose={self.closeBldDialog}  
                        id = {bldId}
                        dAddress = {dAddress}
                        dName = {dName}
                        dEmail = {dEmail}
                        dialogOpen={bldDialog}/>
                        
                         <SponsorDialog 
                         handleClose={self.closeSpDialog}  
                        id = {spId}
                        sp_list = {sponsors}
                        all_list = {sp_list}
                        dialogOpen={spDialog}/>
                         
            </div>
        );
    }
}


function mapStateToProps(state) {
    const {app, user, authentication} = state;
    
    return {
        user,
        app,
        authentication
    };
}

const connectedCreateConfPage = connect(mapStateToProps)(CreateConfPage);
export {connectedCreateConfPage as CreateConfPage};

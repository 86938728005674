import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {DefaultLayout} from "./DefaultLayout";

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

//<Component {...props} />

export const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        getCookie('token')
            ?  <DefaultLayout title={rest.title} component={Component} {...props} />
            : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
    )}/>
);
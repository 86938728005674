import {userConstants} from '../_constants';

const initialState = {
    info: {name: ''},
    isLoading: false,
    isUserLoading: false,
    error:'',
    err_type:'',
    selectedConf:{},
    isConfSelected: false,
    conflist:[],
    conflist_student:[],
    teamorg:[],
    schedule: [],
    sections: [],
}


export function user(state = initialState, action) {
    
    
    if (action.type == userConstants.MOVE_TO) {
        return {
            ...state, url: action.url,error:''
        };
    }
    if (action.type == userConstants.SET_ERROR) {
        return {
            ...state, error:action.error, err_type:action.err_type
        };
    }    
    if (action.type == userConstants.DISABLE_ERROR) {
        return {
            ...state, error:''
        };
    }
    if (action.type == userConstants.SET_CONF_SELECTED) {
        return {
            ...state, isConfSelected: true,
        };
    }        
    if (action.type == userConstants.SET_CONF) {
        return {
            ...state, selectedConf:action.selectedConf,isConfSelected: action.selectedConf?true:false,
        };
    }
    else if (action.type == userConstants.USERINFO_REQUEST)
            return {
                ...state,
                info: {name:''},
                isUserLoading: true,
                error:'',
            };
    else if (action.type == userConstants.USERINFO_SUCCESS)
   {
            console.log("UIS",action);
            
            return {
                ...state,
                info: {...action.info},
                error: '',
				isUserLoading: false,
            };
    }
    else if (action.type == userConstants.USERINFO_FAILURE)
    {
            return {...state,error: action.error, isUserLoading: false}; 
    }    
    else if (action.type == userConstants.CONFLIST_REQUEST)
            return {
                ...state,
                conflist:[],
                isLoading: true,
                error:'',
            };
    else if (action.type == userConstants.CONFLIST_SUCCESS)
    {
            return {
                ...state,
                conflist: action.conflist,
                error: '',
				isLoading: false,
            };
    }
    else if (action.type == userConstants.CONFLIST_FAILURE)
    {
            return {...state,error: action.error, isLoading: false}; 
    }
    else if (action.type == userConstants.CONFLIST_STUDENT_REQUEST)
            return {
                ...state,
                conflist_student:[],
                isLoading: true,
                error:'',
            };
    else if (action.type == userConstants.CONFLIST_STUDENT_SUCCESS)
    {
            return {
                ...state,
                conflist_student: action.conflist,
                error: '',
				isLoading: false,
            };
    }
    else if (action.type == userConstants.CONFLIST_STUDENT_FAILURE)
    {
            return {...state,error: action.error, isLoading: false}; 
    }
     else if (action.type == userConstants.EDITCONF_REQUEST)
            return {
                ...state,
                conflist:[],
                isLoading: true,
                error:'',
            };
    else if (action.type == userConstants.EDITCONF_SUCCESS)
    {
            return {
                ...state,
                selectedConf: action.selectedConf,
                error: '',
				isLoading: false,
            };
    }
    else if (action.type == userConstants.EDITCONF_FAILURE)
    {
            return {...state,error: action.error, isLoading: false}; 
    }
    else if (action.type == userConstants.DELCONF_REQUEST)
            return {
                ...state,
                isLoading: true,
                error:'',
            };
    else if (action.type == userConstants.DELCONF_SUCCESS)
    {
            return {
                ...state,
                conflist:[...state.conflist.filter(e=>e.id != action.id)],
                error: '',
				isLoading: false,
            };
    }
    else if (action.type == userConstants.DELCONF_FAILURE)
    {
            return {...state,error: action.error, isLoading: false}; 
    }
    else if (action.type == userConstants.SCHEDULE_REQUEST)
            return {
                ...state,
                schedule:[],
                isLoading: true,
                error:'',
            };
    else if (action.type == userConstants.SCHEDULE_SUCCESS)
    {
            return {
                ...state,
                schedule: action.data,
                error: '',
				isLoading: false,
            };
    }
    else if (action.type == userConstants.SCHEDULE_FAILURE)
    {
            return {...state,error: action.error, isLoading: false}; 
    }
    else if (action.type == userConstants.SECTIONS_REQUEST)
            return {
                ...state,
                sections:[],
                isLoading: true,
                error:'',
            };
    else if (action.type == userConstants.SECTIONS_SUCCESS)
    {
            return {
                ...state,
                sections: action.data,
                error: '',
				isLoading: false,
            };
    }
    else if (action.type == userConstants.SECTIONS_FAILURE)
    {
            return {...state,error: action.error, isLoading: false}; 
    }
     else if (action.type == userConstants.ADDCONF_REQUEST)
            return {
                ...state,
                isLoading: true,
                error:'',
            };
    else if (action.type == userConstants.ADDCONF_SUCCESS)
    {
        
            return {
                ...state,
                selectedConf: action.selectedConf,
                error: '',
				isLoading: false,
            };
    }
    else if (action.type == userConstants.ADDCONF_FAILURE)
    {
            return {...state,error: action.error, isLoading: false}; 
    }
    else if (action.type == userConstants.TEAMORG_REQUEST)
            return {
                ...state,
                teamorg:[],
                isLoading: true,
                error:'',
            };
    else if (action.type == userConstants.TEAMORG_SUCCESS)
		
            return {
                ...state,
                teamorg: action.teamorg,
                error: '',
				isLoading: false,
            };
    else if (action.type == userConstants.TEAMORG_FAILURE)
    {
            return {...state,error: action.error, isLoading: false}; 
    }     
    else {
        return state
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import { history } from '../_helpers';
import { SendButton, TextFieldCell, Snack} from "../_components";
import '../css/style.css';
import '../css/grid.css';
import { storeData, setStore } from '../store.js';
import { connect } from "react-redux";
import {store} from '../_helpers'; 
import { userService, confService } from '../_services';    
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';


import { userActions, appActions } from "../_actions";
import i18next from 'i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import TicketDialog from './TicketDialog';
import SlotInfo from './SlotInfo';
import ym from 'react-yandex-metrika';
import {serverUrl} from '../_constants'; 
import playLogo from '../img/play.png'; 
import inetLogo from '../img/inet.png'; 

const nodata_text = 'уточняется';

const getDate = (time) => {

   if (!time) return "уточняется";
   
   let time1 = time.substr(0,10);
   
   return time1.split('-').reverse().join('-');

}    

class ClientConfPage extends React.Component {


	constructor(props)
	{
		super(props);
        
		 this.state = { isLoading:false, addr:'',sponsors:[],schedule:[],schFav:[], hasTicket:false,
         oneDaySchedule:[],curDateIndex:0,table_class:'col-md-4',tickets:[],openTicket:false,
         slotInfoOpen:false,slotData:{},favChedule:[],blds:[],id:0, groupBySections:false,lessons:[],isUrl:false}
        store.subscribe(this.storeChange)
	}

    componentDidMount() {
        
        
        let { selectedConf, info} = this.props.user;
        
        if (selectedConf && selectedConf.id)
        {
            this.setState({name:selectedConf.name,
                          format:selectedConf.type,
                          comment:selectedConf.description,
                          streamUrl:selectedConf.navigation_description,
                          id:selectedConf.id,
                          wifi: selectedConf.wifi,
                          image:selectedConf.image?selectedConf.image.replace('public','storage'):null,
                          building_id:selectedConf.building_id,
                          start_time:selectedConf.start_time,
                          end_time:selectedConf.end_time,
                          selectedBldId:selectedConf.building_id,
                          map_description: selectedConf.map_description,
                          hasTicket: selectedConf.hasTicket!=undefined?selectedConf.hasTicket:false,
                          map_image: selectedConf.map_image?selectedConf.map_image.replace('public','storage'):null,
                          navigation_building_description: selectedConf.navigation_building_description,
                          navigation_building_image: selectedConf.navigation_building_image?selectedConf.navigation_building_image.replace('public','storage'):null,
                          navigation_description: selectedConf.navigation_description,
                          navigation_image: selectedConf.navigation_image?selectedConf.navigation_image.replace('public','storage'):null});
                          
            if (selectedConf.id>0)
            {
               this.getSchedule(selectedConf.id);
               //this.props.dispatch(userActions.get_schedule(selectedConf.id));   
               this.getSponsors(selectedConf.id);
               this.getTickets(selectedConf.id);
               this.getConfInfo(selectedConf.id);
               
               
               ym('reachGoal', 'view_conf', {"conf_id": selectedConf.id}); 
               
               //userService.getAsync('api/conference/'+selectedConf.id+'/students');
            }   
        }
        
    }
    
     getConfInfo = (id) => {
        
        let self = this;
        
            
        	confService.confInfo(id,function(data) 
		   {
               if (data.status == 200)
               {
                   
                   self.setState({
                       name:data.data.name,
                          format:data.data.type,
                          comment:data.data.description,
                          streamUrl:data.data.navigation_description,
                          image:data.data.image?data.data.image.replace('public','storage'):null,
                          building_id:data.data.building_id,
                          start_time:data.data.start_time,
                          end_time:data.data.end_time,
                          selectedBldId:data.data.building_id,
                          map_description: data.data.map_description,
                          map_image: data.data.map_image,
                          navigation_building_description: data.data.navigation_building_description,
                          navigation_building_image: data.data.navigation_building_image,
                          navigation_description: data.data.navigation_description,
                          navigation_image: data.data.navigation_image
                       });
                       
                    self.getBuildings();   
               }
                   
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false});  }
		
		   });
    }
    
     getBuildings = () => {
        
        var self = this;
        
        
		userService.getWrapper('api/building',function(data) 
		   {
               let addr = '';
               let blds = [];
               
               if (data.data)
               {
                   blds = [...data.data];
                   
                   for (let i=0;i<blds.length;i++)
                   if (blds[i].id == self.state.selectedBldId)
                   {
                       addr = blds[i].address;
                       break;
                       
                   }
                   
                   self.setState({blds:blds,addr:addr});
               }
                   
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false});  }
		
		   });
    }
    
    closeTicketDialog = (tid) => {
    
        this.setState({openTicket:false});
        
        const { user } = this.props;
        let self = this;
        
        
        if (tid>0)
        {
            if (user.info.type && user.info.type != "org")
            {
            ym('reachGoal', 'buy-ticket', {"conf_id": this.state.id,"ticket_id":tid}); 
            let url = serverUrl+'buy-ticket/'+user.info.id+'/'+tid;
            window.location.href = url;
            }
            else
            {
                alert('Нельзя купить билет из панели организатора');
            }
        }
    }
    
    getTickets = (id) => {
        
        var self = this;
        
        this.setState({loadingTickets:true});
        
		userService.getWrapper('api/ticket-type?conference_id='+id,function(data) 
		   {
               
               if (data.data)
                   self.setState({tickets:[...data.data],loadingTickets:false});
                else
                    self.setState({tickets:[],loadingTickets:false});
                   
          },function(error) {  if (error!='')   { self.setState({error:error,loadingTickets:false}); }
		
		   });
        
        
    }
     
    getSponsors = (id) => {
        
        var self = this;
        
        let getUrl = 'api/sponsor';
        if (id>0) getUrl += '?conference_id='+id;
        
        
		userService.getWrapper(getUrl,function(data) 
		   {
               if (data.data)
                   self.setState({sponsors:[...data.data]});
                else
                    self.setState({sponsors:[]});
               
                   
          },function(error) {  if (error!='')   { self.setState({error:error});  }
		
		   });
        
        
    }
    
    setSchedule = (data, groupType ) => {
        
        let months = {'01':"января",'02':'февраля','03':'марта','04':'апреля','05':'мая','06':'июня',
        '07':'июля','08':'августа','09':'сентября','10':'октября','11':'ноября','12':'декабря'};
        
        
        const { groupBySections } = this.state;
        
        if (groupType == undefined) 
            groupType = groupBySections;
        
        let schedule = [];
        let dates = [];
        let rooms = [];
        let favChedule = [];
        
        let t_class = 'col-md-12';
        
        for (let i=0;i<data.length;i++)
        if (data[i].is_favorite == true)
        {
            
            let objTmp = {...data[i]};
            
            let sp_str = '';
            
            for (let m=0;m<data[i].speakers.length;m++)
            {
                sp_str += data[i].speakers[m].name;
                if (m<data[i].speakers.length-1) 
                    sp_str+=', ';
            } 
            
            objTmp.sp_str = sp_str;
            
            
            let timeStr = '';
            let tmpTime = [];
            if (objTmp.start_time)
            {
                
                tmpTime = objTmp.start_time.split(':');
                if (tmpTime.length>1)
                {
                    timeStr = tmpTime[0]+":"+tmpTime[1];
                    
                    objTmp['ftime'] = timeStr;
                    objTmp['time'] = timeStr;
                }
            }
            
            if (objTmp.date)
            {
                timeStr = '';
                let date0 = objTmp.date.substr(0,10);
                tmpTime = date0.split('-');
                if (tmpTime.length>2)
                {
                    timeStr  = tmpTime[2]+" "+months[tmpTime[1]];
                    objTmp.fdate = timeStr;
                }
            }
            
            
            favChedule.push({...objTmp});
            
        }
        
            
        for (let i=0;i<data.length;i++)
        if (data[i].date)
        {
            let oldDate = new Date(data[i].date);
            
                
                let dayData = ""+oldDate.getDate();
                if (dayData.length<2) dayData = "0"+dayData;
                
                let mData = ""+(1+oldDate.getMonth());
                if (mData.length<2) mData = "0"+mData;
                
                let cdate = oldDate.getFullYear()+'-'+mData+'-'+dayData;
                
            
            let ctime = data[i].start_time;
            let time = '';
            
            if (ctime)
            {
                ctime = ctime.split(':');
            
                if (ctime.length>0)
                    time = ctime[0]+':'+ctime[1];
            }
                
                
            
            let date_array = cdate.substr(0,10).split('-');
            
            
            let title = date_array[2]+" "+months[date_array[1]];
            
            let dateIndex = -1;
            
            for (let j=0;j<dates.length;j++)
                if (dates[j].date == cdate)
                {
                    dateIndex = j;
                }
                
            if (dateIndex<0)
            {
                
                if (groupType == false)
                    dates.push({date:cdate,title:title,rooms:[]});
                else
                    dates.push({date:cdate,title:title,sections:[]});
                dateIndex = dates.length-1;
            }
            
            
            //смотрим как группировать дальше
            
            if (groupType == false)
            {
            
                let roomIndex = -1;
                
                for (let l=0;l<dates[dateIndex].rooms.length;l++)
                        if (dates[dateIndex].rooms[l].room_number == data[i].room_number)
                            roomIndex = l;
                
                if (roomIndex<0)
                {
                    dates[dateIndex].rooms.push({room_id:data[i].room_id,room_number:data[i].room_number,sections:[]});
                    roomIndex = dates[dateIndex].rooms.length-1;
                }
                
                
                let sectIndex = -1;

                for (let k=0;k<dates[dateIndex].rooms[roomIndex].sections.length;k++)
                    if (data[i].section)
                    {
                    if (dates[dateIndex].rooms[roomIndex].sections[k].id == data[i].section.id)
                        sectIndex = k;
                    }
                    else
                    {
                        if (dates[dateIndex].rooms[roomIndex].sections[k].id == 0)
                        sectIndex = k;
                    }
                    
                 if (sectIndex<0)
                {
                    if (data[i].section)
                        dates[dateIndex].rooms[roomIndex].sections.push({id:data[i].section.id,name:data[i].section.name,slots:[]});
                    else
                        dates[dateIndex].rooms[roomIndex].sections.push({id:0,name:'Без секции',slots:[]});
                    sectIndex = dates[dateIndex].rooms[roomIndex].sections.length-1;
                }
                    
                let sp_str = '';
                
                for (let m=0;m<data[i].speakers.length;m++)
                {
                    sp_str += data[i].speakers[m].name;
                    if (m<data[i].speakers.length-1) 
                        sp_str+=', ';
                } 
                      
                dates[dateIndex].rooms[roomIndex].sections[sectIndex].slots.push({...data[i],time:time,sp_str:sp_str});
                
                if (dates[0].rooms.length == 2) t_class = 'col-md-6';
                if (dates[0].rooms.length > 2) t_class = 'col-md-4';
                if (dates[0].rooms.length > 3) t_class = 'col-md-3';
                if (dates[0].rooms.length > 4) t_class = 'col-md-2';
                if (dates[0].rooms.length > 6) t_class = 'col-md-1';
            }
            else            
            {
                let sectIndex = -1;
                

                for (let k=0;k<dates[dateIndex].sections.length;k++)
                    if (data[i].section)
                    {
                    if (dates[dateIndex].sections[k].id == data[i].section.id)
                        sectIndex = k;
                    }
                    else
                    {
                        if (dates[dateIndex].sections[k].id == 0)
                        sectIndex = k;
                    }
                    
                 if (sectIndex<0)
                {
                    if (data[i].section)
                        dates[dateIndex].sections.push({id:data[i].section.id,name:data[i].section.name,slots:[]});
                    else
                        dates[dateIndex].sections.push({id:0,name:'Без секции',slots:[]});
                    
                    sectIndex = dates[dateIndex].sections.length-1;
                }
                    
                let sp_str = '';
                
                for (let m=0;m<data[i].speakers.length;m++)
                {
                    sp_str += data[i].speakers[m].name;
                    if (m<data[i].speakers.length-1) 
                        sp_str+=', ';
                } 
                      
                dates[dateIndex].sections[sectIndex].slots.push({...data[i],time:time,sp_str:sp_str});
                
                if (dates[0].sections.length == 2) t_class = 'col-md-6';
                if (dates[0].sections.length > 2) t_class = 'col-md-4';
                if (dates[0].sections.length > 3) t_class = 'col-md-3';
                if (dates[0].sections.length > 4) t_class = 'col-md-2';
                if (dates[0].sections.length > 6) t_class = 'col-md-1';

                
            }
            
        }
        
        this.setState({schedule:dates,favChedule:favChedule,table_class:t_class,oneDaySchedule:{...dates[0]}});
        this.clickDate(this.state.curDateIndex,groupType,[...dates]);
    }
 
      getSchedule = (id) => {
        
        var self = this;
        
		userService.getWrapper('api/schedule/'+id,function(data) 
		   {
               
               
               if (data.status == 200)
               {
                   self.setSchedule([...data.data.lessons]);
                   self.setState({lessons:[...data.data.lessons]});
               }
                   
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); }
		
		   });
    }
    
    
    clickGroup = () => {
        
        const { lessons, groupBySections } = this.state;
        
        let newGroup = !groupBySections;
        
        this.setState({groupBySections:newGroup});
        this.setSchedule(lessons,newGroup);
        
    }
    
     storeChange = (e) => {
        
     }  
     
     clickDate = (ind,groupType, schedule) => {
         
         
         if (ind>=0)
         {
             
             let t_class = 'col-md-12';
             let col_count = 1;
             
             
            if (groupType)
            {
                if (schedule[ind] && schedule[ind].sections)
                    col_count = schedule[ind].sections.length;
                
            }
            else
            {
                if (schedule[ind] && schedule[ind].rooms)
                    col_count = schedule[ind].rooms.length;
            }
            if (col_count == 2) t_class = 'col-md-6';
            if (col_count > 2) t_class = 'col-md-4';
            if (col_count > 3) t_class = 'col-md-3';
            if (col_count > 4) t_class = 'col-md-2';
            if (col_count > 6) t_class = 'col-md-1';
            
             this.setState({oneDaySchedule:{...schedule[ind]},curDateIndex:ind,table_class:t_class})
         }
         else
            this.setState({oneDaySchedule:{rooms:[]},curDateIndex:ind,table_class:'col-md-12'});
     }
     
     delImage = (tp) => {
    
        this.uploadFile(null,tp);
     }
     
     onSave = (tp) => {
         
      
     }
     
     onTextChange = (e,tp) => {
       
     }
    
    clickStar = (e,item,val) => {
        
        e.stopPropagation();
        let self = this;
        
        
        let url1 ="api/lesson/"+item.id+"/favorite";
        
        if (val ==1)
            userService.postWrapper(url1,{},function(data) 
		   {
               self.getSchedule(self.state.id);
                   
          },function(error) {  if (error!='')   { self.setState({error:error,loadingTickets:false}); }
		
		   });
        else
        userService.delWrapper(url1,function(data) 
		   {
               self.getSchedule(self.state.id);
                   
          },function(error) {  if (error!='')   { self.setState({error:error,loadingTickets:false}); }
		
		   });            
    
    
    }
    
    renderSlots = (slots) => {
        
        let self = this;
        
        
        return slots.map((slotItem,slotIndex) => (
                                             <li class="item" key={slotIndex} onClick={()=>self.setState({slotData:{...slotItem},slotInfoOpen:true})}>
                                                <div class="time">{slotItem.time?slotItem.time:'??:??'}</div>
                                                <div class="theme">
                                                    <div class="title-theme">{slotItem.name}</div>
                                                    <div class="desc-theme">{slotItem.sp_str}</div>
                                                </div>
                                                {slotItem.is_favorite == false && <div class="favorite" onClick={(e)=>self.clickStar(e,slotItem,1)}>
                                                    <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7177 2.06477L19.5604 7.80654C19.839 8.37022 20.3767 8.76108 21.0003 8.85153L27.3594 9.777C28.9306 10.0063 29.5558 11.9316 28.4187 13.0347L23.8203 17.5021C23.3684 17.9415 23.1627 18.573 23.2696 19.1932L24.3548 25.5002C24.6221 27.0605 22.9796 28.2508 21.5753 27.5127L15.8917 24.5328C15.3345 24.2405 14.6671 24.2405 14.1083 24.5328L8.42467 27.5127C7.02036 28.2508 5.37795 27.0605 5.64682 25.5002L6.73043 19.1932C6.83733 18.573 6.63162 17.9415 6.17972 17.5021L1.58128 13.0347C0.444224 11.9316 1.06944 10.0063 2.64059 9.777L8.99968 8.85153C9.62328 8.76108 10.1627 8.37022 10.4412 7.80654L13.2823 2.06477C13.9852 0.645076 16.0148 0.645076 16.7177 2.06477Z" fill="#FAFF00" stroke="#243341" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    </svg>
                                                </div>}
                                                
                                                {slotItem.is_favorite == true && <div class="favorite active" onClick={(e)=>self.clickStar(e,slotItem,0)}>
                                                    <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7177 2.06477L19.5604 7.80654C19.839 8.37022 20.3767 8.76108 21.0003 8.85153L27.3594 9.777C28.9306 10.0063 29.5558 11.9316 28.4187 13.0347L23.8203 17.5021C23.3684 17.9415 23.1627 18.573 23.2696 19.1932L24.3548 25.5002C24.6221 27.0605 22.9796 28.2508 21.5753 27.5127L15.8917 24.5328C15.3345 24.2405 14.6671 24.2405 14.1083 24.5328L8.42467 27.5127C7.02036 28.2508 5.37795 27.0605 5.64682 25.5002L6.73043 19.1932C6.83733 18.573 6.63162 17.9415 6.17972 17.5021L1.58128 13.0347C0.444224 11.9316 1.06944 10.0063 2.64059 9.777L8.99968 8.85153C9.62328 8.76108 10.1627 8.37022 10.4412 7.80654L13.2823 2.06477C13.9852 0.645076 16.0148 0.645076 16.7177 2.06477Z" fill="#FAFF00" stroke="#243341" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    </svg>
                                                </div>}
                                                
                                            </li>
                                                
        )) 
    }
    
    
    

    render() {
        
	
		let self = this;
        const { id, isLoading, image,comment,name,start_time,end_time, addr,
        navigation_building_description, navigation_description,map_description,
        map_image,navigation_building_image, navigation_image, hasTicket,
        sponsors,schedule, oneDaySchedule, curDateIndex, table_class, wifi,
        tickets,openTicket, slotData,slotInfoOpen, favChedule, groupBySections, isUrl,
        } = this.state;
        
        const { classes, user } = this.props;
        
        return (
            <div class="layout">
           {!id && <label for="text1" class="form__label">Нет выбранной конференции</label>}
           {id>0 && 
           <div>
            	<section class="member-top">
                    
            <div class="conf-detail conf-detail-member">
                        <div class="conf-img">
                            <img src={image?serverUrl+image:''} alt=""/>
                        </div>
                        <div class="conf-info">
                            <div class="title h1">
                            {name}
                            </div>
                            <div class="meta">
                                <div class="date">
                                        <span>{getDate(start_time)} - {getDate(end_time)}</span>
                                </div>
                                <div class="address">
                                    <span>
                                    {addr}
                                    </span>
                                </div>
                            </div>
                            <div class="btn-member">
                                <button class="btn-gradient" onClick={()=>self.setState({openTicket:true})}>
                                {hasTicket?"Билет куплен":"Купить билет"}
                                </button>
                                <button class="btn-border">
                                    <span>
                                        Связаться с организаторами
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div class="conf-links">
                        {isUrl && <a class="btn-icon">
                                <img src={playLogo} style={{marginRight:10}}/>
                                <span>Онлайн-трансляция</span>
                        </a>}
                            <a class="btn-icon" style={{marginBottom:15}}>
                                <img src={inetLogo} style={{marginRight:10}}/>
                                <span>Интернет на <br/> площадке</span>
                            </a>
                            {hasTicket && <span style={{marginLeft:10}}>{wifi}</span>}
                        </div>
                    </div>
                </section>
                
                	 <section class="description block-default">
                        <div class="title">Описание мероприятия</div>
                        <p class="text_n">{comment?comment:'Нет описания'}</p>

                        <div class="partners">
                            <div class="title-small">
                                Партнёры и спонсоры
                            </div>
                            <ul>
                            {sponsors.length>0 && sponsors.map(item=>(
                            <li><img style={{width:60,height:60,borderRadius:30}}src={item.logo?serverUrl+item.logo.replace('public','storage'):''} alt=""/>
                                <span>{item.brandname}</span>
                            </li>))}
                            </ul>
                        </div>

                    </section>

            <section class="block-default">
                    <div class="title">
                        Программа
                    </div>
                    
                     <FormGroup style={{marginBottom:10}}>
                        <FormControlLabel control=
                        {<Checkbox color="default" 
                        checked={groupBySections} onClick={this.clickGroup} />} label="Группировать по секциям" />
                        </FormGroup>
                    
                    <div class="tab-switcher">
                        <ul class="tabs-member">
                        {schedule.length>0 && schedule.map((item,index) => (
                        <li data-tab="27" onClick={()=>self.clickDate(index, groupBySections,schedule)} class={index==curDateIndex?'active':''}>{item.title}</li>))}
                            
                            <li data-tab="favorite" class={curDateIndex<0?'active':''} onClick={()=>self.clickDate(-1)} >Мое избранное</li>
                        </ul>
                    </div>
                
                    <div class="tabs-content">
                             <div class="row" style={{width:'100%'}}>
                                    {groupBySections == false && oneDaySchedule.rooms && oneDaySchedule.rooms.map((roomItem,roomInd)=>
                                    (
                                        <div class={table_class}>
                                        <div class="title-small">{roomItem.room_number?roomItem.room_number:nodata_text}</div>
                                         <ul class="list-schedule">
                                         {roomItem.sections && roomItem.sections.map((sectItem,sectIndex) => (
                                         <li>
                                            <ul>
                                               <li class="item" style={{backgroundColor:'#ffffdd'}}>
                                                <div class="time">Секция</div>
                                                <div class="theme">
                                                    <div class="title-theme">{sectItem.name}</div>
                                                    
                                                </div>
                                             </li>
                                             
                                             {self.renderSlots(sectItem.slots)}
                                             </ul>
                                            </li>
                                           
                                            ))}
                                         
                                         </ul>
                                        </div>
                                    ))} 
                                    
                                    {groupBySections && oneDaySchedule.sections && oneDaySchedule.sections.map((sItem,sInd)=>
                                    (
                                        <div class={table_class}>
                                        <div class="title-small">{sItem.name?sItem.name:nodata_text}</div>
                                         <ul class="list-schedule">
                                         {sItem.slots && sItem.slots.map((sectItem,sectIndex) => (
                                         <li style={{marginBottom:10}}>
                                             {self.renderSlots([sectItem])}
                                            </li>
                                           
                                            ))}
                                         
                                         </ul>
                                        </div>
                                    ))}
                                    
                                    
                                    
                                    {curDateIndex<0 && favChedule.length == 0 && <ul><li><div>В избранном ничего нет</div></li></ul>}                                           
                                    {curDateIndex<0 && favChedule.length > 0 && 
                                            <div class="row" style={{width:'100%'}}>
                                            <div class="col-md-12">
                                            <ul class="list-schedule">
                                            { favChedule.map(item=>(
                                            <li class="item" onClick={()=>self.setState({slotData:{...item},slotInfoOpen:true})}>
                                                <div class="time" style={{width:130}}><div>{item.ftime}</div><div style={{paddingTop:5}}>{item.fdate}</div></div>
                                                
                                                <div class="theme">
                                                    <div class="title-theme">{item.name}, {item.room_number?item.room_number:nodata_text} </div>
                                                    <div class="desc-theme">{item.sp_str}</div>
                                                </div>
                                            </li>))}
                                             </ul></div></div>}                                           
                                   
                                
                                    
                                </div>
                        
                    </div>

                </section>
                
                	<section class="block-default navigation-desc">
                    <div class="title">
                        Навигация
                    </div>

                    <div class="row" style={{width:'100%'}}>
                        <div class="col-md-6">
                            <img src={navigation_building_image?serverUrl+navigation_building_image:''} style={{ maxHeight:400}}  alt=""/>
                        </div>
                        <div class="col-md-6">
                            <div class="title-small">Карта выставки</div>
                            <p class="text_n">{navigation_building_description}</p>
                        </div>
                    </div>

                    <div class="row" style={{width:'100%'}}>
                        <div class="col-md-6">
                            <div class="title-small">Карта площадки</div>
                            <p class="text_n">{map_description}</p>
                        </div>
                        <div class="col-md-6">
                            
                            <img src={map_image?serverUrl+map_image:''} style={{ maxHeight:400}} alt=""/>
                        </div>
                    </div>

                    <div class="row" style={{width:'100%'}}>
                        <div class="col-md-6">
                            <img src={navigation_image?serverUrl+navigation_image:''} style={{ maxHeight:400}} alt=""/>
                        </div>
                        <div class="col-md-6">
                            <div class="title-small">Как добраться?</div>
                            <p class="text_n">{navigation_description}</p>
                            
                        </div>
                    </div>

                </section>

		
           </div>}
            <TicketDialog 
                         handleClose={self.closeTicketDialog}  
                        tickets={tickets}
                        dialogOpen={openTicket}/>
                        
            <SlotInfo
                         handleClose={()=>self.setState({slotInfoOpen:false})}  
                        data={slotData}
                        open={slotInfoOpen}/>
           </div>
        );
    }
}


function mapStateToProps(state) {
    const {app, user, authentication} = state;
    
    return {
        user,
        app,
        authentication
    };
}

const connectedClientConfPage = connect(mapStateToProps)(ClientConfPage);
export {connectedClientConfPage as ClientConfPage};



import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './style.css';

import {store} from './_helpers';
import {App} from './App';

import { YMInitializer } from 'react-yandex-metrika';


import './i18n';

const ym_id ='88878877';

ReactDOM.render(
  <Provider store={store}>
   <YMInitializer accounts={[ym_id]} />
    <App/>
    </Provider>,
  document.getElementById('root')
);

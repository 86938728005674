import React from 'react';
import {Button, Grid} from "@material-ui/core";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import TextField from '@material-ui/core/TextField';

import AgrInfo from './AgrInfo';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';

import {pdText} from '../_constants'; 
import { SendButton, TextFieldCell} from "../_components";
import { history } from '../_helpers';
import { userService } from '../_services';
import {connect} from 'react-redux';
import {userActions} from '../_actions';
import {store} from '../_helpers'; 

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
 
  function setCookie(name, value, options) {
                options = options || {};
                let expires = options.expires;
                if (typeof expires == "number" && expires) {
                    var d = new Date();
                    d.setTime(d.getTime() + expires * 1000);
                    expires = options.expires = d;
                }

                if (expires && expires.toUTCString)
                    options.expires = expires.toUTCString();

                value = encodeURIComponent(value);
                let updatedCookie = name + "=" + value;
                for (let propName in options) {
                    updatedCookie += "; " + propName;
                    let propValue = options[propName];
                    if (propValue !== true) {
                        updatedCookie += "=" + propValue;
                    }
                }

                document.cookie = updatedCookie;
            }  

class GooglePage extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            username: '',
            pwd: '',
            submitted: false,
            getCb: false,
            isNew:false,
            
            agreeOk: false,
            slotInfoOpen: false,
        };
        
        store.subscribe(this.storeChange)
    }
    
    storeChange = (e) => {
        
        console.log(e);
        console.log(store.getState());
        /*
        if (store.getState().authentication.error && store.getState().authentication.isLoading == false)
            alert(store.getState().authentication.error);
        
        */
    }
    
    componentDidMount() {
     
     const {dispatch} = this.props;  
     
     const { isNew } = this.state;
     
     let self = this;
     let str = window.location.href;
     
     
     let userType = "org";
     
     console.log(str);
     
     if (str.indexOf("callback-s")>=0) userType = "student";
     
     let   str_arr = str.split('code=');
        if (str_arr.length>1)
        {
            str = str_arr[1];
            str_arr = str.split('&scope');
            str = str_arr[0];
            
            localStorage.setItem('user','{}');
            
           userService.getWrapper('api/token/google?type='+userType+'&code='+str,function(data) 
		   {
               console.log(data);
               if (data && data.data && data.data.token)
               {
                   //self.setState({getCb:true,isNew:data.data.isNew});
                   self.setState({getCb:true,isNew:data.data.is_new,token:data.data.token,id:data.data.id});
                   
                   let agree = localStorage.getItem('navic_agree_'+data.data.id);
                   //|| data.data.is_new ==false
                   
                   if (agree == '1' )
                   {
                       //localStorage.setItem('navic_agree','1');
                       setCookie("token",data.data.token);
                        history.push('/');
                   }
                   
               }
                   
          },function(error) {  if (error!='')   { self.setState({error:error,isLoading:false}); alert(error); }
		
		   });
            
        
         
        }
    }
    
    clickAgr = () => {
        
        this.setState({slotInfoOpen:true});
    }

    handleSubmit = (e) => {
        
        const {getCb, isNew, agreeOk, token,id} = this.state;
        
        if (agreeOk)
        {
            localStorage.setItem('navic_agree_'+id,'1');
            setCookie("token",token);
            history.push('/');
        }
        
    }
   
    
   
    render() {
        
        const {getCb, isNew, slotInfoOpen,agreeOk, snackText, err_type, alertShow, userType} = this.state;

        return (
            <div>
            {getCb && 
            <Grid container justify="center" alignItems="center" style={{height: '100vh'}}>
                <Grid item style={{width: 600}}>
                         <div 
                            style={{marginTop: 10,marginBottom:10,  fontSize:20, marginLeft:-3, display:'flex',flexDirection:'row'}}>
                            <Checkbox color="default" style={{paddingLeft:0,paddingTop:0}}
                        checked={agreeOk} onClick={()=>this.setState({agreeOk:!agreeOk})} />
                            <p>Я ознакомился с <span> </span> 
                            <span style={{color:'blue', textDecoration: 'underline'}} onClick={this.clickAgr}>
                            Соглашением об обработке персональных данных</span>
                            и даю своё согласие на их обработку</p>
                            </div>
                        <div className="form-group"
                             style={{marginTop: 5, justifyContent: 'space-between', flex: 1, display: 'flex'}}>
                            
                                    <CustomizedButton
                                    style={{marginBottom: 5}}
                                    onClick={this.handleSubmit}
                        id="category-button-add"
                        title="Принять"
                        prim
                        />
                       
                        </div>
                        
                    
                </Grid>
            </Grid>}
            {!getCb && <div>Loading...</div>}
            <AgrInfo
                         handleClose={()=>this.setState({slotInfoOpen:false})}  
                        data={pdText}
                        open={slotInfoOpen}/>
                        
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {loggingIn, error} = state.authentication;
    return {
        loggingIn,
        error
    };
}

const connectedGooglePage = connect(mapStateToProps)(GooglePage);
export {connectedGooglePage as GooglePage};
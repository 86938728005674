import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export class Menu extends React.Component {
    render() {
        const styles = {
            svg: {
                width: this.props.size || 40,
                height: this.props.size || 40
            }
        };

        return (
            <SvgIcon onClick={this.props.onClick} style={{...this.props.style, ...styles.svg}}>
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fal"
                     data-icon="bars" className="svg-inline--fa fa-bars fa-w-14" role="img" viewBox="0 0 448 512">
                    <path xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                          d="M442 114H6a6 6 0 0 1-6-6V84a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6z"/>
                </svg>
            </SvgIcon>
        );
    }
}